import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionProps, selectState } from '../../../utils/reduxActions';
import { createAddressActions } from './reducer';
import '../../styles/views.css';
import { Dimmer, Loader } from 'semantic-ui-react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { existPostalCode } from '../../../utils/colonies'

interface CreateAddressFormInputs {
  addressType: string
  nameOfIndividual: string
  email: string
  phone: string
  companyName: string
  address: {
    address: string
    interiorNumber: string
    zipCode: string
    colonyName: string
    city: string
    state: string
    country: string
  }
}

const phoneRegExp = /^[0-9]{10}$/gm
const CreateAddressSchema = yup.object().shape({
  nameOfIndividual: yup.string().trim().max(23, 'Max. número de caracteres permitidos. 23').required('Nombre requerido'),
  email: yup.string().email('Email no valido').required('Email requerido'),
  phone: yup
    .string()
    .matches(phoneRegExp, "Número no valido, ej. 5532990834")
    .required('Telefono requerido'),
  companyName: yup.string().trim().max(23, 'Max. número de caracteres permitidos. 23').required('Nombre de compañia requerido'),
  address: yup.object().shape({
    address: yup.string().trim().required('Dirección requerida'),
    interiorNumber: yup.string().trim().required('Número interior requerido'),
    zipCode: yup
      .string()
      .min(5, 'El Código postal debe contener 5 caracteres numéricos')
      .max(5, 'El Código postal debe contener 5 caracteres numérico').trim().required('Código postal requerido'),
    colonyName: yup.string().trim().required('Seleccionar colonia'),
    city: yup.string().trim().required('Seleccionar ciudad'),
    state: yup.string().trim().required('Seleccionar estado'),
    country: yup.string().trim(''),
  })
});


interface CreateAddressProps {
  actions?: any;
  controls?: any;
  history?: any;
  result?: any;
}

const getData = (data: any) => {
  const colonies: any[] = []
  const cities: any[] = []
  const states: any[] = []
  data.forEach((e: any) => {
    colonies.push(e.colonyName)
    cities.push(e.city)
    states.push(e.state)
  });
  return {
    colonies,
    cities,
    states
  }
}

const CreateAddress: React.FC<CreateAddressProps> = (props) => {
  const { actions, controls, history } = props;
  const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm<CreateAddressFormInputs>({
    resolver: yupResolver(CreateAddressSchema)
  });
  const [colonies, setColonies] = useState<string[]>([])
  const [cities, setCities] = useState<string[]>([])
  const [states, setStates] = useState<string[]>([])
  const zipcode = watch('address.zipCode')

  useEffect(() => {
    setValue('address.country', 'México')
    const searchZipCode = async (zipcode: string) => {
      const res = await existPostalCode(zipcode)
      if (res.exists) {
        const data = getData(res.data)
        setColonies([...new Set(data.colonies)])
        setCities([...new Set(data.cities)])
        setStates([...new Set(data.states)])
      }
    }
    if (zipcode?.length === 5) searchZipCode(zipcode)
  }, [actions, watch, setValue, zipcode]);

  const onSubmit = (data: CreateAddressFormInputs) => actions.createAddress({ ...data, history })

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dimmer active={controls.loading} inverted>
          <Loader inverted>Guardando.....</Loader>
        </Dimmer>
        <div className="grid shadow-xl py-4 px-8 rounded-xl bg-white">
          <h2 className="text-2xl font-bold my-4">Crear nueva dirección</h2>
          <h2 className="text-xl font-bold my-2">Datos personales: </h2>
          <hr className="mt-1 mb-4 md:min-w-full " />
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:space-x-4">
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Nombre</label>
              <input
                className={`w-full py-1 border-b-2 text-gray-500 focus:outline-none ${errors.nameOfIndividual ? 'border-red-500' : ''}`}
                {...register("nameOfIndividual")}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.nameOfIndividual?.message}</span>

            </div>
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Nombre de la Compañia</label>
              <input
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.companyName ? 'border-red-500' : ''}`}
                {...register("companyName")}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.companyName?.message}</span>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:space-x-4">
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Correo Electronico</label>
              <input
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.email ? 'border-red-500' : ''}`}
                type="email"
                {...register("email")}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.email?.message}</span>
            </div>
            <div className='grid'>
              <div className="grid">
                <label className='mt-4 font-extrabold'>Teléfono</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.phone ? 'border-red-500' : ''}`}
                  type="string"
                  {...register("phone")}
                />
              </div>
              <span className='text-red-600 font-semibold h-4'>{errors.phone?.message}</span>
            </div>
          </div>
          <h2 className="text-2xl font-bold my-1">Dirección: </h2>
          <div className="grid grid-cols-1">
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Calle y Número</label>
              <input
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.address?.address ? 'border-red-500' : ''}`}
                {...register("address.address")}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.address?.address?.message}</span>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 lg:space-x-2">
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Número Interior</label>
              <input className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.address?.interiorNumber ? 'border-red-500' : ''}`}
                type="number"
                {...register("address.interiorNumber")}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.address?.interiorNumber?.message}</span>
            </div>
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Código Postal</label>
              <input
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.address?.zipCode ? 'border-red-500' : ''}`}
                type="number"
                maxLength={5}
                minLength={5}
                value={controls.zipCode}
                {...register("address.zipCode")}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.address?.zipCode?.message}</span>
            </div>
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Colonia</label>
              <select
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.address?.colonyName ? 'border-red-500' : ''}`}
                {...register('address.colonyName')}
              >
                <option hidden value=""></option>
                {colonies.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              <span className='text-red-600 font-semibold h-4'>{errors.address?.colonyName?.message}</span>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 lg:space-x-2">
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Ciudad</label>
              <select
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.address?.city ? 'border-red-500' : ''}`}
                {...register('address.city')}
              >
                <option hidden value=""></option>
                {cities.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              <span className='text-red-600 font-semibold h-4'>{errors.address?.city?.message}</span>
            </div>
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Estado</label>
              <select
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.address?.state ? 'border-red-500' : ''}`}
                {...register('address.state')}
              >
                <option hidden value=""></option>
                {states.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              <span className='text-red-600 font-semibold h-4'>{errors.address?.state?.message}</span>
            </div>
            <div className='grid'>
              <label className='mt-4 font-extrabold'>País</label>
              <input
                className={`py-1 border-b-2 text-gray-500 focus:outline-none`}
                type="text"
                disabled={true}
                defaultValue="MEXICO"
              />
              <span className='text-red-600 font-semibold h-4'>{errors.address?.country?.message}</span>
            </div>
          </div>
          <div className="grid grid-cols-1 my-6">
            <input
              type="submit"
              value="Guardar"
              className={`bg-primary-inabit-red py-4 text-2xl text-white rounded-xl ${Object.keys(errors).length > 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-inabit-coffe'}`}
            >
            </input>
          </div>
        </div>
      </form>
    </>
  )
}

const withConnect = connect(
  selectState('createAddress.controls', 'createAddress.result'),
  actionProps({ ...createAddressActions }),
);

export default withRouter(withConnect(CreateAddress));
