import React, { ForwardedRef, useState } from 'react'

interface SelectValue {
  text: string
  key: String
  description: string
}
interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  history?: any
  options: SelectValue[]
  value: any,
  onSelected?: any
  filterText?: string,
  error?: string
}

const SearchInput: React.FC<SearchInputProps> = React.forwardRef(
  (props, ref: ForwardedRef<any>) => {
    const { options, onSelected, value, filterText, error, ...rest } = props
    const [selectedValue, setSelectedValue] = useState<string  | null>(value)
    const sexyFunction = (option: any) => {
      setSelectedValue(option)
      if (typeof onSelected === 'function') onSelected(option)
    }
    return (
      <div>
        {
          selectedValue ?
            <div className="flex">
              <div className="w-5/6">
                <input className="text-lg py-2 px-4 rounded-l-lg w-full" type='text' value={selectedValue} />
              </div>
              <div className="w-1/6 bg-primary-inabit-red rounded-r-lg">
                <button className="w-full py-2 font-bold text-lg" onClick={() => sexyFunction("")}>
                  X
                </button>
              </div>
            </div>
            :
            <div >
              <div className="border rounded overflow-hidden flex">
                <div className='w-5/6'>
                  <input type="text"
                    className={`px-4 py-2 w-full  focus:outline-none border-b-2 ${error ? 'border-red-500' : ''}`}
                    placeholder="Buscar descripción de paquete"
                    value={filterText}
                    {...rest}
                  />
                </div>
                <div className='w-1/6'>
                  <button className="flex items-center justify-center py-4 border-l bg-primary-inabit-red rounded-r-lg">
                    <svg
                      className="h-4 w-24 text-white font-bold"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className={`absolute bg-white py-4 rounded-lg shadow-lg min-h-0 max-h-48 min-w-fit w-128 overflow-auto ${filterText ? '' : 'hidden'}`}>
                <ul className="divide-y divide-gray-200">
                  {options.map((option: any) => (
                    <>
                      <li key={option.key} onClick={(e: any) => sexyFunction(`${option.text} - ${option.value}`)} className='py-2 list-none hover:bg-gray-200'>
                        {option.text}
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            </div>
        }
      </div>
    )
  },
);


export default SearchInput;
