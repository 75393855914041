import { APP } from '../../../commons/reducer';
import { createAction, createReducer } from '../../../utils/reducer';

// Local constants
const PATH = 'paypal_recharge/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const SAVE_RECHARGE_CREDITS = {
  SAVE_RECHARGE: `${PATH}SAVE_RECHARGE`,
  SAVE_RECHARGE_SUCCESS: `${PATH}SAVE_RECHARGE_SUCCESS`,
  SAVE_RECHARGE_FAILED: `${PATH}SAVE_RECHARGE_FAILED`,
};

// actions
export const rechargeActions = {
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  saveRechargeCredits: createAction(SAVE_RECHARGE_CREDITS.SAVE_RECHARGE, 'controls'),
  saveRechargeSuccess: createAction(SAVE_RECHARGE_CREDITS.SAVE_RECHARGE_SUCCESS, 'saveResult'),
  saveRechargeFailed: createAction(SAVE_RECHARGE_CREDITS.SAVE_RECHARGE_FAILED, 'saveResult'),
};

const initialState = {
  controls: {
    payload: {},
    errorMessage: '',
    error: false,
    loading: false,
  },
  saveResult: {}
};

const paypalRecharge = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [SAVE_RECHARGE_CREDITS.SAVE_RECHARGE](state: any) {
    state.controls.loading = true;
  },
  [SAVE_RECHARGE_CREDITS.SAVE_RECHARGE_SUCCESS](state: any, action: any) {
    state.saveResult = action.saveResult;
    state.controls.loading = false;
  },
  [SAVE_RECHARGE_CREDITS.SAVE_RECHARGE_FAILED](state: any) {
    state.controls.error = true;
    state.controls.loading = false;
    state.controls.errorMessage = 'Oops, wrong credentials.';
  },
  [APP.SERVER_FAILED](state: any) {
    state.controls.loading = false;
  },
});

export default paypalRecharge;