import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionProps, selectState } from '../../utils/reduxActions';
import { loginActions } from './reducer';
import paths from '../../config/paths'
import logo from '../../assets/img/logo-color.png'
import loginSvg from '../../assets/img/entrega.svg'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import ErrorSvg from '../../assets/img/error.svg'
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3"
import { RECAPTCHA_API_KEY } from "../../utils/constants";

interface LoginProps {
  actions?: any;
  controls?: any;
  history?: any;
}

interface LoginFormInput {
  email: string
  password: string
  ReCaptchaToken: string
}

const schema = yup.object({
  email: yup.string()
    .email('Correo electrónico no valido')
    .required('Campo obligatorio'),
  password: yup.string()
    .trim()
    .required('Campo obligatorio'),
}).required();

const Login: React.FC<LoginProps> = (props) => {
  const { actions, controls, history } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<LoginFormInput>({
    resolver: yupResolver(schema)
  });
  if (localStorage.getItem('token')) history.push(paths.DASHBOARD)

  const onLogin = (data: LoginFormInput) => actions.auth({ ...data, history })
  if (controls.error)
    setTimeout(() => {
      actions.controlsChanged({ error: false })
    }, 5000);


  return (
    <>
      <div className="h-screen min-h-screen max-h-screen flex justify-center items-center p-4 w-full m-auto">
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_API_KEY}>
          <form onSubmit={handleSubmit(onLogin)} className="rounded-xl p-2">
            <div className="grid grid-cols-1 md:grid-cols-2 xl:w-400 lg:w-400 shadow-2xl rounded-xl">
              <div className="grid bg-primary-inabit-purple md:rounded-l-xl">
                <div className="grid py-8">
                  <img src={loginSvg} alt="logo" className="object-center m-auto p-6" width={800} />
                </div>
              </div>
              <div className="grid shadow-2xl bg-white md:rounded-r-xl py-6 px-6">
                <div className="grid justify-center items-center ">
                  <img src={logo} alt="logo" className="object-center m-auto p-6" />
                </div>
                <div className="px-8 mb-4 text-center">
                  <h3 className="pt-4 mb-2 text-3xl font-bold">Log In</h3>
                </div>
                <div className="grid h-8">
                  {controls.error ?
                    <div className='flex text-red-600 font-semibold h-8 text-lg text-center m-auto content-center'>
                      <img src={ErrorSvg} alt="" width={40} /> {controls.errorMessage}
                    </div>
                    :
                    ""
                  }
                </div>
                <div className="grid grid-cols-1">
                  <div className="grid">
                    <input
                      className={`w-full text-lg p-2 bg-transparent border-2 rounded-lg focus:outline-none ${errors.email ? 'border-red-500' : ''}`}
                      type="email"
                      {...register("email", { required: true })}
                      placeholder="Email o usuario..."
                    />
                    <span className='text-red-600 font-semibold h-6 text-sm'>{
                      errors.email?.type === 'typeError' ?
                        'Valor invalido: Rango de 0 - 1' :
                        errors.email?.message}
                    </span>
                  </div>
                  <div className="grid">
                    <input
                      className={`w-full text-lg p-2 bg-transparent border-2 rounded-lg focus:outline-none ${errors.password ? 'border-red-500' : ''}`}
                      {...register("password", { required: true })}
                      type="password"
                      placeholder="Ingrese su contraseña..."
                    />
                    <span className='text-red-600 font-semibold h-6 text-sm'>{
                      errors.password?.type === 'typeError' ?
                        'Valor invalido: Rango de 0 - 1' :
                        errors.password?.message}
                    </span>
                  </div>
                </div>
                <input
                  type="submit"
                  value="Entrar"
                  disabled={Object.keys(errors).length >= 1}
                  className={`w-full text-xl text-white py-4 text-center bg-primary-inabit-red rounded-xl hover:bg-primary-inabit-purple ${Object.keys(errors).length >= 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-inabit-coffe'}`}
                />
                <hr className="my-6 " />
                <div className="pb-5 text-xl text-center">
                  <p>
                    Aún no tienes cuenta?{" "}
                    <a className="text-blue-500" href="/sign_up">
                      Regístrate ahora!
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </form>
          <GoogleReCaptcha onVerify={token => setValue("ReCaptchaToken", token)} />
        </GoogleReCaptchaProvider>
      </div>
    </>
  );
};

const withConnect = connect(
  selectState('login.controls'),
  actionProps({ ...loginActions }),
);

export default withRouter(withConnect(Login));