import produce, { setAutoFreeze } from 'immer';

setAutoFreeze(process.env.NODE_ENV !== 'production');

export const createReducer = (initialState: any, handlers: { [x: string]: any; }) => (state = initialState, action: { type: string | number; }) => {
  const actionHandler = handlers[action.type];

  return actionHandler
    ? produce(state, (draftState: any) => actionHandler(draftState, action))
    : state;
};

export const createAction = (type: any, ...argNames: any[]) => (...args: any[]) => {
  const action = {
    type,
  };
  return argNames.reduce((prevValue, currentValue, index) => {
    // eslint-disable-next-line no-param-reassign
    prevValue[currentValue] = args[index];
    return prevValue;
  }, action);
};