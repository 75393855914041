/* eslint-disable no-param-reassign */

import { APP } from '../../../commons/reducer';
import { createAction, createReducer } from '../../../utils/reducer';
// Local constants
const PATH = 'book_address/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const BOOK_ADDRESS = {
  GET_ADDRESSES: `${PATH}GET_ADDRESSES`,
  GET_ADDRESSES_SUCCESS: `${PATH}GET_ADDRESSES_SUCCESS`,
  GET_ADDRESSES_FAILED: `${PATH}GET_ADDRESSES_FAILED`,
  DELETE_ADDRESS: `${PATH}GET_ADDRESSES`,
  DELETE_ADDRESS_SUCCESS: `${PATH}DELETE_ADDRESS_SUCCESS`,
  DELETE_ADDRESS_FAILED: `${PATH}DELETE_ADDRESS_FAILED`,
};

// actions
export const bookAddressActions = {
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  getAddresses: createAction(BOOK_ADDRESS.GET_ADDRESSES),
  getAddressesSuccess: createAction(BOOK_ADDRESS.GET_ADDRESSES_SUCCESS, 'list'),
  getAddressesFailed: createAction(BOOK_ADDRESS.GET_ADDRESSES_FAILED, 'list'),
  deleteAddress: createAction(BOOK_ADDRESS.DELETE_ADDRESS, 'controls'),
  deleteAddressSuccess: createAction(BOOK_ADDRESS.DELETE_ADDRESS_SUCCESS, 'deleteResponse'),
  deleteAddressFailed: createAction(BOOK_ADDRESS.DELETE_ADDRESS_FAILED, 'deleteResponse'),
}

const initialState = {
  controls: {
    errorMessage: '',
    error: false,
    loading: false,
    id: null
  },
  list: [],
  deleteResponse: null,
};

const addressBook = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [BOOK_ADDRESS.GET_ADDRESSES](state: any) {
    state.controls.loading = true;
  },
  [BOOK_ADDRESS.GET_ADDRESSES_SUCCESS](state: any, action: any) {
    state.list = action.list.data;
    state.controls.loading = false;
  },
  [BOOK_ADDRESS.GET_ADDRESSES_FAILED](state: any) {
    state.controls.error = true;
    state.controls.loading = false;
    state.controls.errorMessage = 'Oops, wrong credentials.';
  },
  [BOOK_ADDRESS.DELETE_ADDRESS](state: any) {
    state.controls.loading = true;
  },
  [BOOK_ADDRESS.DELETE_ADDRESS_SUCCESS](state: any, action: any) {
    state.controls.close = true;
    state.controls.id = null;
    state.controls.loading = false;
    state.deleteResponse = action.deleteResponse
  },
  [BOOK_ADDRESS.DELETE_ADDRESS_FAILED](state: any, action: any) {
    state.controls.loading = false;
    state.deleteResponse = action.deleteResponse
  },
  [APP.SERVER_FAILED](state: any) {
    state.controls.loading = false;
  },
});

export default addressBook;