import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../../commons/reducer';
import { post } from '../../../utils/api';
import { rechargeActions, SAVE_RECHARGE_CREDITS } from './reducer';

function* saveRecharge() {
  yield takeLatest(SAVE_RECHARGE_CREDITS.SAVE_RECHARGE, function* fn(action: any): any {
    const body = {
      amount: action.controls.amount,
      payload: action.controls.payload,
      paymentMethod: action.controls.paymentMethod,
      paymentForm: action.controls.paymentForm
    }
    try {
      const response = yield post('/payments/save-recharge-credits', body);
      if (response.error) {
        yield put(rechargeActions.saveRechargeFailed({ response: response.errorMsg }));
      } else {
        yield put(rechargeActions.saveRechargeSuccess(response));
      }
    } catch (err) {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });
}
export default function* saga(): any {
  yield spawn(saveRecharge);
}