import * as yup from 'yup';
import { compose } from 'redux';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import paths from '../../config/paths';
import Chip from '@mui/material/Chip';
import { Tooltip } from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { appActions } from '../../commons/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { getChipColor } from '../../utils/guideStatus';
import { calculatorActions } from '../calculator/reducer';
import { actionProps, selectState } from '../../utils/reduxActions';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { shopActions } from './reducer';

interface ShopProps {
  children?: any;
  history?: any;
  links?: any;
  actions?: any;
  profile?: any;
  wooCommerce?: any;
}

interface CreateWooCommerceShopInput {
  key: string;
  secret: string;
  domain: string;
}

const CreateWooCommerceShopSchema = yup.object().shape({
  key: yup.string().trim().required('Consumer Key requerido'),
  secret: yup.string().trim().required('Consumer Secret requerido'),
  domain: yup.string().trim().required('Dominio de tu tienda requerido'),
});

const WooCommerce: React.FC<ShopProps> = (props): JSX.Element => {
  const { actions, wooCommerce, history, profile } = props;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<CreateWooCommerceShopInput>({
    resolver: yupResolver(CreateWooCommerceShopSchema),
  });

  useEffect(() => {
    if (profile.wooCommerceSecretId) actions.getShopData();
  }, [profile]);

  const quoteOrder = (data: any) => {
    actions.calculatorFormChange({ wooCommerceOrder: data, shipmentPktDetails: data.order.shipmentPktDetails});
    history.push(paths.QUOTE);
  };

  const columns: GridColDef[] = [
    { field: 'order_id', headerName: 'ID', width: 120 },
    {
      field: 'name',
      headerName: 'Pedido',
      width: 80,
      editable: false,
    },
    {
      field: 'created_at',
      headerName: 'Fecha',
      width: 180,
      editable: true,
      valueGetter: ({ value }) =>
        value &&
        moment(value).tz('America/Mexico_City').format('DD/MM/YYYY, h:mm:ss a'),
    },
    {
      field: '',
      headerName: 'Cliente',
      sortable: false,
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.customer?.first_name || ''} ${
          params.row.customer?.last_name || ''
        }`,
    },
    {
      field: 'current_total_price',
      headerName: 'Total',
      width: 80,
      type: 'number',
      editable: false,
    },
    {
      field: 'line_items',
      headerName: 'Articulos',
      width: 140,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.line_items.length} Articulo/s`,
    },
    {
      field: 'financial_status',
      headerName: 'Estado del pago del pedido',
      width: 180,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        const status: { [key: string]: string } = {
          paid: 'Pagado',
          pending: 'Pendiente de Pago',
          refunded: 'Reembolsado',
          completed: 'Completado',
          ['on-hold']: 'En espera',
          processing: 'Procesando',
          ['checkout-draft']: 'Borrador',
          failed: 'Fallido',
          cancelled: 'Cancelado'
        };
        return status[params.row.financial_status];
      },
    },
    {
      field: 'fulfillment_status',
      headerName: 'Estado de preparación del pedido',
      width: 180,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        const status: { [key: string]: string } = {
          fulfilled: 'Preparado',
          partial: 'Envio parcial',
          restocked: 'Reabastecido',
        };
        return status[params.row.fulfillment_status] || 'No preparado';
      },
    },
    {
      field: 'tags',
      headerName: 'Etiquetas',
      width: 180,
      editable: false,
    },
    {
      field: 'shippingInfo.trackingNumber',
      headerName: '# Guía',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 130,
      headerClassName: 'text-lg text-bold',
      renderCell: data => {
        return (
          data.row.shippingInfo?.trackingNumber &&
          data.row.shippingInfo?.trackingNumber
        );
      },
    },
    {
      field: 'shippingInfo.internalStatus',
      headerName: 'status',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 220,
      headerClassName: 'text-lg text-bold',
      renderCell: data => {
        const chip = getChipColor(data.row.shippingInfo?.internalStatus);
        return (
          data.row.shippingInfo?.trackStatus && (
            <Tooltip
              title={data.row.shippingInfo?.trackStatus}
              arrow
            >
              <Chip
                label={data.row.shippingInfo?.trackStatus}
                color={chip.color}
                variant='outlined'
                icon={chip.icon}
              />
            </Tooltip>
          )
        );
      },
    },
    {
      field: 'shippingInfo',
      headerName: 'Total',
      width: 80,
      editable: false,
      valueGetter: ({ value }) => value && `${value?.finalTotal} MXN`,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
      sortable: false,
      minWidth: 200,
      renderCell: data => {
        return (
          <div className='grid grid-cols-1 gap-4'>
            <Tooltip
              title='Cotizar y Generar Guía'
              arrow
            >
              <div
                className={`grid ${
                  data.row.shippingInfo
                    ? 'bg-gray-400'
                    : 'bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover'
                } rounded-lg h-12 shadow`}
              >
                <button
                  className='text-center p-4 text-white hover:text-white'
                  onClick={() =>
                    quoteOrder({ order: data.row, shop: wooCommerce?.data })
                  }
                  disabled={data.row.shippingInfo}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke-width='1.5'
                    stroke='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      d='M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'
                    />
                  </svg>
                </button>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onSubmit = (data: CreateWooCommerceShopInput) => {
    console.log('%c⧭', 'color: #807160', data);
    return actions.createShop({
      input: { key: data.key, domain: data.domain, secret: data.secret },
    });
  };

  return profile.wooCommerceSecretId ? (
    <div className='grid md:shadow-xl lg:p-4 sm:p-2 py-2 rounded-xl'>
      <h1 className='text-2xl font-bold mb-6'>Tienda {wooCommerce?.data?.name}</h1>
      <DataGrid
        rows={wooCommerce?.data?.orders?.data || []}
        columns={columns}
        density='comfortable'
        autoHeight
        pageSize={12}
        loading={wooCommerce.loading}
      />
    </div>
  ) : (
    <div className='grid md:shadow-xl lg:p-4 sm:p-2 py-2 rounded-xl '>
      <div className='grid mb-24'>
        <h1 className='font-bold text-2xl text-center my-4'>
          Bienvenido a Nuestra Nueva Integración con WooCommerce
        </h1>
        <p className='font-light text-center'>
          Estamos emocionados de ofrecerte esta nueva funcionalidad que te
          permitirá sincronizar tus órdenes de WooCommerce con nuestra
          plataforma.
        </p>
      </div>
      <form
        className='grid grid-cols-1 w-full lg:w-1/2 m-auto'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='grid justify-items-stretch'>
          <div className='grid grid-cols-1 lg:grid-cols-1'>
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Consumer Key</label>
              <input
                className={` bg-transparent border-2 text-lg py-2 px-4 rounded-lg ${
                  errors.key ? 'border-2 border-red-600' : ''
                }`}
                {...register('key')}
                placeholder='Ej: ck__1b4f02e292c22b22c222d22ab222222c'
              />
              <span className='text-red-600 font-semibold h-4'>
                {errors.key?.message}
              </span>
            </div>
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Consumer Secret</label>
              <input
                className={` bg-transparent border-2 text-lg py-2 px-4 rounded-lg ${
                  errors.secret ? 'border-2 border-red-600' : ''
                }`}
                {...register('secret')}
                placeholder='Ej: cs_1b4f02e292c22b22c222d22ab222222c'
              />
              <span className='text-red-600 font-semibold h-4'>
                {errors.secret?.message}
              </span>
            </div>
            <div className='grid'>
              <label className='mt-4 font-extrabold'>
                Dominio de tu tienda
              </label>
              <input
                className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg ${
                  errors.domain ? 'border-2 border-red-600' : ''
                }`}
                {...register('domain')}
                placeholder='Ej: https://nombreTienda.com'
              />
              <span className='text-red-600 font-semibold h-4'>
                {errors.domain?.message}
              </span>
            </div>
          </div>
          <div className='grid grid-cols-1 my-6'>
            <input
              type='submit'
              value='Crear integración'
              className={`bg-primary-inabit-red py-2 text-2xl text-white rounded-xl ${
                Object.keys(errors).length > 0
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-primary-inabit-coffe'
              }`}
            ></input>
          </div>
        </div>
      </form>
      {/* Instrucciones para obtener access_token */}
      {/* <h2>¿Cómo obtengo mi access_token de Shopify?</h2>
        <ol>
          <li>Inicia sesión en tu panel de administrador de Shopify.</li>
          <li>Navega a 'aplicaciones' y luego a 'Configuración de aplicaciones y canales de venta'.</li>
          <li>Haz clic en 'Desarrollar aplicación'.</li>
          <li>Haz clic en 'Crear una aplicación'.</li>
          <li>Dale un nombre a tu aplicación. Ej: Integración Inabit' y click en crear aplicación.</li>
          <li>En la sección de 'Permisos de API', asegúrate de establecer los permisos necesarios.</li>
          <li>Haz clic en 'Guardar' y verás una sección donde se muestra tu `access_token`.</li>
        </ol> */}
    </div>
  );
};

const withConnect = connect(
  selectState('app.profile', 'wooCommerce'),
  actionProps({ ...calculatorActions, ...appActions, ...shopActions }),
);

export default compose(withConnect)(WooCommerce);
