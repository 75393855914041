/* eslint-disable no-param-reassign */

import { APP } from '../../commons/reducer';
import { createAction, createReducer } from '../../utils/reducer';
// Local constants
const PATH = 'complete_sign_up/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const COMPLETE_SIGN_UP = {
  COMPLETE_SIGN_UP: `${PATH}COMPLETE_SIGN_UP`,
  COMPLETE_SIGN_UP_SUCCESS: `${PATH}COMPLETE_SIGN_UP_SUCCESS`,
  COMPLETE_SIGN_UP_FAILED: `${PATH}COMPLETE_SIGN_UP_FAILED`,
};

// actions
export const completeSignUpActions = {
  controlsChanged: createAction(CONTROLS_CHANGED, 'completeSignUpControls'),
  completeSignUp: createAction(COMPLETE_SIGN_UP.COMPLETE_SIGN_UP, 'completeSignUpControls'),
  completeSignUpSuccess: createAction(COMPLETE_SIGN_UP.COMPLETE_SIGN_UP_SUCCESS, 'completeSignUpResult'),
  completeSignUpFailed: createAction(COMPLETE_SIGN_UP.COMPLETE_SIGN_UP_FAILED, 'completeSignUpResult'),
};

const initialState = {
  completeSignUpControls: {
    typePerson: '',
    name: '',
    identification: '',
    socialMediaUrl: null,
    document: null,
    errorMessage: '',
    error: false,
    loading: false,
  },
  completeSignUpResult: {},
};

const completeSignUp = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.completeSignUpControls = {
      ...state.completeSignUpControls,
      ...action.completeSignUpControls,
    };
  },
  [COMPLETE_SIGN_UP.COMPLETE_SIGN_UP](state: any) {
    state.completeSignUpControls.loading = true;
  },
  [COMPLETE_SIGN_UP.COMPLETE_SIGN_UP_SUCCESS](state: any) {
    state.completeSignUpControls.typePerson = '';
    state.completeSignUpControls.name = '';
    state.completeSignUpControls.identification = '';
    state.completeSignUpControls.socialMediaUrl = '';
    state.completeSignUpControls.document = null;
    state.completeSignUpControls.loading = false;
  },
  [COMPLETE_SIGN_UP.COMPLETE_SIGN_UP_FAILED](state: any) {
    state.completeSignUpControls.error = true;
    state.completeSignUpControls.loading = false;
    state.completeSignUpControls.errorMessage = 'Oops, wrong credentials.';
  },
  [APP.SERVER_FAILED](state: any) {
    state.completeSignUpControls.loading = false;
  },
});

export default completeSignUp;