import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionProps, selectState } from '../../utils/reduxActions';
import { appActions } from '../../commons/reducer';
interface DashboardProps {
  actions?: any;
  profile?: any;
  history?: any;
}

const Dashboard: React.FC<DashboardProps> = (props) => {
  const { profile } = props
  return (
    <div className="text-blueGray-700 antialiased">

      <div className="relative md:ml-42 bg-blueGray-50">
        <div className="relative bg-primary-inabit-red md:pt-32 pb-32 pt-12 rounded-xl shadow-xl">
          <div className="px-4 md:px-10 mx-auto w-full">
            <div>
              <div className="grid grid-cols-1 lg:grid-cols-4">
                <div className="grid px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white rounded-xl mb-6 xl:mb-0 shadow-xl">
                    <div className="flex-auto p-4">
                      <div className="flex flex-wrap">
                        <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                          <h5 className="text-blueGray-400 uppercase font-bold text-xl">
                            Créditos disponibles
                          </h5>
                          <span className="font-semibold text-3xl text-blueGray-700">
                            $ {profile.credits?.toFixed(2) || 0} MXN
                          </span>
                        </div>
                        <div className="relative w-auto pl-4 flex-initial">
                          <div className="text-white p-4 text-center inline-flex items-center justify-center w-14 h-14 shadow-lg rounded-full bg-red-500 text-xl">
                            <i className="fas fa-dollar-sign"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid px-4">
                  <div
                    className="relative flex flex-col min-w-0 break-words bg-white rounded-xl mb-6 xl:mb-0 shadow-xl"
                  >
                    <div className="flex-auto p-4">
                      <div className="flex flex-wrap">
                        <div
                          className="relative w-full pr-4 max-w-full flex-grow flex-1"
                        >
                          <h5 className="text-blueGray-400 uppercase font-bold text-xl">
                            Guías generadas
                          </h5>
                          <span className="font-semibold text-3xl text-blueGray-700">
                            {profile.totalOfShippings || 0}
                          </span>
                        </div>
                        <div className="relative w-auto pl-4 flex-initial">
                          <div className="text-white p-4 text-center inline-flex items-center justify-center w-14 h-14 shadow-lg rounded-full bg-red-500 text-xl">
                            <i className="fas fa-bus"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const withConnect = connect(
  selectState(
    'app.profile',
  ),
  actionProps({ ...appActions }),
);

export default withRouter(withConnect(Dashboard));
