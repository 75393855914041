import { createReducer, createAction } from '../utils/reducer';

// GLOBAL CONSTANTS
const PATH = 'app/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const APP_CONTROLS_CHANGED = `${PATH}APP_CONTROLS_CHANGED`;

export const APP = {
  SERVER_FAILED: `${PATH}SERVER_FAILED`,
  GET_SETTINGS: `${PATH}GET_SETTINGS`,
  GET_SETTINGS_SUCCESS: `${PATH}GET_SETTINGS_SUCCESS`,
  GET_SETTINGS_FAILED: `${PATH}GET_SETTINGS_FAILED`,
  GET_PROFILE_INFO: `${PATH}GET_PROFILE_INFO`,
  GET_PROFILE_INFO_SUCCESS: `${PATH}GET_PROFILE_INFO_SUCCESS`,
  GET_PROFILE_INFO_FAILED: `${PATH}GET_PROFILE_INFO_FAILED`,
  LOGOUT: `${PATH}LOGOUT`,
};

export const appActions = {
  logout: createAction(APP.LOGOUT),
  appControlsChanged: createAction(APP_CONTROLS_CHANGED, 'appControls'),
  serverFailed: createAction(APP.SERVER_FAILED, 'message'),
  getProfileInfo: createAction(APP.GET_PROFILE_INFO, 'profile'),
  getProfileInfoSucess: createAction(APP.GET_PROFILE_INFO_SUCCESS, 'profile'),
  getProfileInfoFailed: createAction(APP.GET_PROFILE_INFO_FAILED, 'profile'),
};

const initialState = {
  appControls: {
    errorMessage: '',
    error: false,
    loading: false
  },
  profile: {},
};

const app = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [APP_CONTROLS_CHANGED](state: any, action: any) {
    state.appControls = {
      ...state.appControls,
      ...action.appControls,
    };
  },
  [APP.SERVER_FAILED](state: any, action: any) {
    state.appControls.errorMessage = action.message;
    state.appControls.error = true;
    state.appControls.loading = false;
  },
  [APP.GET_PROFILE_INFO](state: any, action: any) {
    state.appControls.loading = true;
  },
  [APP.GET_PROFILE_INFO_SUCCESS](state: any, action: any) {
    state.appControls.loading = false;
    state.profile = action.profile;
  },
  [APP.GET_PROFILE_INFO_FAILED](state: any, action: any) {
    state.appControls.loading = false;
    state.appControls.errorMessage = 'Ocurrio un error';
  },
});

export default app;