import { APP } from '../../commons/reducer';
import { createAction, createReducer } from '../../utils/reducer';
const PATH = 'shop/';

// Global constants for saga
export const SHOP = {
  GET_SHOP_DATA: `${PATH}GET_SHOP_DATA`,
  GET_SHOP_DATA_SUCCESS: `${PATH}GET_SHOP_DATA_SUCCESS`,
  GET_SHOP_DATA_FAILED: `${PATH}GET_SHOP_DATA_FAILED`,
  CREATE_SHOP: `${PATH}CREATE_SHOP`,
  CREATE_SHOP_SUCCESS: `${PATH}CREATE_SHOP_SUCCESS`,
  CREATE_SHOP_FAILED: `${PATH}CREATE_SHOP_FAILED`,
};

// actions
export const shopActions = {
  getShopData: createAction(SHOP.GET_SHOP_DATA, 'data'),
  getShopDataSuccess: createAction(SHOP.GET_SHOP_DATA_SUCCESS, 'data'),
  getShopDataFailed: createAction(SHOP.GET_SHOP_DATA_FAILED, 'data'),
  createShop: createAction(SHOP.CREATE_SHOP, 'createShop'),
  createShopSuccess: createAction(SHOP.CREATE_SHOP_SUCCESS, 'createShop'),
  createShopFailed: createAction(SHOP.CREATE_SHOP_FAILED, 'createShop'),
};

const initialState = {
  data: null,
  errorMessage: null,
  error: false,
  loading: false,
  createShop: {
    input: {
      accessToken: null,
      domain: null
    },
  }
};

const shop = createReducer(initialState, {
  [SHOP.GET_SHOP_DATA](state: any) {
    state.loading = true;
  },
  [SHOP.GET_SHOP_DATA_SUCCESS](state: any, action: any) {
    state.loading = false;
    state.data = action.data;
  },
  [SHOP.GET_SHOP_DATA_FAILED](state: any, action: any) {
    state.error = true;
    state.loading = false;
    state.data = action.data
  },
  [SHOP.CREATE_SHOP](state: any) {
    state.loading = true;
  },
  [SHOP.CREATE_SHOP_SUCCESS](state: any, action: any) {
    state.loading = false;
    state.createShop = action.createShop
  },
  [SHOP.CREATE_SHOP_FAILED](state: any, action: any) {
    state.error = true;
    state.loading = false;
    state.createShop = { output: action.data }
  },
  [APP.SERVER_FAILED](state: any) {
    state.loading = false;
  },
});

export default shop;
