import { REACT_APP_POSTAL_CODE_API_URL } from './constants';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export const getColonies = (data: any) => {
  const colonies: string[] = []
  data.forEach((e: any) => {
    colonies.push(e.colonyName)
  });
  return colonies
}


export const existPostalCode = async (postalCode: string) => {
  const result = await axios({
    method: 'get',
    url: `${REACT_APP_POSTAL_CODE_API_URL}/mx?postalCode=${postalCode}`
  })
  if (result?.data?.data?.length === 0) {
    MySwal.fire({
      icon: 'error',
      title: `Código postal invalido`,
      text: 'Por favor verifica el código postal ingresado',
    })
    return { exists: false, data: [] }
  } else {
    return { exists: true, data: result.data.data }
  }
}
