/* eslint-disable no-param-reassign */

import { APP } from '../../commons/reducer';
import { createAction, createReducer } from '../../utils/reducer';
// Local constants
const PATH = 'shiphmentList/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const REPORT_CLEAR = `${PATH}REPORT_CLEAR`;

// Global constants for saga
export const SHIPMENT = {
  GET_SHIPMENT_LIST: `${PATH}SHIPMENT_LIST`,
  GET_SHIPMENT_LIST_SUCCESS: `${PATH}SHIPMENT_LIST_SUCCESS`,
  GET_SHIPMENT_LIST_FAILED: `${PATH}SHIPMENT_LIST_FAILED`,
  GET_REPORT: `${PATH}REPORT`,
  GET_REPORT_SUCCESS: `${PATH}REPORT_SUCCESS`,
  GET_REPORT_FAILED: `${PATH}REPORT_FAILED`
};

// actions
export const shipmentActions = {
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  getShipmentList: createAction(SHIPMENT.GET_SHIPMENT_LIST),
  getShipmentListSuccess: createAction(SHIPMENT.GET_SHIPMENT_LIST_SUCCESS, 'list'),
  getShipmentListFailed: createAction(SHIPMENT.GET_SHIPMENT_LIST_FAILED, 'list'),
  getReport: createAction(SHIPMENT.GET_REPORT, 'report'),
  getReportSuccess: createAction(SHIPMENT.GET_REPORT_SUCCESS, 'report'),
  getReportFailed: createAction(SHIPMENT.GET_REPORT_FAILED, 'report'),
  reportClear: createAction(REPORT_CLEAR, 'report'),
}

const initialState = {
  controls: {
    errorMessage: '',
    error: false,
    loading: false,
  },
  list: [],
  report: {
    url: null,
    filters: {
      dateFrom: null,
      dateTo: null,
      emails: null,
    },
    success: null,
    loading: false,
  }
};

const shipmentList = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [SHIPMENT.GET_SHIPMENT_LIST](state: any) {
    state.controls.loading = true;
  },
  [SHIPMENT.GET_SHIPMENT_LIST_SUCCESS](state: any, action: any) {
    state.list = action.list;
    state.controls.loading = false;
  },
  [SHIPMENT.GET_SHIPMENT_LIST_FAILED](state: any) {
    state.controls.error = true;
    state.controls.loading = false;
    state.controls.errorMessage = 'Oops, wrong credentials.';
  },
  [REPORT_CLEAR](state: any, action: any) {
    state.report = {
      url: null,
      success: null,
      loading: false,
    };
  },
  [SHIPMENT.GET_REPORT](state: any, actions: any) {
    state.report.filters = actions.filters;
    state.report.loading = true;
  },
  [SHIPMENT.GET_REPORT_SUCCESS](state: any, action: any) {
    state.report.url = action.report;
    state.report.success = true;
    state.report.loading = false;
  },
  [SHIPMENT.GET_REPORT_FAILED](state: any, action: any) {
    state.report.loading = false;
    state.report.success = false;
  },
  [APP.SERVER_FAILED](state: any) {
    state.controls.loading = false;
  },
});

export default shipmentList;
