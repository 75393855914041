import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../commons/reducer';
import { post } from '../../utils/api';
import { rechargeCreditsActions, RECHARGE_CREDITS } from './reducer';

function* rechargeCredits() {
  yield takeLatest(RECHARGE_CREDITS.RECHARGE, function* fn(action: any): any {
    const body = {};
    try {
      const response = yield post('/payments/stripe/recharge-credits', body);
      if (response.error) {
        yield put(rechargeCreditsActions.rechargeCreditsFailed({ response: response.errorMsg }));
      } else {
        yield put(rechargeCreditsActions.rechargeCreditsSuccess(response));
      }
    } catch (err) {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });
}
export default function* saga(): any {
  yield spawn(rechargeCredits);
}