import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarningIcon from '@mui/icons-material/Warning';

type ChipColor = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

export const getChipColor = (status: string): { color: ChipColor, icon: React.ReactElement } => {
  const initStatus = ['SWB', 'IN',];
  const successStatus = ['DL', 'HDC', 'WHU', 'HBC','OK', 'BDL'];
  const inTransitStatus = ['IT', 'TRN', 'OW'];
  const warningStatus = ['HDR', 'DE']
  if (initStatus.includes(status)) {
    return {
      color: 'default',
      icon: <InfoIcon />
    }
  }
  if (successStatus.includes(status)) {
    return {
      color: 'success',
      icon: <CheckIcon />
    }
  }
  if (inTransitStatus.includes(status)) {
    return {
      color: 'info',
      icon: <LocalShippingIcon />
    }
  }
  if (warningStatus.includes(status)) {
    return {
      color: 'warning',
      icon: <WarningIcon />
    }
  }
  return {
    color: 'default',
    icon: <InfoIcon />
  }
}
