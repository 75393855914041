export const searchString = (search: string): RegExp => {
  search = search.trim();
  search = search.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  search = search.replace(new RegExp("e", "gi"), "[ée]");
  search = search.replace(new RegExp("a", "gi"), "[áa]");
  search = search.replace(new RegExp("i", "gi"), "[íi]");
  search = search.replace(new RegExp("o", "gi"), "[óo]");
  search = search.replace(new RegExp("u", "gi"), "[úu]");
  search = search.replace(new RegExp("n", "gi"), "[ñn]");
  const regex = new RegExp(search, "gi");
  return regex;
};
