import moment from 'moment';

export const getItemInStorage = (name: string): any => JSON.parse(localStorage.getItem(name) || '{}');

export const getUser = (): any => getItemInStorage('token');

export const capitalize = (str: string): string => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

export const cleaningNullValues = (object: any) => {
  for (let key in object) {
    if (object[key] === null || object[key] === undefined || object[key] === "") {
      delete object[key]
    }
  }
  return object
}

export const formatDate = (date: string) =>
  date
    ? moment(date).tz('America/Mexico_City').format('DD/MM/YYYY, h:mm:ss a')
    : '';
