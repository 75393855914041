import { getItemInStorage } from './functions';
import { SERVER_URL, APPLICATION_KEY, SECRET_KEY } from './constants'

export default function* apiRequest(url: string, options: any, headers?: object): any {
  const token = getItemInStorage('token');
  const res = yield fetch(`${SERVER_URL}${url}`, {
    ...options,
    headers: {
      Authorization: `Bearer ${token}`,
      'application': APPLICATION_KEY,
      'secret': SECRET_KEY,
      'Content-Type': 'application/json',
      ...headers
    },
  })
    .then((response) => {
      if (response.ok) return response.json();
      return response.text().then((errorMsg) => ({ error: true, errorMsg }));
    })
    .catch((err) => {
      console.log('ERRRORRRR', err);
    });
  return res;
}

export function objectToQueryString(obj: any): string {
  return Object.keys(obj)
    .map((k) => {
      if ((obj[k])) {
        return Array.isArray(obj[k])
          ? obj[k].map((o: any) => `${encodeURIComponent(k)}[]=${encodeURIComponent(o)}`).join('&')
          : `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`;
      }
      return '';
    })
    .join('&');
}

export function* post(path: string, body: any, headers?: object): any {
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
  };
  return yield apiRequest(path, options, headers);
}

export function* update(path: string, body: any): any {
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
  };
  return yield apiRequest(path, options);
}

export function* destroy(path: string, id: any): any {
  const options = {
    method: 'DELETE',
  };
  return yield apiRequest(`${path}/${id}`, options);
}

export function* get(path: string): any {
  const options = {
    method: 'GET',
  };
  return yield apiRequest(`${path}`, options);
}