import React, { useState, useEffect } from 'react';
import {
  CardContent,
  Grid,
  Button,
  Image,
  Card,
  Header,
  Input,
  CardGroup,
  Menu,
  Item,
} from 'semantic-ui-react';
import paths from '../../config/paths';
import '../styles/home.css';


import Person1 from '../../assets/person1.png';
import OfertIconRed from '../../assets/ofertIconRed.svg';
import OfertIconBlue from '../../assets/ofertIconBlue.svg';
import OfertIconGreen from '../../assets/ofertIconGreen.svg';
//Function Icons
import FunctionIcon1 from '../../assets/functions/Icon1.svg';
import FunctionIcon2 from '../../assets/functions/Icon2.svg';
import FunctionIcon3 from '../../assets/functions/Icon3.svg';
//Brands Image
import MiniLogo from '../../assets/miniLogo.svg';
import Branch from './components/branch';
import Footer from './components/footer';
import Carousel from './components/carousel';
import Form from './components/form';
import Benefits from './components/benefits';
import MultiModal from './components/modal';

interface HomeProps {
  actions?: any;
  controls?: any;
  history?: any;
}


const Home: React.FC<HomeProps> = (props) => {
  const { history } = props
  const [activeItem, setActiveItem] = useState('Sucursales');
  const [widthScreen, setwidthScreen] = useState<number>(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setwidthScreen(window.innerWidth);
    });
  }, [widthScreen])


  return (
    <CardContent id='container'>
      {/* Section 1 */}
      <CardContent id='segmentHome'>
        <Grid padded='vertically'>
          {/* Left Column */}
          <Grid.Column floated='left' id='leftColumn'>
            {widthScreen > 1000 && <Image id='miniLogo' src={MiniLogo} />}
            {widthScreen <= 1000 &&
              <div className='grid grid-cols-2 space-x-1 items-center'>
                <div className="grid">
                  <Image src={MiniLogo} />
                </div>
                <div className="grid space-x-4 justify-items-end grid-flow-col auto-cols-max justify-end">
                  <div className="grid align-middle">
                    <button className='bg-primary-inabit-red rounded-lg p-2 text-white' onClick={() => window.location.href = '#section6'}>Sucursales</button>
                  </div>
                  <div className="grid align-middle">
                    <button onClick={() => history.push(paths.LOGIN)} className='bg-primary-inabit-red rounded-lg p-2 text-white'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            }
            <CardContent id='sloganBox'><p id='slogan1'>Ocúpate de disfrutar</p><p id='slogan2'> que de tus envíos nos ocupamos nosotros.</p></CardContent>
            {/*  Flow Bar [Rastrea, Cotiza, Envía] */}
            {/* <FlowBar/> */}
            <CardContent id='temporalModule'>
              <MultiModal />
              <CardContent>
                <Header id='headerFormServiceTemporalModule' content='Contáctanos' />
                <CardGroup id='inputGroupTemporalModule'>
                  <Input id='temporalModuleInput' placeholder='Ingresa Correo' />
                  <Button id='btnServicesTemporalModule' content='Enviar' />
                </CardGroup>
                <p id='helpUserTemporalModule'>Necesitas ayuda? Servicio de 48-72 Hrs.</p>
              </CardContent>
            </CardContent>
          </Grid.Column>
          {/* Right Column */}
          <Grid.Column floated='right' id='rightColumn'>
            {/* Menu */}
            {widthScreen > 1000 && <Menu id='menuRightColumn' secondary>
              <Menu.Menu position='right' id='menuDiv'>
                <Menu.Item
                  href='#section2'
                  id={activeItem === 'Promociones' ? 'MenuItemActive' : 'MenuItem'}
                  name='Promociones'
                  onClick={() => setActiveItem('Promociones')}
                />
                <Menu.Item
                  href='#section5'
                  id={activeItem === 'Contacto' ? 'MenuItemActive' : 'MenuItem'}
                  name='Contactos'
                  onClick={() => setActiveItem('Contacto')}
                />
                <Menu.Item
                  href='#section6'
                  id={activeItem === 'Sucursales' ? 'MenuItemActive' : 'MenuItem'}
                  name='Sucursales'
                  onClick={() => setActiveItem('Sucursales')}
                />
                <Menu.Item id={activeItem === 'Iniciar Sesión' ? 'MenuItemActive' : 'MenuItem'}
                  onClick={() => { setActiveItem('Iniciar Sesión'); window.location.href = '/login' }}
                >Iniciar Sesión</Menu.Item>
              </Menu.Menu>
            </Menu>}
            <Header id='headerOfert' content='Últimas ofertas' />
            {/* Ofertas */}
            <Item.Group id='cardOfert'>
              <CardGroup id='itemOfert'>
                <Item.Image id='iconOfert' src={OfertIconRed} />
                <p id='textOfert'><b style={{ color: '#F74550', marginRight: 10 }}>LATAM</b> 1 KG - (48-72HRS) - <b>$865.00</b></p>
              </CardGroup>
              <CardGroup id='itemOfert2'>
                <Item.Image id='iconOfert' src={OfertIconGreen} />
                <p id='textOfert'><b style={{ color: '#37C99E', marginRight: 10 }}>EUROPA</b> 1 KG - (48HRS) - <b>$935.00</b></p>
              </CardGroup>
              <CardGroup id='itemOfert3'>
                <Item.Image id='iconOfert' src={OfertIconBlue} />
                <p id='textOfert'><b style={{ color: '#0878D7', marginRight: 10 }}>EUA</b> 20 KG - (5-7DÍAS) - <b>$1,510.00</b></p>
              </CardGroup>
              <CardGroup id='itemOfert3'>
                <Item.Image id='iconOfert' src={OfertIconBlue} />
                <p id='textOfert'><b style={{ color: '#0878D7', marginRight: 10 }}>EUA</b> 1 KG - (24-48HRS) - <b>$710.00</b></p>
              </CardGroup>
            </Item.Group>
            <Image id='person1' src={Person1} />
          </Grid.Column>
        </Grid>
      </CardContent>
      {/* Section 2 [Carousel]*/}
      <Carousel widthScreen={widthScreen} />

      {/* Section 3 [how does it service] */}
      <CardContent id='section3'>
        <CardContent id='textContainer'>
          <p id='howDoesItTextSection3'>¿CÓMO FUNCIONA?</p>
          <Header id='titleSection3' content='Estamos aquí para entregar tus envíos de la manera más económica y rápida.' />
          <p id='textSection3'>Consigue que tus clientes revisan el mejor servicio al menor costo y haz crecer tu negocio con nuestra plataforma de marketing avanzada.</p>
        </CardContent>
        <CardGroup id='divCardFunctions'>
          <Card
            id='cardFunctions1'
            image={FunctionIcon1}
            header='1. Regístrate'
            description='En menos de 5 minutos; confirma tu correo, documentación, haz una recarga y ¡estas listo para enviar! '
          />
          <Card
            id='cardFunctions2'
            image={FunctionIcon2}
            header='2. Cotiza'
            description='Revisa nuestros servicios disponibles y escoge el que mejor se adapte a tus necesidades.'
          />
          <Card
            id='cardFunctions3'
            image={FunctionIcon3}
            header='3. Envía'
            description='Genera tu guía, programa recolección o llévalo a tu sucursal más cercana y ¡listo!'
          />
        </CardGroup>
        <Button content='Empezar ahora' id='btnServices2' onClick={() => history.push(paths.SIGN_UP)} />
      </CardContent>

      {/* Section 4 [Brands and benefits] */}
      <Benefits widthScreen={widthScreen} />

      {/* Section 5 [Form]*/}
      <Form />

      {/* Section 6 [Map] */}
      <Branch widthScreen={widthScreen} />

      {/* Section 7 [Footer] */}
      <Footer widthScreen={widthScreen} />
    </CardContent>
  );
};

export default Home;