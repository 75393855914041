import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

type width = 'auto' | 'tiny' | 'small' | 'large';

interface ModalProps {
  children: any;
  isOpen: boolean;
  onClose: any
  size?: width
}

const Modal: React.FC<ModalProps> = (props) => {
  let modalSize: string
  switch (props.size) {
    case 'auto':
      modalSize = 'md:w-auto lg:w-auto'
      break;
    case 'tiny':
      modalSize = 'md:w-96 lg:w-96'
      break;
    case 'small':
      modalSize = 'md:w-144 lg:w-144';
      break;
    default:
      modalSize = 'md:w-3/5 lg:w-2/5'
      break;
  }
  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10000" onClose={props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full ${modalSize} transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}>
                  <Dialog.Title
                    as="h3"
                    className="text-right"
                  >
                    <button
                      type="button"
                      className="w-8 bg-gray-200 rounded-lg p-2 font-bold text-lg"
                      onClick={props.onClose}
                    >
                      X
                    </button>
                  </Dialog.Title>
                  {props.children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}


export default Modal;