import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { actionProps, selectState } from '../../../utils/reduxActions';
import { bookAddressActions } from '../AddressBook/reducer';


interface DeleteAddressProps extends RouteComponentProps {
  actions?: any;
  controls?: any;
  history: any;
  result?: any;
  onClose?: any
}

const DeleteAddress: React.FC<DeleteAddressProps> = (props) => {
  const { actions, controls, onClose } = props;
  const onDelete = () => actions.deleteAddress({ ...controls })

  return (
    <>
      <div className='grid grid-cols-1'>
        <div className="grid justify-center ">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-32 w-32 text-red-400 text-center" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <div className="grid mb-6">
          <p className='text-center font-weight text-4xl'>¿Deseas eliminar esta dirección?</p>
        </div>
        <div className="grid grid-cols-2 space-x-4">
          <div className="grid">
            <button className='text-white font-bold p-4 rounded-xl items-center bg-blue-500 hover:bg-blue-600' onClick={onDelete}>Confirmar</button>
          </div>
          <div className="grid">
            <button className='text-white font-bold p-4 rounded-xl items-center bg-red-500 hover:bg-red-600' onClick={onClose}>Cancelar</button>
          </div>
        </div>
      </div>
    </>
  )
}

const withConnect = connect(
  selectState(
    'addressBook.controls',
    'addressBook.list',
    'addressBook.deleteResponse',
  ),
  actionProps({ ...bookAddressActions }),
);

export default withRouter<DeleteAddressProps, any>(withConnect(DeleteAddress));