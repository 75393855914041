/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import {
  CardContent,
  CardGroup,
  Header,
} from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3"
import { RECAPTCHA_API_KEY } from "../../../utils/constants";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actionProps, selectState } from "../../../utils/reduxActions";
import { homeActions } from "../reducer";

interface ContactUsFormInput {
  name: string
  phone: string
  email: string
  subject: string
  detail: string
  ReCaptchaToken: string
  acceptTermAndConditions: boolean
}

const schema = yup.object({
  name: yup
    .string()
    .trim()
    .min(1)
    .required('Campo obligatorio'),
  phone: yup
    .string()
    .trim()
    .min(1)
    .required('Campo obligatorio'),
  email: yup
    .string()
    .email('Correo electrónico no valido')
    .trim()
    .min(1)
    .required('Campo obligatorio'),
  subject: yup
    .string()
    .trim()
    .min(1)
    .required('Campo obligatorio'),
  detail: yup
    .string()
    .trim()
    .min(1)
    .required('Campo obligatorio'),
  acceptTermAndConditions: yup.boolean().default(false).required()
}).required();


interface FormProps {
  children?: any;
  actions?: any;
  controls?: any;
  history?: any;
}

const Form: React.FC<FormProps> = (props) => {
  const { actions, history, controls } = props;
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<ContactUsFormInput>({
    resolver: yupResolver(schema)
  });

  const dropdownOptions = [
    { key: 'Franquicia', text: 'Franquicia', value: 'Franquicia' },
    { key: 'Ecommerce', text: 'Ecommerce', value: 'Ecommerce' },
    { key: 'Fulfillment', text: 'Fulfillment', value: 'Fulfillment' },
    { key: 'EnviosInternacionales', text: 'Envios Internacionales', value: 'Envios Internacionales' }
  ]

  const onSubmit = (data: ContactUsFormInput) => {
    actions.contactUs({ ...data, history });
    reset();
  }

  return (
    <CardGroup id='section5'>
      {/* Left Column */}
      <CardContent id='leftColumnSection5'>
        <p id='howDoesItTextSection5'>CONTÁCTANOS</p>
        <Header id='titleSection5' content='¿Tienes algún proyecto en puerta o simplemente una idea de negocio?' />
        <p id='textSection5'>Nosotros te ayudamos con nuestro servicio de fulfillment y marketing. Donde no solo empacamos tu producto y lo distribuimos por toda la república sino también nos encargamos de la parte de promoción y redes sociales para incrementar tus ventas. Deja tus datos y un ejecutivo de ventas se pondrá en contacto de manera inmediata.</p>
      </CardContent>
      {/* Right Column */}
      <div id='rightColumnSection5' className='grid grid-cols-1'>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_API_KEY}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-2 md:space-x-4 mb-4">
              <div className="grid">
                <input id='halfInputForm'
                  className='p-2 w-full'
                  {...register("name", { required: true })}
                  placeholder='Nombre completo*'
                />
              </div>
              <div className="grid">
                <input id='halfInputForm'
                  className='p-2 w-full'
                  {...register("phone", { required: true })}
                  placeholder='Número Contacto*'
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:space-x-4 mb-4">
              <div className="grid">
                <input
                  id='halfInputForm'
                  className='p-2 w-full'
                  placeholder='E-mail*'
                  type="email"
                  {...register("email", { required: true })}
                />
              </div>
              <div className="grid">
                <select
                  id='halfInputForm'
                  className={`bg-transparent w-full text-lg p-2 px-4 rounded-lg ${errors.subject ? "border-red-600" : ""}`}
                  {...register("subject")}
                >
                  <option hidden value="">¿En qué te ayudamos?</option>
                  {
                    dropdownOptions.map((option) => (
                      <option value={option.value} key={option.key}>{option.text}</option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className="grid grid-cols-1 mb-4">
              <div className="grid">
                <textarea
                  id='halfInputForm'
                  className='p-2 w-full'
                  placeholder='Cuéntanos sobre tu idea…'
                  {...register("detail", { required: true })}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:space-x-4 mb-4">
              <div className="grid">
                <div className="grid grid-cols-8">
                  <div className="col-start-1 col-end-2">
                    <input type="checkbox" {...register("acceptTermAndConditions", { required: true })} />
                  </div>
                  <div className="col-start-2 col-end-8">
                    <p id='checkBoxText'> Acepta las <b><a href='https://drive.google.com/file/d/1cYlLvOywYQ4q9KucQcTIrttwt60oVNlU/view?usp=sharing' target="_blank">políticas de devolución</a></b> consignadas en el documento, además de  la recopilación de mi información personal con base en la <b><a href='https://drive.google.com/file/d/1cYlLvOywYQ4q9KucQcTIrttwt60oVNlU/view?usp=sharing' target="_blank">Políticas de privacidad </a></b> para fines de marketing y ventas *</p>
                  </div>
                </div>
              </div>
              <div className="grid">
                <input type="submit" value='Enviar' id='btnSend' disabled={controls.loading} className={controls.loading ? 'bg-gray-500' : 'bg-primary-inabit-red text-white'} />
              </div>
            </div>
          </form>
          <GoogleReCaptcha onVerify={token => setValue("ReCaptchaToken", token)} />
        </GoogleReCaptchaProvider>
      </div>
    </CardGroup >
  );
}

const withConnect = connect(
  selectState("home.controls"),
  actionProps({ ...homeActions }),
);

export default withRouter(withConnect(Form));