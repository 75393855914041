export const SERVER_URL = process.env.REACT_APP_SERVER_URL || ""
export const APPLICATION_KEY = process.env.REACT_APP_APPLICATION_KEY || ""
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || ""
export const POSTAL_CODE_API_URL = process.env.REACT_APP_POSTAL_CODE_API_URL || ""
export const STRIPE_API_CLIENT = process.env.REACT_APP_STRIPE_API_CLIENT || ""
export const DEFAULT_PAYMENT_METHOD = process.env.REACT_APP_DEFAULT_PAYMENT_METHOD || ""
export const MIN_AMOUNT_TO_RECHARGE = process.env.REACT_APP_MIN_AMOUNT_TO_RECHARGE || 400
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID || ""
export const REACT_APP_POSTAL_CODE_API_URL = process.env.REACT_APP_POSTAL_CODE_API_URL || ""
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || ""
export const RECAPTCHA_API_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY || ""
export const FACTURAMA_GENERAL_PUBLIC_RFC = process.env.REACT_APP_FACTURAMA_GENERAL_PUBLIC_RFC || ""
export const FACTURAMA_GENERAL_PUBLIC_RFC_NAME = process.env.REACT_APP_FACTURAMA_GENERAL_PUBLIC_RFC_NAME || ""
export const FACTURAMA_GENERAL_PUBLIC_REGIMEN_FISCAL = process.env.REACT_APP_FACTURAMA_GENERAL_PUBLIC_REGIMEN_FISCAL || ""
export const FACTURAMA_GENERALPUBLIC_CFDI = process.env.REACT_APP_FACTURAMA_GENERAL_PUBLIC_CFDI || ""
export const FACTURAMA_GENERAL_PUBLIC_CODIGO_POSTAL = process.env.REACT_APP_FACTURAMA_GENERAL_PUBLIC_CODIGO_POSTAL || ""
