import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionProps, selectState } from '../../../utils/reduxActions';
import { updateAddressActions } from './reducer';
import '../../styles/views.css';
import { Dimmer, Loader } from 'semantic-ui-react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { existPostalCode } from '../../../utils/colonies'


interface UpdateAddressFormInputs {
  addressType: string
  nameOfIndividual: string
  email: string
  phone: string
  companyName: string
  address: {
    address: string
    interiorNumber: string
    zipCode: string
    colonyName: string
    city: string
    state: string
    country: string
  }
}

const phoneRegExp = /^[0-9]{10}$/gm
const UpdateAddressSchema = yup.object().shape({
  nameOfIndividual: yup.string().trim().max(23, 'Max. número de caracteres permitidos. 23').required('Campo requerido'),
  email: yup.string().email('Email no valido').required('Campo requerido'),
  phone: yup
    .string()
    .matches(phoneRegExp, "Número no valido, ej. 5532990834")
    .required('Campo requerido'),
  companyName: yup.string().trim().max(23, 'Max. número de caracteres permitidos. 23').required('Campo requerido'),
  address: yup.object().shape({
    address: yup.string().trim().required('Campo requerido'),
    interiorNumber: yup.string().trim().required('Campo requerido'),
    zipCode: yup
      .string()
      .min(5, 'El Código postal debe contener 5 caracteres numéricos')
      .max(5, 'El Código postal debe contener 5 caracteres numérico').trim().required('Campo requerido'),
    colonyName: yup.string().trim().required('Campo requerido'),
    city: yup.string().trim().required('Campo requerido'),
    state: yup.string().trim().required('Campo requerido'),
    country: yup.string().trim(''),
  })
});

interface UpdateAddressProps {
  actions?: any;
  form?: any;
  history?: any;
  updateResponse?: any;
}

const getData = (data: any) => {
  const colonies: any[] = []
  const cities: any[] = []
  const states: any[] = []
  data.forEach((e: any) => {
    colonies.push(e.colonyName)
    cities.push(e.city)
    states.push(e.state)
  });
  return {
    colonies,
    cities,
    states
  }
}

const UpdateAddress: React.FC<UpdateAddressProps> = (props) => {
  const { actions, form, history } = props;
  const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm<UpdateAddressFormInputs>({
    resolver: yupResolver(UpdateAddressSchema)
  });
  const [colonies, setColonies] = useState<string[]>([])
  const [cities, setCities] = useState<string[]>([])
  const [states, setStates] = useState<string[]>([])
  const zipcode = watch('address.zipCode')

  useEffect(() => {
    setValue('address.country', 'México')
    const searchZipCode = async (zipcode: string) => {
      const res = await existPostalCode(zipcode)
      if (res.exists) {
        const data = getData(res.data)
        setColonies([...new Set(data.colonies)])
        setCities([...new Set(data.cities)])
        setStates([...new Set(data.states)])
      }
    }
    if (zipcode?.length === 5) searchZipCode(zipcode)
  }, [actions, watch, setValue, zipcode]);


  useEffect(() => {
    const searchZipCode = async (zipcode: string) => {
      const res = await existPostalCode(zipcode)
      if (res.exists) {
        const data = getData(res.data)
        setColonies([...new Set(data.colonies)])
        setCities([...new Set(data.cities)])
        setStates([...new Set(data.states)])
      }
    }
    if (form.address.zipCode) searchZipCode(form.address.zipCode)
  }, [setValue, form.address.zipCode]);

  const onSubmit = (data: UpdateAddressFormInputs) => actions.updateAddress({ id: form.id, ...data, history })

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dimmer active={form.loading} inverted>
          <Loader inverted>Actualizando.....</Loader>
        </Dimmer>
        <div className="grid bg-white">
          <h2 className="text-2xl font-bold my-4 text-center uppercase">Actualizar dirección</h2>
          <h2 className="text-xl font-bold my-2">Datos personales: </h2>
          <hr className="mt-1 mb-4 md:min-w-full " />
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:space-x-4">
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Nombre</label>
              <input
                className={`w-full py-1 border-b-2 text-gray-500 focus:outline-none ${errors.nameOfIndividual ? 'border-red-500' : ''}`}
                {...register("nameOfIndividual")}
                defaultValue={form.nameOfIndividual}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.nameOfIndividual?.message}</span>
            </div>
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Nombre de la Compañia</label>
              <input
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.companyName ? 'border-red-500' : ''}`}
                {...register("companyName")}
                defaultValue={form.companyName}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.companyName?.message}</span>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:space-x-4">
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Correo Electronico</label>
              <input
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.email ? 'border-red-500' : ''}`}
                type="email"
                defaultValue={form.email}
                {...register("email")}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.email?.message}</span>
            </div>
            <div className='grid'>
              <div className="grid">
                <label className='mt-4 font-extrabold'>Telefono</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.phone ? 'border-red-500' : ''}`}
                  type="string"
                  defaultValue={form.phone}
                  {...register("phone")}
                />
              </div>
              <span className='text-red-600 font-semibold h-4'>{errors.phone?.message}</span>
            </div>
          </div>
          <h2 className="text-2xl font-bold my-1">Dirección: </h2>
          <div className="grid grid-cols-1">
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Calle y Número</label>
              <input
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.address?.address ? 'border-red-500' : ''}`}
                {...register("address.address")}
                defaultValue={form.address.address}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.address?.address?.message}</span>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 lg:space-x-2">
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Número Interior</label>
              <input className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.address?.interiorNumber ? 'border-red-500' : ''}`}
                type="number"
                defaultValue={form.address.interiorNumber}
                {...register("address.interiorNumber")}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.address?.interiorNumber?.message}</span>
            </div>
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Código Postal</label>
              <input
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.address?.zipCode ? 'border-red-500' : ''}`}
                type="number"
                maxLength={5}
                minLength={5}
                defaultValue={form.address.zipCode}
                {...register("address.zipCode")}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.address?.zipCode?.message}</span>
            </div>
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Colonia</label>
              <select
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.address?.colonyName ? 'border-red-500' : ''}`}
                {...register('address.colonyName')}
              >
                <option value=""></option>
                {colonies.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              <span className='text-red-600 font-semibold h-4'>{errors.address?.colonyName?.message}</span>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 lg:space-x-2">
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Ciudad</label>
              <input
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.address?.city ? 'border-red-500' : ''}`}
                {...register('address.city', { required: true })}
                defaultValue={cities[0]}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.address?.city?.message}</span>
            </div>
            <div className='grid'>
              <label className='mt-4 font-extrabold'>Estado</label>
              <select
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.address?.state ? 'border-red-500' : ''}`}
                {...register('address.state')}
              >
                <option value=""></option>
                {states.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              <span className='text-red-600 font-semibold h-4'>{errors.address?.state?.message}</span>
            </div>
            <div className='grid'>
              <label className='mt-4 font-extrabold'>País</label>
              <input
                className={`py-1 border-b-2 text-gray-500 focus:outline-none`}
                type="text"
                disabled={true}
                value={form.address.country}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.address?.country?.message}</span>
            </div>
          </div>
          <div className="grid grid-cols-1 my-6">
            <input
              type="submit"
              value="Actualizar"
              className={`bg-primary-inabit-red py-4 text-2xl text-white rounded-xl ${Object.keys(errors).length > 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-inabit-coffe'}`}
            >
            </input>
          </div>
        </div>
      </form>
    </>
  )
}

const withConnect = connect(
  selectState('updateAddress.form', 'updateAddress.updateResponse'),
  actionProps({ ...updateAddressActions }),
);

export default withRouter(withConnect(UpdateAddress));
