import axios from 'axios'
import Swal from 'sweetalert2'
import moment from 'moment-timezone';
import Chip from '@mui/material/Chip';
import { connect } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { shipmentActions } from './reducer';
import { withRouter } from 'react-router-dom';
import Modal from '../../commons/Components/Modal'
import React, { useEffect, useState } from 'react';
import { getChipColor } from '../../utils/guideStatus';
import { getItemInStorage } from '../../utils/functions';
import Facturama from '../../commons/Components/Facturama'
import SupportPayment from '../../commons/Components/SupportPayment'
import { actionProps, selectState } from '../../utils/reduxActions';
import { invoiceActions } from '../../commons/Components/Facturama/reducer';
import { supportPaymentActions } from '../../commons/Components/SupportPayment/reducer';
import { DataGrid, GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SERVER_URL, APPLICATION_KEY, SECRET_KEY } from '../../utils/constants'

interface Guide {
  id: string;
  serviceName: string;
  destination: string;
  trackingNumber: string;
  createdAt: string;
  actions: any;
  guideUrl: string;
  guideUrl6x4?: string;
  transaction: {
    _id: string;
  }
}

interface ShipmentsProps {
  actions?: any;
  controls?: any;
  history?: any;
  list?: Guide[];
  report?: any;
  invoiceControls?: any;
  supportPaymentRes?: any;
  supportPaymentFields?: any;
  profile?: any;
  cancelGuide?: any;
}

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
    </GridToolbarContainer>
  );
}

const Shipments: React.FC<ShipmentsProps> = (props) => {
  const { actions, list, controls, invoiceControls, report, profile, supportPaymentFields } = props;
  let [isOpenModalSupportPayment, setIsOpenModalSupportPayment] = useState(false);
  let [isOpenModalInvoice, setIsOpenModalInvoice] = useState(false);
  let [isOpenModalReport, setIsOpenModalReport] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [reportFromDate, setReportFromDate] = useState<Date | null>(null);
  const [reportToDate, setReportToDate] = useState<Date | null>(null);
  const [emailsReport, setEmailsReport] = useState<String | null>(null);
  const openModal = () => { setIsOpenModalInvoice(true) };
  const openModalSupportPayment = () => { setIsOpenModalSupportPayment(true) };
  const createInvoice = (data: any) => {
    actions.fieldsChanged({
      transactionIds: selectedRows.map((e) => e.transactionId),
      paymentForm: data.paymentForm,
      guides: selectedRows.map((e) => e.guide),
      serviceName: selectedRows.map((e) => e.serviceName)
    })
    openModal();
  }

  const supportPayment = (data: any) => {
    actions.supportPaymentFieldsChanged({
      invoiceId: data.invoice?.Id,
      amount: data.transaction.amount,
      invoice: data.invoice,
    })
    openModalSupportPayment();
  }

  const requestReport = () => {
    actions.getReport({
      filters: {
        dateFrom: reportFromDate,
        dateTo: reportToDate,
        emails: emailsReport?.split(',').map((e) => e.trim())
      }
    })
    setReportFromDate(null)
    setReportToDate(null)
  }

  const handlerCancelInvoice = (data: any) => {
    Swal.fire({
      title: 'Desea cancelar la Factura?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, cancelar!',
      cancelButtonText: 'No, abortar!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const token = getItemInStorage('token');
        Swal.showLoading()
        axios.post(
          `${SERVER_URL}/invoice/cancel`,
          { id: data.transaction._id },
          {
            headers: {
              'application': APPLICATION_KEY,
              'secret': SECRET_KEY,
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          }).then(res => {
            Swal.fire(
              'Factura cancelada!',
              'Tu factura ha sido eliminada correctamente.',
              'success'
            ).then(() => {
              window.location.reload();
            })
          }).catch(err => {
            console.log(err.message)
            Swal.fire(
              'Error!',
              'Se ha producido un error mientras se cancelaba la factura. vuelve a intentarlo mas tarde.',
              'error'
            )
          })
      }
    })
  }

  useEffect(() => { actions.getShipmentList() }, []);

  useEffect(() => {
    if (invoiceControls.success) {
      setIsOpenModalInvoice(false)
      actions.getShipmentList();
    }
  }, [invoiceControls.success, actions]);

  console.log(supportPaymentFields);
  

  useEffect(() => {
    if (supportPaymentFields.success) {
      setIsOpenModalSupportPayment(false)
      actions.getShipmentList();
    }
  }, [supportPaymentFields.success, actions]);

  useEffect(() => {
    if (report.success) {
      Swal.fire(
        'Reporte Solicitado',
        `Tu reporte se esta generando, Revisa el/los correo/s '${emailsReport ? emailsReport?.split(',').map((e) => e.trim()) : profile?.contact.email}' en unos minutos. Si no lo encuentras en tu bandeja de entrada, revisa tu bandeja de spam`,
        'success'
      )
      setIsOpenModalReport(false)
      setEmailsReport(null)
    }
  }, [report, actions]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID. de orden',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold'
    },
    {
      field: 'serviceName',
      headerName: 'Paquetería',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      headerClassName: 'text-lg text-bold',
      valueGetter: ({ value }) => value.replace('EXPRESS', 'Blue bit').replace('FEDEX', 'Purple bit').replace('DHL', 'Yellow bit').replace('INABIT', 'Orange bit').replace('UPS', 'Brown bit'),
    },
    {
      field: 'destination',
      headerName: 'Destinatario',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 220,
      headerClassName: 'text-lg text-bold',
    },
    {
      field: 'trackingNumber',
      headerName: 'No. Rastreo',
      type: 'number',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      headerClassName: 'text-lg text-bold',
    },
    {
      field: 'trackStatus',
      headerName: 'status',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 180,
      headerClassName: 'text-lg text-bold',
      renderCell: (data) => {
        const chip = getChipColor(data.row.internalStatus)
        return (
          data.row.trackStatus && <Tooltip title={data.row.trackStatus} arrow>
            <Chip label={data.row.trackStatus} color={chip.color} variant="outlined" icon={chip.icon} />
          </Tooltip>
        )
      }
    },
    {
      field: 'transaction.invoiced',
      headerName: 'Facturado',
      type: 'boolean',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 120,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.transaction.invoiced
    },
    {
      field: 'transaction.invoiceId',
      headerName: 'ID. Factura',
      type: 'string',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.transaction.invoiceId
    },
    {
      field: 'invoice.PaymentTerms',
      headerName: 'Forma de pago Facturada',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 120,
      headerClassName: 'text-lg text-bold',
      renderCell: (data) => {
        return (
          data.row.invoice?.PaymentTerms && <Tooltip title={data.row.invoice.PaymentTerms} arrow><span>{data.row.transaction.invoiced && data.row.invoice.PaymentTerms}</span></Tooltip>
        )
      }
    },
    {
      field: 'invoice.supportPayment',
      headerName: 'Complemento de Pago',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 120,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.invoice?.supportPayment 

    },
    {
      field: 'createdAt',
      headerName: 'Fecha de creación',
      type: 'date',
      minWidth: 180,
      sortable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
      valueGetter: ({ value }) => value && moment(value).tz('America/Mexico_City').format("DD/MM/YYYY, h:mm:ss a"),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
      sortable: false,
      minWidth: 200,
      renderCell: (data) => {
        return (
          <div className='grid grid-cols-4 grid-flow-col gap-1 grid-flow-row-dense'>
            {
              data.row.upsLabelUrl && data.row.serviceName === 'UPS' &&
              <Tooltip title={'Descargar etiqueta'} arrow>
                <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                <a className="text-center p-4 text-white hover:text-white" href={data.row.upsLabelUrl} target="_blank" rel="noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>
                  </a>
                </div>
              </Tooltip>
            }
            {
              data.row.upsReceiptUrl && data.row.serviceName === 'UPS' &&
              <Tooltip title={'Descargar ticket'} arrow>
                <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                  <a className="text-center p-4 text-white hover:text-white" href={data.row.upsReceiptUrl} target="_blank" rel="noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>
                  </a>
                </div>
              </Tooltip>
            }
            {
              data.row.guideUrl && data.row.serviceName === 'DHL' &&
              <Tooltip title={'Descargar guía'} arrow>
                <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                  <a className="text-center p-4 text-white hover:text-white" href={`data:application/pdf;base64,${data.row.guideUrl}`} target="_blank" rel="noreferrer" download={`Guia-${data.row.trackingNumber}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>
                  </a>
                </div>
              </Tooltip>
            }
            {
              data.row.guideUrl && data.row.serviceName !== 'DHL' &&
              <Tooltip title={'Descargar guía'} arrow>
                <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                  <a className="text-center p-4 text-white hover:text-white" href={data.row.guideUrl} target="_blank" rel="noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>
                  </a>
                </div>
              </Tooltip>
            }
            {
              data.row.acknowledgmentXT && data.row.serviceName === 'EXPRESS' &&
              <Tooltip title={'Regreso Acuse XT'} arrow>
                <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                  <a className="text-center p-4 text-white hover:text-white" href={data.row.guideUrl.replace(data.row.trackingNumber, data.row.acknowledgmentXT)} target="_blank" rel="noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>
                  </a>
                </div>
              </Tooltip>
            }
            {
              data.row.guideUrl6x4 &&
              <Tooltip title={'Descargar guía térmica'} arrow>
                <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                  <a className="text-center p-4 text-white hover:text-white" href={data.row.guideUrl6x4} target="_blank" rel="noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>
                  </a>
                </div>
              </Tooltip>
            }
            {data.row.invoice?.PaymentTerms === '99 - Por definir' && !data.row.invoice?.supportPayment &&
              <Tooltip title={'Complemento de Pago'} arrow>
                <div className=''>
                  <button
                    className={`${!getChipColor(data.row.internalStatus) ? 'bg-primary-inabit-disabled' : 'bg-primary-inabit-yellow hover:bg-primary-inabit-yellow-hover'} text-center p-2 text-white hover:text-white w-full h-full flex justify-center items-center rounded-lg`}
                    onClick={() => supportPayment(data.row)}
                    disabled={!getChipColor(data.row.internalStatus)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                    </svg>

                  </button>
                </div>
              </Tooltip>
            }
            {data.row.transaction.invoiced &&
              <Tooltip title={'Cancelar Factura'} arrow>
                <div className=''>
                  <button
                    className={`${!getChipColor(data.row.internalStatus) ? 'bg-primary-inabit-disabled' : 'bg-red-500 hover:bg-red-600'} text-center p-2 text-white hover:text-white w-full h-full flex justify-center items-center rounded-lg`}
                    onClick={() => handlerCancelInvoice(data.row)}
                    disabled={!getChipColor(data.row.internalStatus)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                    </svg>
                  </button>
                </div>
              </Tooltip>
            }
          </div>
          // <div className="dropdown">
          //   <button className="dropbtn">Actions</button>
          //   <div className="dropdown-content">
          //     <a href="#"><i className="fa fa-folder-open fa-icon"></i>Open</a>
          //     <a href="#"><i className="fa fa-print fa-icon"></i>Print</a>
          //     <a href="#"><i className="fa fa-share-alt fa-icon"></i>Share</a>
          //     <a href="#"><i className="fa fa-save fa-icon"></i>Save as...</a>
          //     <a href="#"><i className="fa fa-trash-alt fa-icon"></i>Delete</a>
          //   </div>
          // </div>
        )
      }
    },
  ];

  return (
    <div className='w-full overflow-auto'>
      <div className='flex flex-row-reverse'>
        <Tooltip title="Tu guía sera generada y enviada al correo registrado">
          <button
            type="button"
            onClick={() => setIsOpenModalReport(true)}
            className="inline-flex float-right items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-lg text-white bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover transition ease-in-out duration-150 mb-4">
            Solicitar reporte
          </button>
        </Tooltip>
        {selectedRows.length > 0 && profile.canGenerateInvoices &&
          <button
            type="button"
            onClick={() => createInvoice({})}
            className="inline-flex float-right items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-lg text-white bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover transition ease-in-out duration-150 mb-4 mr-4">
            {report.loading && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>}
            {report.loading ? "Procesando..." : 'Generar Facturas'}
          </button>
        }
      </div>
      {/* Invoice Modal */}
      <Modal
        onClose={() => setIsOpenModalInvoice(false)}
        isOpen={isOpenModalInvoice}
      >
        <Facturama />
      </Modal>
      {/* Support Payment Modal */}
      <Modal
        onClose={() => setIsOpenModalSupportPayment(false)}
        isOpen={isOpenModalSupportPayment}
      >
        <SupportPayment />
      </Modal>
      {/* report filter modal */}
      <Modal
        onClose={() => setIsOpenModalReport(false)}
        isOpen={(isOpenModalReport)}
        size='tiny'
      >
        <h1 className='text-xl'>Filtrar reporte</h1>
        <hr className='my-6'></hr>
        <h4 className='my-2'>Desde</h4>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            className='w-full'
            minDate={new Date('2021-01-01')}
            onChange={(newValue) => setReportFromDate(newValue)}
          />
        </LocalizationProvider>
        <h4 className='my-2'>Hasta</h4>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            className='w-full'
            maxDate={new Date()}
            referenceDate={new Date()}
            onChange={(newValue) => setReportToDate(newValue)}
          />
        </LocalizationProvider>
        <hr className='my-4'></hr>
        <h2>Cambiar email por defecto</h2>
        <input
          className={`w-full border-2 py-4 px-4 mt-2 text-sm rounded-lg`}
          placeholder='Correos electrónicos separados por comas'
          type='text'
          onChange={(e) => setEmailsReport(e.target.value)}
        />
        <span className='text-gray-400 text-sm'> (user1@gmail.com, user2@gmail.com)</span>
        <hr className='my-4'></hr>
        <button
          type="button"
          onClick={() => requestReport()}
          className="inline-flex float-center items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-lg text-white bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover transition ease-in-out duration-150 mb-4">
          {report.loading && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>}
          {report.loading ? "Procesando..." : 'Descargar reporte'}
        </button>
      </Modal>
      <DataGrid
        autoHeight
        localeText={{
          toolbarDensity: 'Tamaño de Columnas',
          toolbarDensityLabel: 'Size',
          toolbarDensityCompact: 'Pequeño',
          toolbarDensityStandard: 'Medio',
          toolbarDensityComfortable: 'Grande',
          toolbarExport: 'Exportar',
          toolbarFilters: 'Filtros',
          toolbarColumns: 'Columnas',
        }}
        rows={list || []}
        columns={columns}
        pageSize={12}
        loading={controls.loading}
        className="rounded-xl"
        components={{ Toolbar: CustomToolbar, }}
        density='comfortable'
        checkboxSelection={profile.canGenerateInvoices}
        isRowSelectable={(params) => !params.row.transaction.invoiced}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = list?.filter((row) => selectedIDs.has(row.id));
          setSelectedRows(selectedRows?.map((e) => ({ transactionId: e.transaction._id, guide: e.trackingNumber, serviceName: e.serviceName })) || []);
        }
        }
      />
    </div>
  )
}

const withConnect = connect(
  selectState(
    'app.profile',
    'shipmentList.controls',
    'shipmentList.list',
    'shipmentList.report',
    'invoice.invoiceControls',
    'supportPayment.supportPaymentFields',
    'supportPayment.supportPaymentRes'
  ),
  actionProps({ ...shipmentActions, ...invoiceActions, ...supportPaymentActions }),
);

export default withRouter(withConnect(Shipments));
