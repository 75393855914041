import React from 'react'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { PAYPAL_CLIENT_ID, MIN_AMOUNT_TO_RECHARGE } from '../../../utils/constants'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionProps, selectState } from '../../../utils/reduxActions';
import { rechargeActions} from './reducer';
import paths from '../../../config/paths'

const MySwal = withReactContent(Swal)

interface PaypalProps {
  children?: any
  paypalControls?: any
  paypalResult?: any
  actions?: any
  history?: any
  rechargeCreditsControls?: any
}

const Paypal: React.FC<PaypalProps> = (props) => {
  const { rechargeCreditsControls, history, actions } = props
  const createPaypalOrder = (data: any, actions: any) => {
    return actions.order
      .create({
        intent: "CAPTURE",
        purchase_units: [
          {
            description: 'Inabit recharge',
            amount: { value: rechargeCreditsControls.amount }
          }
        ],
      })
      .then((orderID: any) => {
        return orderID;
      });
  }

  const handlerOnApprove = async (data: any, paypalAction: any) => {
    try {
      await paypalAction.order.capture()
      actions.controlsChanged({ payload: data })
      actions.saveRechargeCredits({ payload: data, amount: rechargeCreditsControls.amount, paymentMethod: 'PAYPAL' })
      MySwal.fire({
        icon: 'success',
        title: `Recarga exitosa`,
        text: 'Tu recarga ha sido exitosa',
      }).then(() => {
        history.push(paths.DASHBOARD)
      })
    } catch (err) {
      MySwal.fire({
        icon: 'error',
        title: `Oops!`,
        text: 'Lo sentimos estamos teniendo problemas para procesar tu recarga, por favor intenta más tarde!',
      })
    }
  }

  return (
    <div>
      <PayPalScriptProvider options={{ 'client-id': PAYPAL_CLIENT_ID, currency: 'MXN' }}>
        <PayPalButtons
          className='w-full lg:w-144 m-auto'
          createOrder={createPaypalOrder}
          onApprove={handlerOnApprove}
          fundingSource={'paypal'}
          disabled={rechargeCreditsControls.amount <= MIN_AMOUNT_TO_RECHARGE}
          forceReRender={[rechargeCreditsControls.amount]}
        />
      </PayPalScriptProvider>
    </div>
  )
}

const withConnect = connect(
  selectState(
    'paypalRecharge.controls',
    'rechargeCredits.rechargeCreditsControls'
  ),
  actionProps({ ...rechargeActions }),
);

export default withRouter(withConnect(Paypal));