import React from "react"
import { MIN_AMOUNT_TO_RECHARGE } from "../../../utils/constants"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actionProps, selectState } from "../../../utils/reduxActions";
import { rechargeCreditsActions } from '../reducer';
import { speiActions } from "./reducer";
import paths from "../../../config/paths"
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import './style.css';

import {
  Dimmer,
  Loader,
  Button,
  Modal
} from 'semantic-ui-react';

import SpeiBrand from "../../../assets/img/spei_brand.png"

interface PaypalProps {
  children?: any
  controls?: any
  paypalResult?: any
  actions?: any
  history?: any
  rechargeCreditsControls?: any
  profile?: any
  res?: any
}

const Spei: React.FC<PaypalProps> = (props) => {
  const [open, setOpen] = React.useState(true)
  const { rechargeCreditsControls, history, actions, profile, controls, res } = props
  const handleClick = () => {
    actions.generateClabe({ profile, amount: rechargeCreditsControls.amount })
  }

  const printDocument = () => {
    const input = document.getElementById('spei-digital-card');
    html2canvas(input as HTMLElement)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        // @ts-ignore: 
        pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save("spei.pdf");
      })
  }

  const closeModal = () => {
    printDocument()
    setOpen(false)
    history.push(paths.DASHBOARD)
  }

  return (
    <>
      <Dimmer active={controls.loading} inverted>
        <Loader inverted>Generando CLABE....</Loader>
      </Dimmer>
      <div className='grid grid-cols-1'>
        {
          !res
            ?
            <div className="grid">
              <div className="grid">
                <div className="grid justify-items-center">
                  <button
                    onClick={handleClick}
                    disabled={rechargeCreditsControls.amount <= MIN_AMOUNT_TO_RECHARGE}
                    className={`px-24 lg:px-52 my-4 rounded-xl text-2xl py-4 text-white bg-primary-inabit-red ${rechargeCreditsControls.amount <= MIN_AMOUNT_TO_RECHARGE ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-inabit-purple'}`}
                  >
                    Generar <strong>clabe</strong>
                  </button>
                </div>
                <div className="grid">
                  <span>Solamente podrá pagar la cantidad establecida en la petición de pago vía SPEI.<br /> Al momento de que se reciba el pago de manera exitosa sus créditos seran actualizados automaticamente.</span>
                </div>
              </div>
            </div>
            :
            <Modal
              size="tiny"
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={open}
            >
              <Modal.Header>FICHA DIGITAL</Modal.Header>
              <Modal.Content>
                <div className="grid my-4">
                  <div className="grid justify-items-center">
                    <div className="ps" id="spei-digital-card">
                      <div className="ps-header">
                        <div className="ps-reminder">Ficha digital. No es necesario imprimir.</div>
                        <div className="ps-info">
                          <div className="ps-brand"><img src={SpeiBrand} alt="spei brand" /></div>
                          <div className="ps-amount">
                            <h3>Monto a pagar</h3>
                            <h2 className="text-4xl">$ {rechargeCreditsControls.amount}<sup>MXN</sup></h2>
                            <p>Utiliza exactamente esta cantidad al realizar el pago.</p>
                          </div>
                        </div>
                        <div className="ps-reference">
                          <h3>CLABE</h3>
                          <h1 className="card-title">{res.order.cable}</h1>
                        </div>
                      </div>
                      <div className="ps-instructions">
                        <h3>Instrucciones</h3>
                        <ol>
                          <li>Accede a tu banca en línea.</li>
                          <li>Da de alta la CLABE en esta ficha. <strong>El banco deberá de ser STP</strong>.</li>
                          <li>Realiza la transferencia correspondiente por la cantidad exacta en esta ficha, <strong>de lo contrario se rechazará el cargo</strong>.</li>
                          <li>Al confirmar tu pago, el portal de tu banco generará un comprobante digital. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
                        </ol>
                        <div className="ps-footnote">Al completar estos pasos tus créditos seran actualizas automaticamente</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div><strong>Nota: </strong>Asegure la cable para poder realizar la transferencia</div>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content="Descargar y cerrar"
                  labelPosition='right'
                  icon='checkmark'
                  onClick={closeModal}
                  positive
                />
              </Modal.Actions>
            </Modal>
        }
      </div>
    </>
  )
}

const withConnect = connect(
  selectState(
    'speiRecharge.controls',
    'speiRecharge.res',
    'rechargeCredits.rechargeCreditsControls',
    'app.profile'
  ),
  actionProps({ ...speiActions, ...rechargeCreditsActions }),
);

export default withRouter(withConnect(Spei));