import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../../commons/reducer';

import { get, destroy } from '../../../utils/api';
import { bookAddressActions, BOOK_ADDRESS } from './reducer';

function* bookAddress() {
  yield takeLatest(BOOK_ADDRESS.GET_ADDRESSES, function* fn(action: any): any {
    try {
      const response = yield get('/addresses-book');
      if (response.error) {
        yield put(bookAddressActions.getAddressesFailed({ response: response.errorMsg }));
      } else {
        yield put(bookAddressActions.getAddressesSuccess(response));
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  }
  );
}

function* deleteAddress() {
  yield takeLatest(BOOK_ADDRESS.DELETE_ADDRESS, function* fn(action: any): any {
    const { controls } = action
    try {
      const response = yield destroy('/addresses-book', controls.id);
      if (response.error) {
        yield put(bookAddressActions.deleteAddressFailed({ response: response.errorMsg }));
      } else {
        yield put(bookAddressActions.deleteAddressSuccess(response));
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  }
  );
}
export default function* saga(): any {
  yield spawn(bookAddress);
  yield spawn(deleteAddress);
}