import { APP } from '../../../commons/reducer';
import { createAction, createReducer } from '../../../utils/reducer';

// Local constants
const PATH = 'spei_recharge/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const SPEI = {
  GENERATE_CLABE: `${PATH}GENERATE_CLABE`,
  GENERATE_CLABE_SUCCESS: `${PATH}GENERATE_CLABE_SUCCESS`,
  GENERATE_CLABE_FAILED: `${PATH}GENERATE_CLABE_FAILED`,
};

// actions
export const speiActions = {
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  generateClabe: createAction(SPEI.GENERATE_CLABE, 'controls'),
  generateClabeSucess: createAction(SPEI.GENERATE_CLABE_SUCCESS, 'res'),
  generateClabeFailed: createAction(SPEI.GENERATE_CLABE_FAILED, 'res'),
};

const initialState = {
  controls: {
    payload: {},
    errorMessage: '',
    error: false,
    loading: false,
  },
  res: null
};

const SpeiRecharge = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [SPEI.GENERATE_CLABE](state: any) {
    state.controls.loading = true;
  },
  [SPEI.GENERATE_CLABE_SUCCESS](state: any, action: any) {
    state.res = action.res;
    state.controls.loading = false;
  },
  [SPEI.GENERATE_CLABE_FAILED](state: any) {
    state.controls.error = true;
    state.controls.loading = false;
    state.controls.errorMessage = 'Oops, wrong credentials.';
  },
  [APP.SERVER_FAILED](state: any) {
    state.controls.loading = false;
  },
});

export default SpeiRecharge;