import Swal from 'sweetalert2';
import { get, post } from '../../utils/api';
import { appActions } from '../../commons/reducer';
import { takeLatest, spawn, put } from 'redux-saga/effects';
import { INVOICES, invoicesActions } from './reducer';

function* invoicesList() {
  yield takeLatest(INVOICES.GET_INVOICES_LIST, function* fn(action: any): any {
    try {
      const response = yield get('/invoices');
      if (response.error) {
        yield put(
          invoicesActions.getInvoicesListFailed({
            response: response.errorMsg,
          }),
        );
      } else {
        yield put(invoicesActions.getInvoicesListSuccess(response.data));
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });
}

export default function* saga(): any {
  yield spawn(invoicesList);
}
