import { APP } from '../../../commons/reducer';
import { createAction, createReducer } from '../../../utils/reducer';
// Local constants
const PATH = 'createAddress/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const CREATE_ADDRESS = {
  CREATE_ADDRESS: `${PATH}CREATE_ADDRESS`,
  CREATE_ADDRESS_SUCCESS: `${PATH}CREATE_ADDRESS_SUCCESS`,
  CREATE_ADDRESS_FAILED: `${PATH}CREATE_ADDRESS_FAILED`,
};

// actions
export const createAddressActions = {
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  createAddress: createAction(CREATE_ADDRESS.CREATE_ADDRESS, 'controls'),
  createAddressSuccess: createAction(CREATE_ADDRESS.CREATE_ADDRESS_SUCCESS, 'result'),
  createAddressFailed: createAction(CREATE_ADDRESS.CREATE_ADDRESS_FAILED, 'result'),
};

const initialState = {
  controls: {
    addressType: null,
    nameOfIndividual: null,
    email: null,
    phone: null,
    companyName: null,
    address: null,
    interiorNumber: null,
    zipCode: null,
    colonyName: null,
    city: null,
    state: null,
    country: 'México',
    reference: null,
    errorMessage: null,
    error: false,
    loading: false,
  },
  result: {}
};

const createAddress = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [CREATE_ADDRESS.CREATE_ADDRESS](state: any) {
    state.controls.loading = true;
  },
  [CREATE_ADDRESS.CREATE_ADDRESS_SUCCESS](state: any, action: any) {
    state.controls.addressType = null;
    state.controls.nameOfIndividual = null;
    state.controls.email = null;
    state.controls.phone = null;
    state.controls.companyName = null;
    state.controls.address = null;
    state.controls.interiorNumber = null;
    state.controls.zipCode = null;
    state.controls.colonyName = null;
    state.controls.city = null;
    state.controls.state = null;
    state.controls.country = 'México';
    state.controls.reference = null;
    state.controls.errorMessage = null;
    state.controls.error = false;
    state.controls.loading = false;
    state.result = action.result
  },
  [CREATE_ADDRESS.CREATE_ADDRESS_FAILED](state: any) {
    state.controls.error = true;
    state.controls.loading = false;
    state.controls.errorMessage = 'Oops, wrong credentials.';
  },
  [APP.SERVER_FAILED](state: any) {
    state.controls.loading = false;
  },
});

export default createAddress;