import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../../commons/reducer';
import { post } from '../../../utils/api';
import { createAddressActions, CREATE_ADDRESS } from './reducer';
import paths from '../../../config/paths'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

function* createAddress() {
  yield takeLatest(CREATE_ADDRESS.CREATE_ADDRESS, function* fn(action: any): any {
    const { controls } = action
    const history = controls.history;
    delete controls.history
    const body = { ...controls }
    try {
      const response = yield post('/addresses-book', body);
      if (response.error) {
        yield put(createAddressActions.createAddressFailed({ response: response.errorMsg }));
        MySwal.fire({
          icon: 'error',
          title: `Ooops, tenemos problemas`,
          text: response.errorMsg,
        })
      } else {
        yield put(createAddressActions.createAddressSuccess(response));
        history.push(paths.ADDRESS_BOOK);
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  }
  );
}
export default function* saga(): any {
  yield spawn(createAddress);
}