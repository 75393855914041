import React from 'react';

interface WaitProps {
  actions?: any;
  controls?: any;
  history?: any;
}

const Wait: React.FC<WaitProps> = (props) => {
  return (
    <div className="rounded-xl shadow-xl p-6 text-center">
      <h2 className="font-bold text-2xl">¡Tu cuenta está siendo verificada!</h2>
      <div className="mt-6 text-lg">
        <span >
          Estamos validando la información de tu negocio y asignando a <br />
          uno de nuestros asesores para que puedan apoyarte con las <br />
          mejores opciones para tu negocio. Este proceso puede demorar <br />
          algún tiempo, tu asesor se pondrá en contacto contigo.
        </span>
      </div>
      <img className="m-auto w-96 mt-20" alt="waiting" src="https://thumbs.gfycat.com/QuarterlyAdventurousGyrfalcon-max-1mb.gif" />
      <div className="mt-20 text-lg mb-40">
        <span className="font-bold">
          ¿Es urgente realizar tus envios? <a className="text-blue-600" href="https://wa.link/drqq03" target="_blank" rel="noreferrer">Verificar mi cuenta ahora</a>
        </span>
      </div>
    </div>
  );
};

export default Wait;