/* eslint-disable no-param-reassign */

import { APP } from '../../reducer';
import { createAction, createReducer } from '../../../utils/reducer';
// Local constants
const PATH = 'invoice/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const INVOICE = {
  GET_LIST_OF_CFDI: `${PATH}GET_LIST_OF_CFDI`,
  GET_LIST_OF_CFDI_SUCCESS: `${PATH}GET_CFDI_SUCCESS`,
  GET_LIST_OF_CFDI_FAILED: `${PATH}GET_CFDI_FAILED`,
  CREATE_INVOICE: `${PATH}CREATE_INVOICE`,
  CREATE_NEW_INVOICE: `${PATH}CREATE_NEW_INVOICE`,
  CREATE_INVOICE_SUCCESS: `${PATH}CREATE_INVOICE_SUCCESS`,
  CREATE_INVOICE_FAILED: `${PATH}CREATE_INVOICE_FAILED`,
  GET_LIST_OF_REGIMEN_FISCAL: `${PATH}GET_LIST_OF_REGIMEN_FISCAL`,
  GET_LIST_OF_REGIMEN_FISCAL_SUCCESS: `${PATH}GET_LIST_OF_REGIMEN_FISCAL_SUCCESS`,
  GET_LIST_OF_REGIMEN_FISCAL_FAILED: `${PATH}GET_LIST_OF_REGIMEN_FISCAL_FAILED`,
  GET_PAYMENT_FORMS: `${PATH}GET_PAYMENT_FORMS`,
  GET_PAYMENT_FORMS_SUCCESS: `${PATH}GET_PAYMENT_FORMS_SUCCESS`,
  GET_PAYMENT_FORMS_FAILED: `${PATH}GET_PAYMENT_FORMS_FAILED`,
};

// actions
export const invoiceActions = {
  fieldsChanged: createAction(CONTROLS_CHANGED, 'invoiceControls'),
  getListOfCfdi: createAction(INVOICE.GET_LIST_OF_CFDI),
  getListOfCfdiSuccess: createAction(INVOICE.GET_LIST_OF_CFDI_SUCCESS, 'listOfCfdi'),
  getListOfCfdiFailed: createAction(INVOICE.GET_LIST_OF_CFDI_FAILED, 'listOfCfdi'),
  createInvoice: createAction(INVOICE.CREATE_INVOICE, 'invoiceControls'),
  createNewInvoice: createAction(INVOICE.CREATE_NEW_INVOICE, 'invoiceControls'),
  createInvoiceSuccess: createAction(INVOICE.CREATE_INVOICE_SUCCESS, 'createInvoiceRes'),
  createInvoiceFailed: createAction(INVOICE.CREATE_INVOICE_FAILED, 'createInvoiceRes'),
  getListOfRegimeFiscal: createAction(INVOICE.GET_LIST_OF_REGIMEN_FISCAL),
  getListOfRegimeFiscalSuccess: createAction(INVOICE.GET_LIST_OF_REGIMEN_FISCAL_SUCCESS, 'listOfRegimeFiscal'),
  getListOfRegimeFiscalFailed: createAction(INVOICE.GET_LIST_OF_REGIMEN_FISCAL_FAILED, 'listOfRegimeFiscal'),
  getPaymentForms: createAction(INVOICE.GET_PAYMENT_FORMS),
  getPaymentFormsSuccess: createAction(INVOICE.GET_PAYMENT_FORMS_SUCCESS, 'paymentForms'),
  getPaymentFormsFailed: createAction(INVOICE.GET_PAYMENT_FORMS_FAILED, 'paymentForms'),
}

const initialState = {
  invoiceControls: {
    paymentForm: '',
    receiver: {
      rfc: '',
      name: '',
      cfdiUse: '',
    },
    transactionId: '',
    errorMessage: '',
    error: false,
    loading: false,
    success: null,
  },
  listOfCfdi: [],
  listOfRegimeFiscal: [],
  paymentForms: [],
  createInvoiceRes: {},
};

const invoice = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.invoiceControls = {
      ...state.invoiceControls,
      ...action.invoiceControls,
    };
  },
  [INVOICE.GET_LIST_OF_CFDI](state: any) {
    state.invoiceControls.loading = false;
  },
  [INVOICE.GET_LIST_OF_CFDI_SUCCESS](state: any, action: any) {
    state.listOfCfdi = action.listOfCfdi.data;
    state.invoiceControls.loading = false;
  },
  [INVOICE.GET_LIST_OF_CFDI_FAILED](state: any) {
    state.invoiceControls.error = true;
    state.invoiceControls.loading = false;
    state.invoiceControls.errorMessage = 'Oops, wrong credentials.';
  },
  [INVOICE.GET_LIST_OF_REGIMEN_FISCAL](state: any) {
    state.invoiceControls.loading = false;
  },
  [INVOICE.GET_LIST_OF_REGIMEN_FISCAL_SUCCESS](state: any, action: any) {
    state.listOfRegimeFiscal = action.listOfRegimeFiscal.data;
    state.invoiceControls.loading = false;
  },
  [INVOICE.GET_LIST_OF_REGIMEN_FISCAL_FAILED](state: any) {
    state.invoiceControls.error = true;
    state.invoiceControls.loading = false;
  },
  [INVOICE.GET_PAYMENT_FORMS](state: any) {
    state.invoiceControls.loading = false;
  },
  [INVOICE.GET_PAYMENT_FORMS_SUCCESS](state: any, action: any) {
    state.paymentForms = action.paymentForms.data;
    state.invoiceControls.loading = false;
  },
  [INVOICE.GET_PAYMENT_FORMS_FAILED](state: any) {
    state.invoiceControls.error = true;
    state.invoiceControls.loading = false;
  },
  [APP.SERVER_FAILED](state: any) {
    state.invoiceControls.loading = false;
  },
  [INVOICE.CREATE_INVOICE](state: any) {
    state.invoiceControls.loading = true;
  },
  [INVOICE.CREATE_NEW_INVOICE](state: any) {
    state.invoiceControls.loading = true;
  },
  [INVOICE.CREATE_INVOICE_SUCCESS](state: any) {
    state.invoiceControls.loading = false;
    state.invoiceControls.success = true;
    state.invoiceControls.paymentForm = null;
    state.invoiceControls.receiver.rfc = null;
    state.invoiceControls.receiver.name = null;
    state.invoiceControls.receiver.cfdiUse = null;
    state.invoiceControls.transactionId = null;
    state.invoiceControls.error = false;
  },
  [INVOICE.CREATE_INVOICE_FAILED](state: any) {
    state.invoiceControls.error = true;
    state.invoiceControls.loading = false;
    state.invoiceControls.errorMessage = 'Ocurrio un error al crear la factura';
  },
});

export default invoice;