import { APP } from '../../commons/reducer';
import { createAction, createReducer } from '../../utils/reducer';
import { DEFAULT_PAYMENT_METHOD } from '../../utils/constants'

// Local constants
const PATH = 'rechargeCredits/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const RECHARGE_CREDITS = {
  RECHARGE: `${PATH}RECHARGE`,
  RECHARGE_SUCCESS: `${PATH}RECHARGE_SUCCESS`,
  RECHARGE_FAILED: `${PATH}RECHARGE_FAILED`,
};

// actions
export const rechargeCreditsActions = {
  controlsChanged: createAction(CONTROLS_CHANGED, 'rechargeCreditsControls'),
  rechargeCredit: createAction(RECHARGE_CREDITS.RECHARGE, 'rechargeCreditsControls'),
  rechargeCreditsSuccess: createAction(RECHARGE_CREDITS.RECHARGE_SUCCESS, 'rechargeResult'),
  rechargeCreditsFailed: createAction(RECHARGE_CREDITS.RECHARGE_FAILED, 'rechargeResult'),
};

const initialState = {
  rechargeCreditsControls: {
    paymentMethod: DEFAULT_PAYMENT_METHOD,
    amount: 0,
    cardholderName: '',
    cardholderLastName: '',
    errorMessage: '',
    error: false,
    loading: false,
  },
  rechargeResult: null
};

const rechargeCredits = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.rechargeCreditsControls = {
      ...state.rechargeCreditsControls,
      ...action.rechargeCreditsControls,
    };
  },
  [RECHARGE_CREDITS.RECHARGE](state: any) {
    state.rechargeCreditsControls.loading = true;
  },
  [RECHARGE_CREDITS.RECHARGE_SUCCESS](state: any, action: any) {
    state.rechargeResult = action.rechargeResult;
    state.rechargeCreditsControls.loading = false;
    state.rechargeCreditsControls.name = '';
  },
  [RECHARGE_CREDITS.RECHARGE_FAILED](state: any) {
    // state.rechargeCreditsControls.error = true;
    // state.rechargeCreditsControls.loading = false;
    // state.rechargeCreditsControls.errorMessage = 'Oops, wrong credentials.';
  },
  [APP.SERVER_FAILED](state: any) {
    state.rechargeCreditsControls.loading = false;
  },
});

export default rechargeCredits;