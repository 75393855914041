/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Route,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { actionProps, selectState } from './utils/reduxActions';
import 'semantic-ui-css/semantic.min.css'
// views
import Login from './views/Login';
import Calculator from './views/calculator';
import CreateAddress from './views/Address/CreateAddress';
import GenerateShipment from './views/GenerateShipment';
import AddressBook from './views/Address/AddressBook';
import Register from './views/Register';
import Home from './views/Home';
import Dashboard from './views/Dashboard';
import Shipments from './views/Shipments';
import RechargeCredits from './views/RechargeCredits';
import TransactionHistory from './views/TransactionHistory';
import CompleteSignUp from './views/CompleteSignUp';
import Shop from './views/Shop';
import Wait from './views/Wait';

import { appActions } from './commons/reducer';
import AuthRoute from './utils/authRouter';
import Paths from './config/paths';
import Invoices from './views/Invoices';
import WooCommerce from './views/WooCommerce';


interface AppProps {
  appControls?: any;
  actions?: any;
}

const App: React.FC<AppProps & RouteComponentProps> = (props): JSX.Element => {
  return (
    <>
      <Route exact path={Paths.LOGIN} component={Login} />
      <Route exact path={Paths.SIGN_UP} component={Register} />
      <Route exact path={Paths.HOME} component={Home} />
      <AuthRoute
        exact
        path={Paths.DASHBOARD}
        Component={Dashboard}
        roles={[]}
      />
      <AuthRoute roles={[]} exact path={Paths.COMPLETE_SIGN_UP} Component={CompleteSignUp} />
      <AuthRoute roles={[]} exact path={Paths.WAITING_FOR_REVIEW} Component={Wait} />
      <AuthRoute roles={[]} exact path={Paths.ADDRESS_BOOK_CREATE} Component={CreateAddress} />
      <AuthRoute roles={[]} exact path={Paths.ADDRESS_BOOK} Component={AddressBook} />
      <AuthRoute roles={[]} exact path={Paths.CREATE_ORDER} Component={GenerateShipment} />
      <AuthRoute roles={[]} exact path={Paths.QUOTE} Component={Calculator} />
      <AuthRoute roles={[]} exact path={Paths.SHIPMENTS} Component={Shipments} />
      <AuthRoute roles={[]} exact path={Paths.RECHARGE_CREDITS} Component={RechargeCredits} />
      <AuthRoute roles={[]} exact path={Paths.SHOP} Component={Shop} />
      <AuthRoute roles={[]} exact path={Paths.INVOICES} Component={Invoices} />
      <AuthRoute roles={[]} exact path={Paths.WOOCOMMERCE} Component={WooCommerce} />
      {/* <AuthRoute roles={[]} exact path={Paths.TRANSACTION_HISTORY} Component={TransactionHistory} /> */}
    </>
  );
};

const withConnect = connect(
  selectState(
    'app.appControls',
  ),
  actionProps({ ...appActions }),
);

export default withRouter(withConnect(App));
