import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';
import { get, post } from '../../utils/api';
import { SHOP, shopActions } from './reducer';
import Swal from 'sweetalert2'

function* shop() {
  yield takeLatest(SHOP.GET_SHOP_DATA, function* fn(): any {
    try {
      const response = yield get('/shop/orders');
      if (response) {
        if (response.error) {
          const data = JSON.parse(response.errorMsg);
          if (data.error.status === 401) {
            localStorage.removeItem('token');
          }
        }
        yield put(shopActions.getShopDataSuccess(response.data));
      } else {
        yield put(shopActions.getShopDataFailed({ response: response.errorMsg }));
      }
    } catch {
      console.log('error en la request')
      // yield put(shopActions.('Oops, server failed'));
    }
  });
}

function* createShop() {
  yield takeLatest(SHOP.CREATE_SHOP, function* fn(actions: any): any {
    try {
      const response = yield post('/shop', { accessToken: actions.createShop.input.accessToken, domain: actions.createShop.input.domain });
      if (response) {
        if (response.error) {
          const data = JSON.parse(response.errorMsg);
          if (data.error.status === 401) {
            localStorage.removeItem('token');
          }
          if (data.error.status === 422) {
            const errorList = data.error.details.map((e: any) => `<li>${e.message}</li>`).join('');
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `<ul>${errorList}</ul>`
            })
          }
          if (data.error.status === 424) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: data.error.message
            })
          }
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Tienda creada correctamente',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            window.location.reload();
          })
          yield put(shopActions.createShopSuccess(response.data));
        }
      } else {
        yield put(shopActions.createShopFailed({ response: response.errorMsg }));
      }
    } catch {
      console.log('error en la request')
      // yield put(shopActions.('Oops, server failed'));
    }
  });
}

export default function* saga(): any {
  yield spawn(shop);
  yield spawn(createShop);
}
