import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import Calculator from './publicCalculator/calculator'

function MultiModal() {
  const [open, setOpen] = React.useState(false)

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button id='btnModal'>Cotizar</Button>}
    >
      <Modal.Content>
        <Button floated='right' onClick={() => setOpen(false)} icon='close' color="red" />
        <Calculator />
      </Modal.Content>
    </Modal>
  )
}

export default MultiModal
