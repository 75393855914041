import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../commons/reducer';
import { post } from '../../utils/api';
import { registerActions, REGISTER } from './reducer';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

function* auth() {
  yield takeLatest(REGISTER.REGISTER, function* fn(action: any): any {
    const body = {
      name: action.controls.name,
      password: action.controls.password,
      contact: action.controls.contact,
      company: {
        name: action.controls.company.name,
        approxshipmentsPerMonth: action.controls.company.approxshipmentsPerMonth,
        // businessType: action.controls.businessType,
        preferredShippingCompany: "fedEx",
        activity: action.controls.company.activity
      },
      ReCaptchaToken: action.controls.ReCaptchaToken
    };
    try {
      const response = yield post('/signUp', body);
      if (response.error) {
        yield put(registerActions.registerFailed({ response: response.errorMsg }));
        const msgError = JSON.parse(response.errorMsg).error.message.split(':')[1]
        MySwal.fire({
          icon: 'error',
          title: `Ooops, tenemos problemas`,
          text: msgError,
        })
      } else {
        localStorage.setItem('token', JSON.stringify(response.token));
        yield put(registerActions.registerSuccess(response));
        action.controls.history.push('/dashboard');
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });
}
export default function* saga(): any {
  yield spawn(auth);
}