import { APP } from '../../commons/reducer';
import { createAction, createReducer } from '../../utils/reducer';
const PATH = 'login/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const LOGIN = {
  AUTH: `${PATH}AUTH`,
  AUTH_SUCCESS: `${PATH}AUTH_SUCCESS`,
  AUTH_FAILED: `${PATH}AUTH_FAILED`,
};

// actions
export const loginActions = {
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  // auth login
  auth: createAction(LOGIN.AUTH, 'controls'),
  authSuccess: createAction(LOGIN.AUTH_SUCCESS, 'result'),
  authFailed: createAction(LOGIN.AUTH_FAILED, 'result'),
};

const initialState = {
  controls: {
    email: '',
    password: '',
    emailError: '',
    passwordError: '',
    userLogged: !!localStorage.getItem('token'),
    errorMessage: '',
    error: false,
    loading: false,
  },
};

const login = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [LOGIN.AUTH](state: any) {
    state.controls.loading = true;
  },
  [LOGIN.AUTH_SUCCESS](state: any) {
    state.controls.userLogged = true;
    state.controls.loading = false;
    state.controls.email = '';
    state.controls.password = '';
  },
  [LOGIN.AUTH_FAILED](state: any) {
    state.controls.error = true;
    state.controls.loading = false;
    state.controls.errorMessage = 'Usuario o contraseña incorrecto';
  },
  [APP.SERVER_FAILED](state: any) {
    state.controls.loading = false;
  },
});

export default login;