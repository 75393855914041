import axios from 'axios';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { invoicesActions } from './reducer';
import { withRouter } from 'react-router-dom';
import Modal from '../../commons/Components/Modal';
import React, { useEffect, useState } from 'react';
import { getChipColor } from '../../utils/guideStatus';
import { formatDate, getItemInStorage } from '../../utils/functions';
import Facturama from '../../commons/Components/Facturama';
import { actionProps, selectState } from '../../utils/reduxActions';
import { invoiceActions } from '../../commons/Components/Facturama/reducer';
import { DataGrid, GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import { SERVER_URL, APPLICATION_KEY, SECRET_KEY } from '../../utils/constants';

interface Guide {
  id?: string;
  _id?: string;
  serviceName: string;
  destination: string;
  trackingNumber: string;
  createdAt: string;
  actions: any;
  guideUrl: string;
  guideUrl6x4?: string;
  transaction?: {
    _id: string;
  };
}

interface InvoicesProps {
  actions?: any;
  controls?: any;
  history?: any;
  list?: Guide[];
  report?: any;
  invoiceControls?: any;
  profile?: any;
}

const CustomToolbar = () => {
  return <GridToolbarContainer></GridToolbarContainer>;
};

const Invoices: React.FC<InvoicesProps> = props => {
  const { actions, list, controls, invoiceControls } = props;
  let [isOpenModalInvoice, setIsOpenModalInvoice] = useState(false);
  const openModal = () => {
    setIsOpenModalInvoice(true);
  };
  const createInvoice = (data: any) => {
    actions.fieldsChanged({
      transactionIds: [],
      paymentForm: '',
      guides: [],
      serviceName: [],
    });
    openModal();
  };

  const handlerCancelInvoice = (data: any) => {
    Swal.fire({
      title: 'Desea cancelar la Factura?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, cancelar!',
      cancelButtonText: 'No, abortar!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const token = getItemInStorage('token');
        Swal.showLoading();
        axios
          .post(
            `${SERVER_URL}/invoice/cancel`,
            { id: data?.transaction?._id || data?.Id },
            {
              headers: {
                application: APPLICATION_KEY,
                secret: SECRET_KEY,
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            },
          )
          .then(res => {
            Swal.fire(
              'Factura cancelada!',
              'Tu factura ha sido eliminada correctamente.',
              'success',
            ).then(() => {
              window.location.reload();
            });
          })
          .catch(err => {
            console.log(err.message);
            Swal.fire(
              'Error!',
              'Se ha producido un error mientras se cancelaba la factura. vuelve a intentarlo mas tarde.',
              'error',
            );
          });
      },
    });
  };

  useEffect(() => {
    actions.getInvoicesList();
  }, []);

  useEffect(() => {
    if (invoiceControls.success) {
      setIsOpenModalInvoice(false);
      actions.getInvoicesList();
    }
  }, [invoiceControls.success, actions]);

  const columns: GridColDef[] = [
    {
      field: 'Date',
      headerName: 'Fecha de emisión',
      minWidth: 160,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.Date,
      valueFormatter: (params: any) => formatDate(params.value),
    },
    {
      field: 'invoice.Folio',
      headerName: 'Folio de factura',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 140,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.Folio,
    },
    {
      field: 'trackingNumber',
      headerName: 'No. Rastreo',
      type: 'number',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 130,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params?.row?.trackingNumber || 'N/A',
    },
    {
      field: 'invoice.PaymentTerms',
      headerName: 'Forma de pago',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.PaymentTerms || 'N/A',
    },
    {
      field: 'invoice.Total',
      headerName: 'Monto facturado',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 140,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.Total ?? 0,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
      sortable: false,
      minWidth: 100,
      renderCell: data => {
        return (
          <div>
            {(data.row?.transaction?.invoiced ||
              data.row?.Status == 'active') && (
              <Tooltip
                title={'Cancelar Factura'}
                arrow
              >
                <div>
                  <button
                    className={`${
                      !getChipColor(data.row.internalStatus)
                        ? 'bg-primary-inabit-disabled'
                        : 'bg-red-500 hover:bg-red-600'
                    } text-center p-2 text-white hover:text-white w-full h-full flex justify-center items-center rounded-lg`}
                    onClick={() => handlerCancelInvoice(data.row)}
                    disabled={!getChipColor(data.row.internalStatus)}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-6 h-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z'
                      />
                    </svg>
                  </button>
                </div>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className='w-full overflow-auto'>
      <div className='flex flex-row-reverse'>
        <button
          type='button'
          onClick={() => createInvoice({})}
          className='inline-flex float-right items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-lg text-white bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover transition ease-in-out duration-150 mb-4'
        >
          Generar Factura
        </button>
      </div>
      {/* Invoice Modal */}
      <Modal
        onClose={() => setIsOpenModalInvoice(false)}
        isOpen={isOpenModalInvoice}
      >
        <Facturama />
      </Modal>
      <DataGrid
        autoHeight
        localeText={{
          toolbarDensity: 'Tamaño de Columnas',
          toolbarDensityLabel: 'Size',
          toolbarDensityCompact: 'Pequeño',
          toolbarDensityStandard: 'Medio',
          toolbarDensityComfortable: 'Grande',
          toolbarExport: 'Exportar',
          toolbarFilters: 'Filtros',
          toolbarColumns: 'Columnas',
        }}
        rows={list || []}
        columns={columns}
        pageSize={12}
        loading={controls.loading}
        className='rounded-xl'
        components={{ Toolbar: CustomToolbar }}
        density='comfortable'
      />
    </div>
  );
};

const withConnect = connect(
  selectState(
    'app.profile',
    'invoicesList.controls',
    'invoicesList.list',
    'invoicesList.report',
    'invoice.invoiceControls',
  ),
  actionProps({
    ...invoicesActions,
    ...invoiceActions,
  }),
);

export default withRouter(withConnect(Invoices));
