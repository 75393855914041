import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../commons/reducer';

import { get } from '../../utils/api';
import { transactionListActions, TRANSACTION_LIST } from './reducer';

function* shipmentList() {
  yield takeLatest(TRANSACTION_LIST.GET_TRANSACTION_LIST, function* fn(action: any): any {
    try {
      const response = yield get('/transaction');
      if (response.error) {
        yield put(transactionListActions.getTransactionListFailed({ response: response.errorMsg }));
      } else {
        yield put(transactionListActions.getTransactionListSuccess(response.data));
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  }
  );
}
export default function* saga(): any {
  yield spawn(shipmentList);
}
