import React, { FunctionComponent, useState, useEffect } from 'react'
//Check Icons
import CheckIcon from '../../../assets/checkIcon.svg';
//Brands Image
import UpsLogo from '../../../assets/brands/UPS';
import DHLLogo from '../../../assets/brands/DHL.svg';
import FedExLogo from '../../../assets/brands/FEDEX.svg';
import PaquetexpressLogo from '../../../assets/brands/PAQUETEXPRESS.svg';
import Person2 from '../../../assets/person2.png';
import redPlane from '../../../assets/redPlane.png';
import {
  CardContent,
  Image,
  Header,
  CardGroup,
  List
} from 'semantic-ui-react';

interface BenefitsProps {
  widthScreen?: any;
}

const Benefits: FunctionComponent<BenefitsProps> = () => {

  const [showService, setShowService] = useState(false)

  const descriptionListNational = [
    {
      icon: CheckIcon,
      description: 'Express. Dia siguiente a toda la república mexicana.'
    },
    {
      icon: CheckIcon,
      description: 'Express antes de las 12pm, 10:30am y 8:30am.'
    },
    {
      icon: CheckIcon,
      description: 'Económico. Servicio de 1 a 5 días dependiendo del destino.'
    }
  ]
  const descriptionListInternational = [
    {
      icon: CheckIcon,
      description: 'Express. 24 a 48 horas servicio a Estados Unidos, Canadá y Europa.'
    },
    {
      icon: CheckIcon,
      description: 'Express. 24 a 72 horas al resto del mundo.'
    },
    {
      icon: CheckIcon,
      description: 'Terrestre a Estados Unidos.'
    },
    {
      icon: CheckIcon,
      description: 'Compra en Estados Unidos y nosotros nos encargamos de que llegue a la puerta de tu casa.'
    },

  ]

  const changeInfo = () => {
    
    
    if(showService){
      setTimeout(() => {
        setShowService(false)
      }, 4000);

    } else{
      setTimeout(() => {
        setShowService(true)
      }, 4000);   
    }
  }
  

useEffect(() => {
  changeInfo()
}, [showService])

  return (
    <CardGroup id='section4'>
      {/* Left Column */}
      <CardContent id='leftColumnSection4'>
        {/* <p id='howDoesItTextSection4'>COMO FUNCIONA</p> */}
        <Header id='titleSection4' content='Estamos aquí para entregar a ti y a tus clientes' />
        {!showService && <>
          <List id='listBenefitsContainer'>
            <Header icon='shipping fast' content='Servicio Nacional.' />
            {descriptionListNational.map((item, key) => (
              <List.Item id='listBenefitsItem' key={key}>
                <Image id='iconList' src={item.icon} />
                <List.Content id='benefitsText'>{item.description}</List.Content>
              </List.Item>))}
          </List>
        </>}
        {showService && <>
          <List id='listBenefitsContainer'>
            <Header icon='plane' content='Servicio Internacional.' />
            {descriptionListInternational.map((item, key) => (
              <List.Item id='listBenefitsItem' key={key}>
                <Image id='iconList' src={item.icon} />
                <List.Content id='benefitsText'>{item.description}</List.Content>
              </List.Item>))}
          </List>
        </>}
        <CardGroup>
          <UpsLogo id='brandLogo' width={40} height={10} />
          <Image id='brandLogo2' src={FedExLogo} />
          <Image id='brandLogo3' src={PaquetexpressLogo} />
          <Image id='brandLogo4' src={DHLLogo} />
        </CardGroup>
        <br />
        <p id='TextBottonSection4'>Enviamos a cualquier parte del mundo</p>
      </CardContent>
      {/*  Right Column */}
      <CardContent id='rightColumnSection4'>
        <CardContent id='backgroundPerson2'>
          <Image id='redPlane' src={redPlane} />
          <CardContent id='contentPerson2'>
            <Image id='person2' src={Person2} />

          </CardContent>
        </CardContent>
      </CardContent>
    </CardGroup>
  );
}

export default Benefits;