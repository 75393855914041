/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionProps, selectState } from '../../utils/reduxActions';
import { completeSignUpActions } from './reducer';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import paths from '../../config/paths';
import * as yup from "yup";
import { SERVER_URL, APPLICATION_KEY, SECRET_KEY } from '../../utils/constants'
import { getItemInStorage } from '../../utils/functions';
import FormData from 'form-data';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

interface CompleteSignUpProps {
  actions?: any;
  completeSignUpControls?: any;
  history?: any;
  result?: any;
}
interface CompleteSignUpFormInput {
  name: string;
  curp: string;
  websiteUrl?: string;
  typeOfUser: string;
  fiscalRegime?: string;
  certificatePsw?: string;
}

const schema = yup.object().shape({
  name: yup.string().trim().min(2).required(),
  curp: yup.string().trim().min(11).required(),
  websiteUrl: yup
    .string()
    .optional()
    .url(),
  typeOfUser: yup.string().trim().min(2).required(),
  fiscalRegime: yup.string().trim(),
  certificatePsw: yup.string().trim().min(4),
})

const fiscalRegime = [
  { value: "601", name: "General de Ley Personas Morales" },
  { value: "603", name: "Personas Morales con Fines no Lucrativos" },
  { value: "605", name: "Sueldos y Salarios e Ingresos Asimilados a Salarios" },
  { value: "606", name: "Arrendamiento" },
  { value: "607", name: "Régimen de Enajenación o Adquisición de Bienes" },
  { value: "608", name: "Demás ingresos" },
  { value: "610", name: "Residentes en el Extranjero sin Establecimiento Permanente en México" },
  { value: "611", name: "Ingresos por Dividendos (socios y accionistas)" },
  { value: "612", name: "Personas Físicas con Actividades Empresariales y Profesionales" },
  { value: "614", name: "Ingresos por intereses" },
  { value: "615", name: "Régimen de los ingresos por obtención de premios" },
  { value: "616", name: "Sin obligaciones fiscales" },
  { value: "620", name: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos" },
  { value: "621", name: "Incorporación Fiscal" },
  { value: "622", name: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras" },
  { value: "623", name: "Opcional para Grupos de Sociedades" },
  { value: "624", name: "Coordinados" },
  { value: "625", name: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas" },
  { value: "626", name: "Régimen Simplificado de Confianza" },
  { value: "628", name: "Hidrocarburos" },
  { value: "629", name: "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales" },
  { value: "630", name: "Enajenación de acciones en bolsa de valores" },
]
interface IDocument {
  files: FileList | [];
  error: string;
  upload: boolean
}

const CompleteSignUp: React.FC<CompleteSignUpProps> = (props) => {
  const [document, setDocument] = useState<IDocument>({ files: [], error: '', upload: false });
  const [csdFiles, setCsdFiles] = useState<IDocument>({ files: [], error: '', upload: false });
  const { actions, completeSignUpControls, history } = props;
  const { handleSubmit, register, formState: { errors }, watch } = useForm<CompleteSignUpFormInput>({
    resolver: yupResolver(schema)
  });

  const typeOfUser = watch('typeOfUser');
  const handleDocument = (e: any) => {
    setDocument({ files: e.target.files, error: '', upload: true })
  }
  const handleCertificates = (e: any) => {
    setCsdFiles({ files: e.target.files, error: '', upload: true })
  }

  const Oncomplete = async (data: CompleteSignUpFormInput) => {
    if (!document.upload) {
      setDocument({ ...document, error: 'No se ha cargado ningún documento' })
      return;
    } else if (document.files[0].type !== 'application/pdf') {
      setDocument({ ...document, error: 'Solo se permite formato PDF' })
      return;
    }

    if (typeOfUser === 'branch') {
      const files = Array.from(csdFiles.files)
      if (!csdFiles.upload) {
        setCsdFiles({ ...csdFiles, error: 'Deben cargarse los respectivos documentos .key y .cert' })
        return;
      } else if (csdFiles.files.length > 2) {
        setCsdFiles({ ...csdFiles, error: 'Solo se permite cargar 2 archivos  (.key y .cert)' })
        return;
      } else if (!files.find((e: any) => e.name.includes('.cer')) && !files.find((e: any) => e.name.includes('.key'))) {
        setCsdFiles({ ...csdFiles, error: 'Par de archivos incorrectos (.key y .cert)' })
      }
    }

    const formData = new FormData();

    formData.append('name', data.name)
    formData.append('curp', data.curp)
    formData.append('document', document.files[0])
    if (typeOfUser === 'branch' && csdFiles.upload) {
      formData.append('certificates', csdFiles.files[0]);
      formData.append('certificates', csdFiles.files[1]);
    }
    if (data.certificatePsw)
      formData.append('certificatePsw', data.certificatePsw);
    if (data.fiscalRegime)
      formData.append('fiscalRegime', data.fiscalRegime);

    actions.controlsChanged({ loading: true })
    try {
      const token = getItemInStorage('token');
      await axios.post(
        `${SERVER_URL}/complete-sign-up`,
        formData,
        {
          headers: {
            'application': APPLICATION_KEY,
            'secret': SECRET_KEY,
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          }
        })
    } catch (error: any) {
      MySwal.fire({
        icon: 'error',
        title: `Ooops, tenemos problemas`,
        text: error.response.data.error.message
      })
      actions.controlsChanged({ loading: false })
      return
    }
    actions.controlsChanged({ loading: false })
    history.push(paths.WAITING_FOR_REVIEW)
  }

  return (
    <>
      <Dimmer active={completeSignUpControls.loading} inverted>
        <Loader inverted>Guardando tus datos</Loader>
      </Dimmer>
      <div className="shadow-2xl rounded-2xl p-4">
        <p className="text-2xl text-center">Para acceder a todas nuestras herramientas es necesario que llenes estos datos:</p>
        <form onSubmit={handleSubmit(Oncomplete)}>
          <div className="grid grid-cols-1 grap-4 space-x-2 mt-12 w-full md:w-7/12 m-auto">
            <div className="grid grid-cols-1">
              <div className='grid'>
                <label>Seleccionar Tipo de usuario</label>
                <select {...register('typeOfUser')}
                  className={`border-2 text-lg rounded-lg h-12 mt-0 ${errors.typeOfUser ? 'border-red-500' : ''}`}>
                  <option value="natural">Cliente Inabit</option>
                  <option value="branch">Franquicia</option>
                </select>
                <span className='text-red-600 text-xs font-semibold h-5'>{errors.typeOfUser?.message}</span>
              </div>
              <div className="grid">
                <label>Nombre Completo</label>
                <input
                  className={`border-2 text-lg py-2 px-4 rounded-lg ${errors.name ? 'border-2 border-red-600' : ''}`}
                  type="text"
                  {...register('name' as const)}
                />
                <span className='text-red-600 text-xs font-semibold h-5'>{errors.name?.message}</span>
              </div>
              <div className="grid">
                <label>RFC o CURP</label>
                <input
                  className={`border-2 text-lg py-2 px-4 rounded-lg ${errors.curp ? 'border-2 border-red-600' : ''}`}
                  type="text"
                  {...register('curp' as const)}
                />
                <span className='text-red-600 text-xs font-semibold h-5'>{errors.curp?.message}</span>
              </div>
              <div className="grid">
                <label>Página web de tu empresa (Opcional)</label>
                <input
                  className={`border-2 text-lg py-2 px-4 rounded-lg ${errors.websiteUrl ? 'border-2 border-red-600' : ''}`}
                  type="text"
                  {...register('websiteUrl' as const)}
                />
                <span className='text-red-600 text-xs font-semibold h-5'>{errors.websiteUrl?.message}</span>
              </div>
              <div className="grid">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor="large_size">IFE, INE o Pasaporte</label>
                <input
                  onChange={(e) => handleDocument(e)}
                  className={`block w-full text-lg text-gray-900 bg-white rounded-lg border border-gray-300 cursor-pointer focus:outline-none ${document.error ? 'border-2 border-red-600' : ''}`}
                  id="large_size" type="file"
                  name='document'
                />
                <p className="mt-1 text-sm text-gray-500" id="file_input_help">Subir documento en formato PDF</p>
                <span className='text-red-600 text-xs font-semibold h-5'>{document.error}</span>
              </div>
            </div>
            <hr className="mt-4 mb-4 w-full" />
            {typeOfUser === 'branch' &&
              <div className="grid grid-cols-1">
                <p className="text-2xl">Sellos CSD digitales</p>
                <div className="grid grid-cols-1 mt-12">
                  <div className="grid">
                    <label>Seleccionar Regimen fiscal</label>
                    <select
                      {...register('fiscalRegime' as const, { required: typeOfUser === 'branch' })}
                      className={`w-full border-2 text-lg rounded-lg h-12 mt-0 ${errors.fiscalRegime ? 'border-red-500' : ''}`}>
                      {fiscalRegime.map((e: any) => (<option key={e.value} value={e.value}>{e.name}</option>
                      ))}
                    </select>
                    <span className='text-red-600 text-xs font-semibold h-5'>{errors.fiscalRegime?.message}</span>
                  </div>
                  <div className="grid">
                    <div className="grid">
                      <label>Contraseña de clave privada</label>
                      <input
                        {...register('certificatePsw' as const, { required: typeOfUser === 'branch', minLength: 4 })}
                        className={`border-2 text-lg py-2 px-4 rounded-lg ${errors.certificatePsw?.message ? 'border-2 border-red-600' : ''}`}
                        type="text"
                        {...register('certificatePsw' as const)}
                      />
                      <span className='text-red-600 text-xs font-semibold h-5'>{errors.certificatePsw?.message}</span>
                    </div>
                  </div>
                  <div className="grid">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor="large_size">Cargar certificados (Archivos .key y .cert) </label>
                    <input
                      multiple
                      onChange={(e) => handleCertificates(e)}
                      className={`block w-full text-lg text-gray-900 bg-white rounded-lg border border-gray-300 cursor-pointer focus:outline-none ${csdFiles.error ? 'border-2 border-red-600' : ''}`} id="large_size" type="file" name='certificate'
                    />
                    <p className="mt-1 text-sm text-gray-500" id="file_input_help">Archivo .cert y .key</p>
                    <span className='text-red-600 text-xs font-semibold h-5'>{csdFiles.error}</span>
                    {
                      csdFiles.upload &&
                      <div className="grid grid-cols-1 space-y-4">
                        <div className="flex py-2 bg-gray-100 rounded-lg shadow-lg">
                          <div className='grid w-12'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-10 h-10 text-blue-600">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>
                          </div>
                          <div className='grid'>
                            <p className='text-lg font-bold'>{csdFiles.files[0].name}</p>
                          </div>
                        </div>
                        <div className="flex py-2 bg-gray-100 rounded-lg shadow-lg">
                          <div className='grid w-12'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-10 h-10 text-blue-600">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>
                          </div>
                          <div className='grid'>
                            <p className='text-lg font-bold'>{csdFiles.files[1].name}</p>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
            <div className="my-10">
              <input
                type="submit"
                value="Comenzar ahora"
                disabled={Object.keys(errors).length >= 1}
                className={`bg-primary-inabit-red text-white text-xl px-6 py-4 rounded-xl ${Object.keys(errors).length >= 1 ? 'bg-gray-400 bg-opacity-25 cursor-not-allowed' : 'hover:bg-primary-inabit-purple'}`}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

const withConnect = connect(
  selectState('completeSignUp.completeSignUpControls', 'completeSignUp.completeSignUpResult'),
  actionProps({ ...completeSignUpActions, }),
);

export default withRouter(withConnect(CompleteSignUp));