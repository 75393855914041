/* eslint-disable no-param-reassign */

import { APP } from '../../../../commons/reducer';
import { createAction, createReducer } from '../../../../utils/reducer';
// Local constants
const PATH = 'public-quote/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const PUBLIC_CALCULATOR = {
  GET_QUOTE: `${PATH}GET_QUOTE`,
  PUBLIC_CALCULATOR_SUCCESS: `${PATH}PUBLIC_CALCULATOR_SUCCESS`,
  PUBLIC_CALCULATOR_FAILED: `${PATH}PUBLIC_CALCULATOR_FAILED`,
};

// actions
export const publicCalculatorActions = {
  controlsChanged: createAction(CONTROLS_CHANGED, 'calculatorControls'),
  getQuote: createAction(PUBLIC_CALCULATOR.GET_QUOTE, 'calculatorControls'),
  publicCalculatorSuccess: createAction(PUBLIC_CALCULATOR.PUBLIC_CALCULATOR_SUCCESS, 'result'),
  publicCalculatorFailed: createAction(PUBLIC_CALCULATOR.PUBLIC_CALCULATOR_FAILED, 'result'),
};

const initialState = {
  calculatorControls: {
    clientAddrOrig: {
      zipCode: '',
      colonyName: ''
    },
    clientAddrDest: {
      zipCode: '',
      colonyName: ''
    },
    amountOfPackets: 0,
    shipmentPktDetails: [],
    error: false,
    loading: false,
    origData: [],
    destData: [],
    shipmentSelect: {},
    acknowledgmentXT: 'Sin acuse'
  },
  calculatorResult: [],
};

const publicCalculator = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.calculatorControls = {
      ...state.calculatorControls,
      ...action.calculatorControls,
    };
  },
  [PUBLIC_CALCULATOR.GET_QUOTE](state: any) {
    state.calculatorControls.loading = true;
  },
  [PUBLIC_CALCULATOR.PUBLIC_CALCULATOR_SUCCESS](state: any, action: any) {
    state.calculatorResult = action.result.data;
    state.calculatorControls.loading = false;
    state.calculatorControls.actionQuote = true
  },
  [PUBLIC_CALCULATOR.PUBLIC_CALCULATOR_FAILED](state: any,) {
    state.calculatorControls.error = true;
    state.calculatorControls.loading = false;
    state.calculatorControls.errorMessage = 'Oops, wrong credentials.';
  },
  [APP.SERVER_FAILED](state: any) {
    state.calculatorControls.loading = false;
  },
});

export default publicCalculator;