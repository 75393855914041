import React from 'react';
import { RouteComponentProps, Route, Redirect } from 'react-router-dom';
import Layout from '../commons/Layout';

interface Props {
  Component: any;
  path: string;
  exact: boolean;
  roles: string[];
}

const AuthRoute = ({ Component, path, exact = false, roles }: Props): JSX.Element => {
  const isAuth = !!localStorage.getItem('token');
  return (
    <Route
      path={path}
      exact={exact}
      render={(props: RouteComponentProps) => (isAuth ? (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect to="/login" />
      ))}
    />
  );
};

export default AuthRoute;