import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actionProps, selectState } from '../../utils/reduxActions';
import { appActions } from '../reducer';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import { Dimmer, Loader } from 'semantic-ui-react';
import paths from '../../config/paths'

interface LayoutProps {
  children?: any
  history?: any;
  links?: any;
  actions?: any;
  profile?: any;
  appControls?: any
}

const Layout: React.FC<LayoutProps> = (props): JSX.Element => {
  const {
    children, history, actions, profile, appControls
  } = props;

  useEffect(() => { 
    actions.getProfileInfo()
   }, []);

  useEffect(() => {
    //redirect to complete-signUp if user has status different of 3
    if (profile?.status === 1) history.push(paths.COMPLETE_SIGN_UP)
    if (profile?.status === 2) history.push(paths.WAITING_FOR_REVIEW)
  }, [actions, profile?.status]);

  return (
    <div className='fullHD:container'>
      <Dimmer active={appControls.loading} inverted>
        <Loader inverted>Cargando....</Loader>
      </Dimmer>
      <div className="parent md:h-screen md:grid md:grid-cols-6 p-4">
        <Sidebar smallMenu={false} profile={profile} />
        <div className='main md:col-span-5'>
          <div className="relative mx-4 pr-6">
            <Navbar history={history} />
          </div>
          <div className="relative lg:mx-4 lg:pr-6">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const withConnect = connect(
  selectState(
    'app.appControls',
    'app.profile'
  ),
  actionProps({ ...appActions }),
);

export default compose(
  withConnect,
)(Layout);
