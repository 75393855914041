import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../reducer';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { get, post } from '../../../utils/api';
import { supportPaymentActions, SUPPORT_PAYMENT } from './reducer';

const MySwal = withReactContent(Swal)

function* invoice() {
  yield takeLatest(SUPPORT_PAYMENT.CREATE_SUPPORT_PAYMENT, function* fn(action: any): any {
    try {
      const { supportPaymentFields } = action
      delete supportPaymentFields['sendToAnotherEmail']
      delete supportPaymentFields['generalPublic']
      const body = { ...supportPaymentFields, emails: supportPaymentFields.emails?.split(",").map((e: string) => e.trim()) || [] }
      const response = yield post('/invoice/supportPayment', body);
      if (response.error) {
        yield put(supportPaymentActions.createSupportPaymentFailed({ response: response.errorMsg }));
        MySwal.fire({
          icon: 'error',
          title: `Ooops, tenemos problemas para generar tu factura`,
          text: JSON.parse(response.errorMsg).error.message,
        })
      } else {
        yield put(supportPaymentActions.createSupportPaymentSuccess(response));
        MySwal.fire({
          icon: 'success',
          title: `Tu complemento de pago se ha generado con exito, verifica tu correo`,
          text: response.errorMsg,
        })
      }
    } catch (error){
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  }
  );
}
export default function* saga(): any {
  yield spawn(invoice);
}
