import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionProps, selectState } from '../../utils/reduxActions';
import { generateShipmentActions } from './reducer';
import { bookAddressActions } from '../Address/AddressBook/reducer';
import Express from './AddressDetail/Express'
import Fedex from './AddressDetail/Fedex'
import Inabit from './AddressDetail/Inabit'
import ProductDetailView from './productDetail'


interface GenerateShipmentProps {
  actions?: any;
  shipmentControls?: any;
  history?: any;
  calculatorForm?: any
  productCatalog?: any
  list?: any
}

const GenerateShipment: React.FC<GenerateShipmentProps> = (props) => {
  const { actions, shipmentControls, calculatorForm } = props;
  const carrier = calculatorForm.shipmentSelect.carrier
  const GetAddressDetailView = (carrier: string) => {
    switch (carrier) {
      case 'EXPRESS':
        return (<Express />)
      case 'INABIT':
        return (<Inabit />)
      default:
        return (<Fedex />)
    }
  }

  useEffect(() => { actions.getAddresses() }, []);

  return (
    <>
      {
        shipmentControls.step === 1
          ?
          GetAddressDetailView(carrier)
          :
          <ProductDetailView />
      }
    </>
  );

}

const withConnect = connect(
  selectState(
    'generateShipment.shipmentControls',
    'generateShipment.result',
    'generateShipment.productCatalog',
    'calculator.calculatorForm',
    'addressBook.list'
  ),
  actionProps({ ...generateShipmentActions, ...bookAddressActions }),
);

export default withRouter(withConnect(GenerateShipment));
