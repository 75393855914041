/* eslint-disable no-param-reassign */

import { APP } from '../../commons/reducer';
import { createAction, createReducer } from '../../utils/reducer';
// Local constants
const PATH = 'home/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const HOME = {
  CONTACT_US: `${PATH}CONTACT_US`,
  CONTACT_US_SUCCESS: `${PATH}CONTACT_US_SUCCESS`,
  CONTACT_US_FAILED: `${PATH}CONTACT_US_FAILED`,
};

// actions
export const homeActions = {
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  // auth register
  contactUs: createAction(HOME.CONTACT_US, 'controls'),
  contactUsSuccess: createAction(HOME.CONTACT_US_SUCCESS, 'result'),
  contactUsFailed: createAction(HOME.CONTACT_US_FAILED, 'result'),
};

const initialState = {
  controls: {
    name: '',
    phone: '',
    email: '',
    subject: '',
    detail: '',
    acceptTermAndConditions: '',
    errorMessage: '',
    error: false,
    loading: false,
  },
  result: null
};

const home = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [HOME.CONTACT_US](state: any) {
    state.controls.loading = true;
  },
  [HOME.CONTACT_US_SUCCESS](state: any) {
    state.controls.loading = false;
    state.controls.name = '';
    state.controls.phone = '';
    state.controls.email = '';
    state.controls.subject = '';
    state.controls.detail = '';
    state.controls.acceptTermAndConditions = '';
  },
  [HOME.CONTACT_US_FAILED](state: any) {
    state.controls.error = true;
    state.controls.loading = false;
  },
  [APP.SERVER_FAILED](state: any) {
    state.controls.loading = false;
  },
});

export default home;