import React, { useState } from 'react'
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { MIN_AMOUNT_TO_RECHARGE, SERVER_URL, APPLICATION_KEY, SECRET_KEY } from '../../../utils/constants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionProps, selectState } from '../../../utils/reduxActions';
import { rechargeActions } from './reducer';
import axios from 'axios';
import { getItemInStorage } from '../../../utils/functions';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import paths from '../../../config/paths'
import { Dimmer, Loader } from 'semantic-ui-react'

const MySwal = withReactContent(Swal)

interface StripeProps {
  children?: any
  controls?: any
  stripeResult?: any
  actions?: any
  history?: any
  rechargeCreditsControls?: any
}

const paymentForm = [
  {
    name: "Tarjeta de crédito",
    value: "04"
  },
  {
    name: "Tarjeta de débito",
    value: "28"
  },
]

const StripeForm: React.FC<StripeProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const { history, rechargeCreditsControls, actions, controls } = props
  const stripe = useStripe();
  const elements = useElements();
  const [stripeError, setStripeError] = useState('')
  const handleChange = async (event: any) => {
    if (event.error) {
      setStripeError(event.error.message)
    } else {
      setStripeError('')
    }
  };
  const handleClick = async () => {
    try {
      setLoading(true)
      const request = await axios.post(
        `${SERVER_URL}/payments/stripe/recharge-credits`,
        { amount: rechargeCreditsControls.amount },
        {
          headers: {
            'application': APPLICATION_KEY,
            'secret': SECRET_KEY,
            'Authorization': `Bearer ${getItemInStorage('token')}`,
            'Content-Type': 'application/json',
          }
        }
      )
      const cardElement = elements?.getElement(CardElement);
      if (cardElement) {
        const payload = await stripe?.confirmCardPayment(request.data.clientSecret, {
          payment_method: {
            card: cardElement
          }
        });
        actions.controlsChanged({ payload: payload })
        if (payload?.error) {
          setLoading(false)
          MySwal.fire({
            icon: 'error',
            title: `Oops!`,
            text: `Algo salio mal con tu pago, ${payload.error.message}`,
          })
        } else {
          actions.saveRechargeCredits({
            payload,
            amount: rechargeCreditsControls.amount,
            paymentMethod: 'STRIPE',
            paymentForm: controls.paymentForm
          })
          setLoading(false)
          MySwal.fire({
            icon: 'success',
            title: '¡Recarga exitosa!',
            text: `Ya puedes hacer uso de tus créditos`,
          }).then(() => {
            history.push(paths.DASHBOARD)
          })
        }
      }
    } catch (err) {
      setLoading(false)
      MySwal.fire({
        icon: 'error',
        title: `Oops!`,
        text: 'Lo sentimos estamos teniendo problemas internos, por favor vuelve a intentarlo más tarde!',
      })
    }
  }

  const onChangePaymentForm = (e: any) => actions.controlsChanged({ paymentForm: e.target.value })


  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader inverted>Procesando recarga</Loader>
      </Dimmer>
      <div className="grid w-full lg:w-144 m-auto">
        <div className="my-8">
          <h1 className="font-semibold text-2xl my-2">Datos de pago: </h1>
          <hr className="my-2 md:min-w-full " />
        </div>
        <div className="my-2">
          <input className="w-full shadow-md p-4 text-2xl rounded-xl" placeholder="Titular de la tarjeta" />
        </div>
        <div className="my-2">
          <select
            onChange={(e => onChangePaymentForm(e))}
            className={`w-full border-2 py-2 px-4 mt-2 text-xl rounded-lg focus:outline-none`}>
            <option key={""} value={""} disabled selected>Seleccionar tipo de tarjeta</option>
            {paymentForm.map((form) => (
              <option key={form.value} value={form.value}>
                {form.name}
              </option>
            ))}
          </select>
        </div>
        <div className="my-2 bg-white p-4 rounded-xl shadow-md">
          <CardElement
            onChange={handleChange}
            options={{
              hidePostalCode: true, style: {
                base: {
                  color: "#3C1D2B",
                  fontFamily: 'sans-serif',
                  fontSmoothing: "antialiased",
                  fontSize: "20px",
                  "::placeholder": {
                    color: "#BAB8B466"
                  }
                },
                invalid: {
                  color: "#FA3A49",
                  iconColor: "#490A3C"
                }
              }
            }}
          />
        </div>
        <div><span className="text-md text-primary-inabit-red">{stripeError}</span></div>
        <div>
          <button
            type="submit"
            disabled={rechargeCreditsControls.amount <= MIN_AMOUNT_TO_RECHARGE || controls.paymentForm === '' || (stripeError.length > 0)}
            onClick={handleClick}
            className={`w-full bg-primary-inabit-red hover:bg-primary-inabit-purple py-6 px-20 text-white rounded-lg shadow-xl mt-4 text-2xl ${rechargeCreditsControls.amount <= MIN_AMOUNT_TO_RECHARGE || controls.paymentForm === '' || stripeError.length > 0 ? 'disabled:opacity-25 cursor-not-allowed' : ''}`}
          >
            Comprar / Recargar
          </button>
        </div>
      </div>
    </>
  );
}

const withConnect = connect(
  selectState(
    'stripeRechargeCredits.controls',
    'rechargeCredits.rechargeCreditsControls'
  ),
  actionProps({ ...rechargeActions }),
);

export default withRouter(withConnect(StripeForm));