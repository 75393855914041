import { PaymentMethod } from '../../interfaces/rechargeCredits'
import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionProps, selectState } from '../../utils/reduxActions';
import { rechargeCreditsActions } from './reducer';
import { MIN_AMOUNT_TO_RECHARGE, STRIPE_API_CLIENT } from '../../utils/constants'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from "@stripe/stripe-js";

import CardIcon from "../../assets/img/cards.svg"
import PaypalIcon from "../../assets/img/paypal.svg"
import BankTransferIcon from "../../assets/img/bank-transfer.svg"
import StripeForm from './Stripe'
import PaypalBtn from './Paypal'
import SpeiBtn from './Spei'

const stripePromise = loadStripe(STRIPE_API_CLIENT);

interface RechargeCreditsProps {
  actions?: any;
  rechargeCreditsControls?: any;
  history?: any;
  rechargeResult?: any
}

const RechargeCredits: React.FC<RechargeCreditsProps> = (props) => {
  const { actions, rechargeCreditsControls } = props
  const selectPaymentMethod = (paymentMethod: PaymentMethod) => actions.controlsChanged({ paymentMethod })
  const onChangeInput = (e: any, name: string) => actions.controlsChanged({ [name]: parseFloat(e.target.value) })
  const renderPaymentForm = (type: PaymentMethod) => {
    switch (type) {
      case PaymentMethod.paypal:
        return (<PaypalBtn />)
      case PaymentMethod.spei:
        return (<SpeiBtn />)
      case PaymentMethod.stripe:
      default:
        return (
          <Elements stripe={stripePromise} >
            <StripeForm />
          </Elements>
        )
    }
  }

  return (
    <>
      <div className="container mx-auto p-4 rounded-xl text-center" >
        <div className='w-full'>
          <h1 className="text-lg lg:text-4xl font-semibold shadow-3xl my-2">Recarga créditos con tu método de pago preferido</h1>
          <hr className="my-6" />
          <h1 className="text-xl font-semibold shadow-3xl my-2 text-gray-500">Selecciona tu método de pago</h1>
          <div className="grid grid-cols-1 lg:grid-cols-3 lg:space-x-11">
            <div className='grid p-4 text-center'>
              <button className='bg-white rounded-xl hover:bg-primary-inabit-blue hover:border-red-400 p-4 shadow-lg ' onClick={() => selectPaymentMethod(PaymentMethod.stripe)}>
                <img src={CardIcon} width={80} alt="cards" className='m-auto' />
                <span className='text-xl'>Débito/Crédito</span>
              </button>
            </div>
            <div className='grid p-4'>
              <button className='bg-white rounded-xl hover:bg-primary-inabit-blue hover:border-red-400 p-4 shadow-lg' onClick={() => selectPaymentMethod(PaymentMethod.paypal)}>
                <img src={PaypalIcon} width={80} alt="paypal" className='m-auto' />
                <span className='text-xl'>Paypal</span>
              </button>
            </div>
            <div className='grid p-4'>
              <button className='bg-white rounded-xl hover:bg-primary-inabit-blue hover:border-red-400 p-4 shadow-lg' onClick={() => selectPaymentMethod(PaymentMethod.spei)}>
                <img src={BankTransferIcon} width={80} alt="bankTransfer" className='m-auto' />
                <span className='text-xl'>Transferencia Bancaria</span>
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 my-6">
            <h2 className="text-xl font-semibold shadow-3xl my-2 text-gray-500">Cantidad de créditos a recargar:</h2>
            <hr className="my-2" />
            <div className="grid grid-cols-2 p-4">
              <div className="grid justify-items-end">
                <input
                  type="number"
                  className="w-full lg:w-72 text-4xl border-1 bg-white-200 text-right rounded-l-lg focus:outline-none focus:border-none"
                  placeholder="1000"
                  min={MIN_AMOUNT_TO_RECHARGE}
                  onChange={(e) => { onChangeInput(e, 'amount') }}
                ></input>
              </div>
              <div className="grid justify-items-start content-center">
                <span className="text-left p-2 text-4xl w-full lg:w-72 text-gray-400 bg-white rounded-r-lg">MXN</span>
              </div>
            </div>
          </div>
        </div>
        <div className="grid w-full">
          {renderPaymentForm(rechargeCreditsControls.paymentMethod)}
        </div>
      </div>
    </>
  );
}

const withConnect = connect(
  selectState(
    'rechargeCredits.rechargeCreditsControls',
    'rechargeCredits.rechargeResult'
  ),
  actionProps({ ...rechargeCreditsActions }),
);

export default withRouter(withConnect(RechargeCredits));