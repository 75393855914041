/* eslint-disable no-param-reassign */

import { APP } from '../../commons/reducer';
import { createAction, createReducer } from '../../utils/reducer';
// Local constants
const PATH = 'signUp/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const REGISTER = {
  REGISTER: `${PATH}AUTH`,
  REGISTER_SUCCESS: `${PATH}AUTH_SUCCESS`,
  REGISTER_FAILED: `${PATH}AUTH_FAILED`,

};

// actions
export const registerActions = {
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  // auth register
  register: createAction(REGISTER.REGISTER, 'controls'),
  registerSuccess: createAction(REGISTER.REGISTER_SUCCESS, 'result'),
  registerFailed: createAction(REGISTER.REGISTER_FAILED, 'result'),

};

const initialState = {
  controls: {
    name: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    companyName:'',
    quantityOrders:'',
    businessType:'',
    howHelpYou:[],
    requiredService:[],
    nameError: '',
    phoneNumberError: '',
    emailError: '',
    passwordError: '',
    confirmPasswordError: '',
    companyNameError:'',
    quantityOrdersError:'',
    businessTypeError:'',
    howHelpYouError:'',
    requiredServiceError:'',
    errorMessage: '',
    error: false,
    loading: false,
  },
};

const register = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [REGISTER.REGISTER](state: any) {
    state.controls.loading = true;
  },
  [REGISTER.REGISTER_SUCCESS](state: any) {
    state.controls.userLogged = true;
    state.controls.loading = false;    
    state.controls.name= '';
    state.controls.email= '';
    state.controls.phoneNumber= '';
    state.controls.password= '';
    state.controls.confirmPassword= '';
    state.controls.companyName='';
    state.controls.quantityOrders='';
    state.controls.businessType='';
    /* state.controls.howHelpYou='';
    state.controls.requiredService=[]; */
  },
  [REGISTER.REGISTER_FAILED](state: any) {
    state.controls.error = true;
    state.controls.loading = false;
    state.controls.errorMessage = 'Oops, wrong credentials.';
  },
  [APP.SERVER_FAILED](state: any) {
    state.controls.loading = false;
  },
});

export default register;