/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { createSelector } from 'reselect';

export const actionProps = (actionsObj: any) => (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(actionsObj, dispatch),
  dispatch,
});

const getState = (statePortion: string) => {
  const splitedState = statePortion.split('.');

  return (state: any) => {
    let tempState = state;

    if (/\./.test(statePortion)) {
      splitedState.forEach((value: string | number) => {
        tempState = tempState[value];
      });
    } else {
      tempState = tempState[statePortion];
    }

    return tempState;
  };
};

const getSelectors = (key: any, valueState: any, state: any) => ({
  [key]: createSelector([getState(valueState)], (reselectState) => reselectState)(state),
});

// eslint-disable-next-line max-len
export const selectState = (...statePortion: any[]) => (state: any) => statePortion.reduce((prev, current) => {
  const stateName = current.split('.').pop();
  return { ...prev, ...getSelectors(stateName, current, state) };
}, {});