/* eslint-disable no-param-reassign */

import { APP } from '../../reducer';
import { createAction, createReducer } from '../../../utils/reducer';
// Local constants
const PATH = 'supportPayment/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const SUPPORT_PAYMENT = {
  CREATE_SUPPORT_PAYMENT: `${PATH}CREATE_SUPPORT_PAYMENT`,
  CREATE_SUPPORT_PAYMENT_SUCCESS: `${PATH}CREATE_SUPPORT_PAYMENT_SUCCESS`,
  CREATE_SUPPORT_PAYMENT_FAILED: `${PATH}CREATE_SUPPORT_PAYMENT_FAILED`,
};

// actions
export const supportPaymentActions = {
  supportPaymentFieldsChanged: createAction(CONTROLS_CHANGED, 'supportPaymentFields'),
  createSupportPayment: createAction(SUPPORT_PAYMENT.CREATE_SUPPORT_PAYMENT, 'supportPaymentFields'),
  createSupportPaymentSuccess: createAction(SUPPORT_PAYMENT.CREATE_SUPPORT_PAYMENT_SUCCESS, 'supportPaymentRes'),
  createSupportPaymentFailed: createAction(SUPPORT_PAYMENT.CREATE_SUPPORT_PAYMENT_FAILED, 'supportPaymentRes'),
}

const initialState = {
  supportPaymentFields: {
    paymentForm: '',
    receiver: {
      rfc: '',
      name: '',
      cfdiUse: '',
    },
    invoiceId: '',
    errorMessage: '',
    amount: 0,
    error: false,
    loading: false,
    success: null,
  },
  supportPaymentRes: {},
};

const supportPayment = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.supportPaymentFields = {
      ...state.supportPaymentFields,
      ...action.supportPaymentFields,
    };
  },
  [APP.SERVER_FAILED](state: any) {
    state.supportPaymentFields.loading = false;
  },
  [SUPPORT_PAYMENT.CREATE_SUPPORT_PAYMENT](state: any) {
    state.supportPaymentFields.loading = true;
  },
  [SUPPORT_PAYMENT.CREATE_SUPPORT_PAYMENT_SUCCESS](state: any) {
    state.supportPaymentFields.loading = false;
    state.supportPaymentFields.success = true;
    state.supportPaymentFields.paymentForm = null;
    state.supportPaymentFields.receiver.rfc = null;
    state.supportPaymentFields.receiver.name = null;
    state.supportPaymentFields.invoiceId = null;
    state.supportPaymentFields.error = false;
  },
  [SUPPORT_PAYMENT.CREATE_SUPPORT_PAYMENT_FAILED](state: any) {
    state.supportPaymentFields.error = true;
    state.supportPaymentFields.loading = false;
    state.supportPaymentFields.errorMessage = 'Ocurrio un error al crear la factura';
    state.supportPaymentFields.success = false;
  },
});

export default supportPayment;
