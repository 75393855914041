/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  CardContent,
  Segment,
  CardGroup,
  Header,
  Button,
  Image,
  Accordion,
  Icon,
  Grid,
} from 'semantic-ui-react';

import { Map, markers, Marker } from './map';
import { Wrapper } from '@googlemaps/react-wrapper';

import LocationMapIcon from '../../../assets/locationMapIcon.svg';

interface BranchProps {
  widthScreen?: any;
}

const Branch: React.FC<BranchProps> = props => {
  const { widthScreen } = props;

  const [openClose, setOpenClose] = useState(false);

  const openMenuMap = () => {
    setOpenClose(true);
    document.documentElement.style.setProperty('--menuMapDoor', '380px');
    if (openClose) {
      document.documentElement.style.setProperty('--menuMapDoor', '60px');
      setOpenClose(false);
    }
  };
  //Accordion Function
  const [activeIndex, setActiveIndex] = useState(0);
  const [nameCity, setNameCity] = useState<string>('Home');

  const [tagCity, setTagCity] = useState<any>({
    lon: -97.5353938095054,
    lat: 19.226375899499534,
    zoom: 4.5,
  });
  const [newMakers, setNewMakers] = useState<any>(markers);

  const handleClick = (index: any) => {
    setTagCity({ lon: index.lon, lat: index.lat, zoom: 16 });
    setNewMakers([{ lat: index.lat, lng: index.lon }]);
    setActiveIndex(index.number);
    if (activeIndex === index.number) {
      setNameCity(index.city);
      if (nameCity === 'Home') {
        setTagCity({
          lat: 20.212057419924395,
          lon: -100.0593107124024,
          zoom: 2.5,
        });
      }

      if (nameCity === 'Querétaro') {
        setTagCity({
          lat: 20.63173,
          lon: -100.40129,
          zoom: 9,
        });
      }
      if (nameCity === 'CDMX') {
        setTagCity({
          lat: 19.447667309761027,
          lon: -99.1889399334488,
          zoom: 9,
        });
      }
      if (nameCity === 'Guanajuato') {
        setTagCity({
          lat: 21.1391922791908,
          lon: -101.69450937886619,
          zoom: 9,
        });
      }
      if (nameCity === 'Jalisco') {
        setTagCity({
          lat: 19.702682188668255,
          lon: -101.20475119667694,
          zoom: 9,
        });
      }
      if (nameCity === 'Puebla') {
        setTagCity({
          lat: 19.035753375112034,
          lon: -98.21417827167029,
          zoom: 9,
        });
      }
      if (nameCity === 'Quintana Roo') {
        setTagCity({
          lat: 20.681194693301673,
          lon: -87.04618895582279,
          zoom: 9,
        });
      }
      setNewMakers(markers);
      setActiveIndex(-1);
    }
  };
  //Queretaro
  const ubications1 = [
    {
      number: 1,
      city: 'Querétaro',
      title1: 'Loma Dorada',
      title2: 'Blvd. B. Quintana, 76060',
      site: 'Blvd.  B. Quintana Nº 161, Col. Loma Dorada',
      subTitle2: 'Loma Dorada',
      contentLeftColumn: 'lomadorada@integralogis.com.mx \n|||||||  C.P. 76060 |||||||\n  Tel. (442) 223 06 88 ',
      contentRightColumn: 'L-V 8-20, S 9-16',
      lon: -100.36755616626246,
      lat: 20.59272412297873,
    },
    {
      number: 2,
      city: 'Querétaro',
      title1: 'Plaza del Parque',
      title2: 'Plaza del Parque, 76169',
      site: 'Plaza del Parque, Prol Corregidora Norte No 691 Edificio E Locales 1 y 2 CP 76169',
      subTitle2: 'Plaza del Parque',
      contentLeftColumn: 'plazadelparque@integralogis.com.mx \n|||||||  C.P. 76169 |||||||\n  Tel. (442) 223 36 46',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -100.3907516,
      lat: 20.6138859,
    },
    {
      number: 3,
      city: 'Querétaro',
      title1: 'Plaza de las Americas',
      title2: 'Plaza de las Americas, 76050',
      site: 'Plaza de las Américas, Av. Panamá Local 314, Carretas CP 76050',
      subTitle2: 'Plaza de las Americas',
      contentLeftColumn:
        'americas@integralogis.com.mx \n|||||||  C.P. 76050 |||||||\n  Tel. (442) 248 33 37',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -100.37260119509745,
      lat: 20.588250003044532,
    },
    {
      number: 4,
      city: 'Querétaro',
      title1: 'Plaza Bal',
      title2: 'Plaza Bal, 76125',
      site: 'Plaza Bal, Blvd Bernardo Quintana 630 local 2, Desarrollo San Pablo CP 76125',
      subTitle2: 'Plaza Bal',
      contentLeftColumn:
        'plazabal@integralogis.com.mx \n|||||||  C.P. 76125 |||||||\n  Tel. (442) 223 72 08',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -100.4100183,
      lat: 20.6196397,
    },
    {
      number: 5,
      city: 'Querétaro',
      title1: 'Plaza El Pocito',
      title2: 'Plaza El Pocito, 76902',
      site: 'Av. Constituyentes No. 47 local 6 Col. El Pocito CP 76902',
      subTitle2: 'Plaza El Pocito',
      contentLeftColumn:
        'pocito@integralogis.com.mx \n|||||||  C.P. 76902 |||||||\n  Tel. (442) 196 85 58',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -100.4501558,
      lat: 20.549838,
    },
    {
      number: 6,
      city: 'Querétaro',
      title1: 'Plaza El Manantial',
      title2: 'Plaza El Manantial, 76922',
      site: 'Plaza Manantial Blvd. Del Lago 3-24, Fracc. Manantiales, Cañadas del Lago, El Progreso, Qro. C.P. 76922.',
      subTitle2: 'Plaza El Manantial',
      contentLeftColumn:
        'qro.manantial@inabitmexico.com \n|||||||  C.P. 76922 |||||||\n  Tel. (442) 865 60 28',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -100.4019656,
      lat: 20.5059573,
    },
  ];
  // CDMX
  const ubications4 = [
    {
      number: 1,
      city: 'CDMX',
      title1: 'Camarones',
      title2: 'Camarones, 02060',
      site: 'Norte 83 no. 326 local B, Col. Sindicato Mexicano de Electricista CP 02060',
      subTitle2: 'Camarones',
      contentLeftColumn:
        'camarones@bitenvios.com \n|||||||  C.P.  02060 |||||||\n  Tel. (55) 1549 1107',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -99.17854204048099,
      lat: 19.471316623507136,
    },
    {
      number: 2,
      city: 'CDMX',
      title1: 'Calzada de Tlalpan',
      title2: 'Calz. De Tlalpan, 03510',
      site: 'Calz. De Tlalpan 610 local A, Col. Moderna CP 03510',
      subTitle2: 'Calzada de Tlalpan',
      contentLeftColumn:
        'ctlalpan@bitenvios.com \n|||||||  C.P.  03510 |||||||\n  Tel. (55) 2609 9289',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -99.1373705,
      lat: 19.3961934,
    },
    {
      number: 3,
      city: 'CDMX',
      title1: 'República de Perú',
      title2: 'República de Perú, 06000',
      site: 'República de Perú 61 local A, Centro CP 06000',
      subTitle2: 'República de Perú',
      contentLeftColumn:
        'mex.peru@inabitmexico.com \n|||||||  C.P. 06000 |||||||\n  Tel. (55) 1056 3029',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -99.1357400981519,
      lat: 19.439637147994443,
    },
    {
      number: 4,
      city: 'CDMX',
      title1: 'San Marcos',
      title2: 'San Marcos, 06060',
      site: 'San Marcos 28, Centro, Cuauhtemoc, CDMX 06060',
      subTitle2: 'San Marcos',
      contentLeftColumn:
        'mex.sanmarcos@inabitmexico.com \n|||||||  C.P. 06060 |||||||\n  Tel. (55) 1549 3878',
      contentRightColumn: 'L-V 9-18, S 10-16',
      lon: -99.1250905,
      lat: 19.4327514,
    },
    {
      number: 5,
      city: 'CDMX',
      title1: 'Rojo Gomez',
      title2: 'Rojo Gomez, 09310',
      site: 'Av. Rojo Gómez 726 local A, Col. Leyes de Reforma 3ra Sección, CP 09310',
      subTitle2: 'Rojo Gomez',
      contentLeftColumn:
        'mex.rojogómez@inabitmexico.com \n|||||||  C.P. 09310 |||||||\n  Tel. (55) 5640 6201',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -99.07801049999999,
      lat: 19.37570209974448,
    },
    {
      number: 6,
      city: 'CDMX',
      title1: 'Marina Nacional',
      title2: 'Marina Nacional, 11320',
      site: 'Av. Marina Nacional 330 local F1, Col. Anahuac I, CP 11320',
      subTitle2: 'Marina Nacional',
      contentLeftColumn:
        'marina.nacional@inabitmexico.com \n|||||||  C.P. 09310 |||||||\n  Tel. (55) 2589 8898',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -99.17461123124043,
      lat: 19.440121730457122,
    },
    {
      number: 7,
      city: 'CDMX',
      title1: 'Impulsora',
      title2: 'Impulsora, 57130',
      site: 'Av Central 127 local B, Col. Impulsora Popular Avícola, CP 57130',
      subTitle2: 'Impulsora',
      contentLeftColumn:
        'mex.impulsora@inabitmexico.com \n|||||||  C.P. 09310 |||||||\n  Tel. (55) 2484 8040',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -99.050208,
      lat: 19.4814851,
    },
    {
      number: 8,
      city: 'CDMX',
      title1: 'Tacuba',
      title2: 'Legaria, 11410',
      site: 'Calzada Legaria 22 local D, Col. Tacuba, C.P. 11410',
      subTitle2: 'Tacuba',
      contentLeftColumn:
        'mex.legaria@inabitmexico.com \n|||||||  C.P. 11410 |||||||\n  Tel. (55) 2793 5835',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -99.1918403,
      lat: 19.4579221,
    },
  ];
  //Guanajuato
  const ubications2 = [
    {
      number: 1,
      city: 'Guanajuato',
      title1: 'León',
      title2: 'León, 37266',
      site: 'Calle La Luz 1004 Local D, Col. Españita CP 37266',
      subTitle2: 'León',
      contentLeftColumn:
        'leon@bitenvios.com \n|||||||  C.P. 37266 |||||||\n  Tel. (477) 1484017 ',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -101.6597643,
      lat: 21.1215807,
    },
  ];
  // Jalisco
  const ubications3 = [
    {
      number: 1,
      city: 'Jalisco',
      title1: 'Plaza Lisboa',
      title2: 'Plaza Lisboa, 44240',
      site: 'Plaza Lisboa Calzada Independencia Norte No. 2175-21, Col. Monte Tabor Monserrat Independencia C.P. 44240',
      subTitle2: 'Plaza Lisboa',
      contentLeftColumn:
        'gdl.plazalisboa@inabitmexico.com\n|||||||  C.P. 37266 |||||||\n  Tel. (333) 699 3746',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -103.3248754,
      lat: 20.7073113,
    },
  ];
  // Puebla
  const ubications5 = [
    {
      number: 1,
      city: 'Puebla',
      title1: 'Puebla',
      title2: 'Puebla, 72000',
      site: ' Calle 7 Poniente 501 Local 1, Col Centro CP 72000',
      subTitle2: 'Puebla',
      contentLeftColumn:
        ' pbc.centro@enviosinabit.com \n|||||||  C.P.  72000 |||||||\n  Tel. (222) 2253 485 ',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -98.2033005,
      lat: 19.04387,
    },
  ];
  //Quintana Roo
  const ubications6 = [
    {
      number: 1,
      city: 'Quintana Roo',
      title1: 'Playa del Carmen',
      title2: 'Playa del Carmen, 77726',
      site: 'Plaza del Sol, Av. Xel-Ha SM82 MZ8 LT4, Local 16, CP 77726',
      subTitle2: 'Playa del Carmen',
      contentLeftColumn:
        ' pcm.xelha@inabitmexico \n|||||||  C.P.  77726 |||||||\n  Tel. (984) 688 6811 ',
      contentRightColumn: 'L-V 9-19, S 9-14',
      lon: -87.04618895582279,
      lat: 20.681194693301673,
    },
  ];

  const [selectCity, setSelectCity] = useState<any>(ubications1);

  useEffect(() => {
    if (activeIndex === -1) setNewMakers(markers);
  }, [nameCity, newMakers]);

  const orderAlphabetically = (array: any, field: string) =>
    array.sort((a: any, b: any) => {
      let titleA = a[field].toUpperCase(); // ignore case
      let titleB = b[field].toUpperCase(); // ignore case
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });

  return (
    <Segment id='section6'>
      <CardGroup id='cardGroupMaps'>
        {/* Left Column */}
        <CardContent id='leftColumnMap'>
          <CardContent id='map'>
            <CardGroup id='menuSection6'>
              {widthScreen >= 1550 && (
                <Header
                  id='titleSection6'
                  content='Ubica nuestras sucursales'
                />
              )}
              {widthScreen < 1550 && (
                <CardGroup>
                  <Header
                    id='titleSection6'
                    content='Ubica nuestras sucursales'
                  />
                  <Button
                    icon='bars'
                    id='menuIconMap'
                    onClick={() => openMenuMap()}
                  />
                </CardGroup>
              )}
              {widthScreen < 1550 && (
                <CardContent id='menuItemMap'>
                  <br />
                  <p
                    id='btnMenuMap'
                    onClick={() => {
                      openMenuMap();
                      setSelectCity(ubications1);
                      setTagCity({ lat: 20.63173, lon: -100.40129, zoom: 9 });
                      setNewMakers(markers);
                    }}
                  >
                    Querétaro
                  </p>
                  <br />
                  <p
                    id='btnMenuMap'
                    onClick={() => {
                      openMenuMap();
                      setSelectCity(ubications4);
                      setTagCity({
                        lat: 19.447667309761027,
                        lon: -99.1889399334488,
                        zoom: 9,
                      });
                      setNewMakers(markers);
                    }}
                  >
                    CDMX
                  </p>
                  <br />
                  <p
                    id='btnMenuMap'
                    onClick={() => {
                      openMenuMap();
                      setSelectCity(ubications2);
                      setTagCity({ lat: 21.121673, lon: -101.66002, zoom: 9 });
                      setNewMakers(markers);
                    }}
                  >
                    Guanajuato
                  </p>
                  <br />
                  <p
                    id='btnMenuMap'
                    onClick={() => {
                      openMenuMap();
                      setSelectCity(ubications3);
                      setTagCity({ lat: 20.706979, lon: -103.32488, zoom: 9 });
                      setNewMakers(markers);
                    }}
                  >
                    Jalisco
                  </p>
                  <br />
                  <p
                    id='btnMenuMap'
                    onClick={() => {
                      openMenuMap();
                      setSelectCity(ubications5);
                      setTagCity({
                        lat: 19.04413591106933,
                        lon: -98.20342100266858,
                        zoom: 9,
                      });
                      setNewMakers(markers);
                    }}
                  >
                    Puebla
                  </p>
                  <br />
                  <p
                    id='btnMenuMap'
                    onClick={() => {
                      openMenuMap();
                      setSelectCity(ubications6);
                      setTagCity({
                        lat: 20.681194693301673,
                        lon: -87.04618895582279,
                        zoom: 9,
                      });
                      setNewMakers(markers);
                    }}
                  >
                    Quintana Roo
                  </p>
                </CardContent>
              )}
            </CardGroup>
            <Wrapper apiKey={'AIzaSyBWA6IVq5UcsUYb7L94Vc_qwAtDDKV6fw8'}>
              <Map
                center={{ lat: tagCity.lat, lng: tagCity.lon }}
                zoom={tagCity.zoom}
                style={{ flexGrow: '1', height: '100%' }}
                onClick={undefined}
                onIdle={undefined}
              >
                {newMakers.map((marker: any) => {
                  return <Marker position={marker} />;
                })}
              </Map>
            </Wrapper>{' '}
          </CardContent>
        </CardContent>
        {/* Right Column */}
        <CardContent id='rightColumnMap'>
          {/* input search */}
          {/*   <CardGroup id='inputGroupMap'>
            <Input id='inputServiceLargeMap' placeholder='Ingresa tu municipio o ciudad…' />
            <Button id='btnServicesMap' icon='search' content='Buscar' />
          </CardGroup> */}
          <CardContent id='menuContainerSection6'>
            {widthScreen >= 1550 && (
              <CardGroup>
                <Button
                  id={
                    nameCity === 'Home'
                      ? 'btnMenuSection6Active'
                      : 'btnMenuSection6'
                  }
                  onClick={() => {
                    setNameCity('Home');
                    setNewMakers(markers);
                    setTagCity({
                      lat: 20.212057419924395,
                      lon: -100.0593107124024,
                      zoom: 4.5,
                    });
                  }}
                  icon='home'
                />
                <Button
                  id={
                    nameCity === 'Querétaro'
                      ? 'btnMenuSection6Active'
                      : 'btnMenuSection6'
                  }
                  onClick={() => {
                    setSelectCity(orderAlphabetically(ubications1, 'title1'));
                    setNameCity('Querétaro');
                    setNewMakers(markers);
                    setTagCity({ lat: 20.63173, lon: -100.40129, zoom: 9 });
                  }}
                  content='Querétaro'
                />
                <Button
                  id={
                    nameCity === 'CDMX'
                      ? 'btnMenuSection6Active'
                      : 'btnMenuSection6'
                  }
                  onClick={() => {
                    setSelectCity(orderAlphabetically(ubications4, 'title1'));
                    setNameCity('CDMX');
                    setNewMakers(markers);
                    setTagCity({
                      lat: 19.447667309761027,
                      lon: -99.1889399334488,
                      zoom: 9,
                    });
                  }}
                  content='CDMX'
                />
                <Button
                  id={
                    nameCity === 'Guanajuato'
                      ? 'btnMenuSection6Active'
                      : 'btnMenuSection6'
                  }
                  onClick={() => {
                    setSelectCity(orderAlphabetically(ubications2, 'title1'));
                    setNameCity('Guanajuato');
                    setNewMakers(markers);
                    setTagCity({ lat: 21.121673, lon: -101.66002, zoom: 9 });
                  }}
                  content='Guanajuato'
                />
                <Button
                  id={
                    nameCity === 'Jalisco'
                      ? 'btnMenuSection6Active'
                      : 'btnMenuSection6'
                  }
                  onClick={() => {
                    setSelectCity(orderAlphabetically(ubications3, 'title1'));
                    setNameCity('Jalisco');
                    setNewMakers(markers);
                    setTagCity({ lat: 20.706979, lon: -103.32488, zoom: 9 });
                  }}
                  content='Jalisco'
                />
                <Button
                  id={
                    nameCity === 'Puebla'
                      ? 'btnMenuSection6Active'
                      : 'btnMenuSection6'
                  }
                  onClick={() => {
                    setSelectCity(orderAlphabetically(ubications5, 'title1'));
                    setNameCity('Puebla');
                    setNewMakers(markers);
                    setTagCity({
                      lat: 19.04413591106933,
                      lon: -98.20342100266858,
                      zoom: 9,
                    });
                  }}
                  content='Puebla'
                />
                <Button
                  id={
                    nameCity === 'Quintana Roo'
                      ? 'btnMenuSection6Active'
                      : 'btnMenuSection6'
                  }
                  onClick={() => {
                    setSelectCity(orderAlphabetically(ubications6, 'title1'));
                    setNameCity('Quintana Roo');
                    setNewMakers(markers);
                    setTagCity({
                      lat: 20.683244212507656,
                      lon: -87.0352685887214,
                      zoom: 9,
                    });
                  }}
                  content='Quintana Roo'
                />
              </CardGroup>
            )}
          </CardContent>
          <CardContent id='cardContentAccordion'>
            {selectCity !== null && (
              <>
                {/* Accordion */}
                {selectCity.map((item: any, key: number) => (
                  <Accordion
                    id='acordionLocation'
                    key={key}
                  >
                    <Accordion.Title
                      onClick={() => {
                        handleClick(item);
                      }}
                    >
                      <CardGroup id='cardLocation'>
                        <CardGroup id='contentHeaderDropdown1'>
                          <Image
                            id='IconUbication'
                            src={LocationMapIcon}
                          />
                          <Header
                            id='dropUbicationHeader'
                            content={item.title1}
                            subheader={item.subTitle2}
                          />
                        </CardGroup>
                        <CardGroup id='contentHeaderDropdown2'>
                          <Header
                            id='dropUbicationHeader2'
                            content={item.title2}
                            subheader={
                              <p id='subheader2Ubication'>{item.site}</p>
                            }
                          />
                          <Icon
                            id='iconDropDown'
                            name={
                              activeIndex === item.number
                                ? 'angle up'
                                : 'angle down'
                            }
                          />
                        </CardGroup>
                      </CardGroup>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === item.number}>
                      <Grid
                        id='gridContentLocation'
                        columns='equal'
                      >
                        <Grid.Column>
                          <p id='textRevealAcordion'>
                            {item.contentLeftColumn}
                          </p>
                        </Grid.Column>
                        <Grid.Column>
                          <p id='textRevealAcordion2'>
                            {item.contentRightColumn}
                          </p>
                        </Grid.Column>
                      </Grid>
                    </Accordion.Content>
                  </Accordion>
                ))}
              </>
            )}
          </CardContent>
        </CardContent>
      </CardGroup>
    </Segment>
  );
};

export default Branch;
