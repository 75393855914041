/* eslint-disable no-param-reassign */

import { APP } from '../../commons/reducer';
import { createAction, createReducer } from '../../utils/reducer';
// Local constants
const PATH = 'shiphmentList/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;
const REPORT_CLEAR = `${PATH}REPORT_CLEAR`;

// Global constants for saga
export const INVOICES = {
  GET_INVOICES_LIST: `${PATH}INVOICES_LIST`,
  GET_INVOICES_LIST_SUCCESS: `${PATH}INVOICES_LIST_SUCCESS`,
  GET_INVOICES_LIST_FAILED: `${PATH}INVOICES_LIST_FAILED`,
};

// actions
export const invoicesActions = {
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  getInvoicesList: createAction(INVOICES.GET_INVOICES_LIST),
  getInvoicesListSuccess: createAction(
    INVOICES.GET_INVOICES_LIST_SUCCESS,
    'list',
  ),
  getInvoicesListFailed: createAction(
    INVOICES.GET_INVOICES_LIST_FAILED,
    'list',
  ),
};

const initialState = {
  controls: {
    errorMessage: '',
    error: false,
    loading: false,
  },
  list: [],
  report: {
    url: null,
    filters: {
      dateFrom: null,
      dateTo: null,
      emails: null,
    },
    success: null,
    loading: false,
  },
};

const invoicesList = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [INVOICES.GET_INVOICES_LIST](state: any) {
    state.controls.loading = true;
  },
  [INVOICES.GET_INVOICES_LIST_SUCCESS](state: any, action: any) {
    state.list = action.list;
    state.controls.loading = false;
  },
  [INVOICES.GET_INVOICES_LIST_FAILED](state: any) {
    state.controls.error = true;
    state.controls.loading = false;
    state.controls.errorMessage = 'Oops, wrong credentials.';
  },
  [REPORT_CLEAR](state: any, action: any) {
    state.report = {
      url: null,
      success: null,
      loading: false,
    };
  },
  [APP.SERVER_FAILED](state: any) {
    state.controls.loading = false;
  },
});

export default invoicesList;
