/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionProps, selectState } from '../../../utils/reduxActions';
import { bookAddressActions } from './reducer';
import paths from '../../../config/paths';
import '../../styles/views.css';
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import Modal from '../../../commons/Components/Modal'
import UpdateAddress from '../UpdateAddress'
import DeleteAddress from '../Delete'
import { updateAddressActions } from '../UpdateAddress/reducer'


interface BookProps {
  actions?: any;
  controls?: any;
  history?: any;
  list?: any[];
  deleteResponse?: any;
  updateResponse?: any
}

interface Address {
  id: string;
  address: {
    address: string;
    zipCode: string;
    state: string;
    colonyName: string
  };
  addressType: string
  nameOfIndividual: string;
  email: string;
  phone: string;
}


const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
    </GridToolbarContainer>
  );
}

const BookAddress: React.FC<BookProps> = (props) => {
  const { actions, controls, list, history, deleteResponse, updateResponse } = props;
  let [isOpenModalUpdate, setIsOpenModalUpdate] = useState(false)
  let [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  const openUpdateModal = () => { setIsOpenModalUpdate(true) }
  const openDeleteModal = () => { setIsOpenModalDelete(true) }

  useEffect(() => {
    actions.getAddresses();
  }, [actions]);

  const updateAddress = (data: Address) => {
    actions.formChanged({ ...data })
    openUpdateModal();
  }
  const deleteAddress = (data: Address) => {
    actions.controlsChanged({ ...data })
    openDeleteModal();
  }

  useEffect(() => {
    if (deleteResponse && deleteResponse.success) {
      setIsOpenModalDelete(false)
      actions.getAddresses();
    }
  }, [deleteResponse, actions]);

  useEffect(() => {
    if (updateResponse && updateResponse.success) {
      setIsOpenModalUpdate(false)
      actions.getAddresses();
    }
  }, [updateResponse, actions]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 230,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold'
    },
    {
      field: 'address.address',
      headerName: 'Dirección',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.address.address
    },
    {
      field: 'address.zipCode',
      headerName: 'Código postal',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 250,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.address.zipCode
    },
    {
      field: 'address.colonyName',
      headerName: 'Colonia',
      type: 'number',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.address.colonyName
    },
    {
      field: 'address.state',
      headerName: 'Estado',
      type: 'string',
      minWidth: 180,
      sortable: false, flex: 1, align: 'center', headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.address.state
    },
    {
      field: 'addressType',
      headerName: 'Tipo',
      type: 'string',
      hide: true,
      minWidth: 180,
      sortable: false, flex: 1, align: 'center', headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
      sortable: false,
      minWidth: 150,
      renderCell: (data) => {
        return (
          <div className="grid grid-cols-2 space-x-2">
            <div className='grid hover:bg-primary-inabit-blue bg-primary-inabit-blue-hover rounded-lg h-12 shadow '>
              <button
                className='text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center bg-blue-500 hover:bg-blue-600'
                onClick={() => updateAddress(data.row)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
              </button>
            </div>
            <div className='grid hover:bg-primary-inabit-blue bg-primary-inabit-blue-hover rounded-lg h-12 shadow '>
              <button
                className='text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center bg-red-500 hover:bg-red-600'
                onClick={() => deleteAddress(data.row)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>
          </div>
        )
      }
    },
  ];

  return (
    <>
      <div className='w-full overflow-auto'>
        <div className='grid relative mb-20'>
          <button className="absolute top-0 right-0 bg-primary-inabit-blue rounded-3xl p-4 shadow-lg text-white" onClick={() => history.push(paths.ADDRESS_BOOK_CREATE)}>Crear dirección</button>
        </div>
        <Modal
          onClose={() => setIsOpenModalUpdate(false)}
          isOpen={isOpenModalUpdate}
        >
          <UpdateAddress />
        </Modal>
        <Modal
          size='small'
          onClose={() => setIsOpenModalDelete(false)}
          isOpen={isOpenModalDelete}
        >
          <DeleteAddress
            onClose={() => setIsOpenModalDelete(false)}
          />
        </Modal>
        <DataGrid
          autoHeight
          localeText={{
            toolbarDensity: 'Tamaño de Columnas',
            toolbarDensityLabel: 'Size',
            toolbarDensityCompact: 'Pequeño',
            toolbarDensityStandard: 'Medio',
            toolbarDensityComfortable: 'Grande',
            toolbarExport: 'Exportar',
            toolbarFilters: 'Filtros',
            toolbarColumns: 'Columnas',
          }}
          rows={list || []}
          columns={columns}
          pageSize={12}
          loading={controls.loading}
          className="rounded-xl"
          components={{ Toolbar: CustomToolbar, }}
          density='comfortable'
          disableSelectionOnClick={true}
        />
      </div>
    </>
  )
}

const withConnect = connect(
  selectState(
    'addressBook.controls',
    'addressBook.list',
    'addressBook.deleteResponse',
    'updateAddress.updateResponse',
  ),
  actionProps({ ...bookAddressActions, ...updateAddressActions }),
);

export default withRouter(withConnect(BookAddress));
