import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';
import { get } from '../utils/api';
import { APP, appActions } from './reducer';

function* getProfileInfo() {
  yield takeLatest(APP.GET_PROFILE_INFO, function* fn(): any {
    try {
      const response = yield get('/me');
      if (response) {
        if (response.error) {
          const data = JSON.parse(response.errorMsg);
          if (data.error.status === 401) {
            localStorage.removeItem('token');
          }
        }
        yield put(appActions.getProfileInfoSucess(response.data));
      } else {
        yield put(appActions.getProfileInfoFailed({ response: response.errorMsg }));
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });
}

export default function* saga(): any {
  yield spawn(getProfileInfo);
}