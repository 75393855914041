const Paths = {
  HOME: '/',
  LOGIN: '/login',
  SIGN_UP: '/sign_up',
  COMPLETE_SIGN_UP: '/complete_sign_up',
  WAITING_FOR_REVIEW: '/waiting_by_review',
  DASHBOARD: '/dashboard',
  QUOTE: '/quote',
  SHIPMENTS: '/shipments',
  CREATE_ORDER: '/orders/create',
  ADDRESS_BOOK: '/address_book',
  ADDRESS_BOOK_CREATE: '/address_book/create',
  ADDRESS_BOOK_UPDATE: '/address_book/update',
  ADDRESS_BOOK_DELETE: '/address_book/delete',
  RECHARGE_CREDITS: '/recharge_credits',
  TRANSACTION_HISTORY:'/transaction-history',
  INVOICE: '/invoice',
  SHOP:'/shop',
  INVOICES: '/invoices',
  WOOCOMMERCE: '/woo-commerce'
};

export default Paths;
