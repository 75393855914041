/* eslint-disable no-param-reassign */

import { APP } from '../../commons/reducer';
import { createAction, createReducer } from '../../utils/reducer';
// Local constants
const PATH = 'shiphmentList/';
const TRANSACTION_PARAMS = `${PATH}TRANSACTION_PARAMS`;

// Global constants for saga
export const TRANSACTION_LIST = {
  GET_TRANSACTION_LIST: `${PATH}TRANSACTION_LIST`,
  GET_TRANSACTION_LIST_SUCCESS: `${PATH}TRANSACTION_LIST_SUCCESS`,
  GET_TRANSACTION_LIST_FAILED: `${PATH}TRANSACTION_LIST_FAILED`,
};

// actions
export const transactionListActions = {
  transactionParams: createAction(TRANSACTION_PARAMS, 'params'),
  getTransactionList: createAction(TRANSACTION_LIST.GET_TRANSACTION_LIST),
  getTransactionListSuccess: createAction(TRANSACTION_LIST.GET_TRANSACTION_LIST_SUCCESS, 'list'),
  getTransactionListFailed: createAction(TRANSACTION_LIST.GET_TRANSACTION_LIST_FAILED, 'list'),
}

const initialState = {
  params: {
    errorMessage: '',
    error: false,
    loading: false,
  },
  list: []
};

const transactionList = createReducer(initialState, {
  [TRANSACTION_PARAMS](state: any, action: any) {
    state.params = {
      ...state.params,
      ...action.params,
    };
  },
  [TRANSACTION_LIST.GET_TRANSACTION_LIST](state: any) {
    state.params.loading = true;
  },
  [TRANSACTION_LIST.GET_TRANSACTION_LIST_SUCCESS](state: any, action: any) {
    state.list = action.list;
    state.params.loading = false;
  },
  [TRANSACTION_LIST.GET_TRANSACTION_LIST_FAILED](state: any) {
    state.params.error = true;
    state.params.loading = false;
    state.params.errorMessage = 'Oops, wrong credentials.';
  },
  [APP.SERVER_FAILED](state: any) {
    state.params.loading = false;
  },
});

export default transactionList;