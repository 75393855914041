import { applyMiddleware, Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import { ENVIRONMENT } from '../utils/constants'

const logger = createLogger();

export const sagaMiddleware = createSagaMiddleware();

const middlewares: (SagaMiddleware<object> | Middleware)[] = [sagaMiddleware];
if (ENVIRONMENT === 'dev') middlewares.push(logger)

export default applyMiddleware(...middlewares);