import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actionProps, selectState } from "../../utils/reduxActions";
import { registerActions } from "./reducer";
import signUpSvg from "../../assets/img/sign-up.svg"
import logoPng from "../../assets/img/logo-blanco.png"
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3"
import "./style.css"
import { RECAPTCHA_API_KEY } from "../../utils/constants";

interface RegisterProps {
  actions?: any;
  controls?: any;
  history?: any;
}

interface SignUpFormInput {
  name: string;
  password: string;
  checkPassword: string;
  contact: {
    email: string;
    phone: string;
  },
  company: {
    name: string;
    approxshipmentsPerMonth: string
    activity: string;
    howHelpYou: string;
    typeOfServiceToRequire: string
  },
  ReCaptchaToken: string
}
// const phoneRegExp = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/gm
const phoneRegExp = /^\d{10}$/g
const schema = yup.object({
  name: yup.string()
    .trim().min(4, "Debe contener por lo menos 4 caracteres")
    .required("Campo obligatorio"),
  password: yup.string()
    .trim().min(8, "Debe contener por lo menos 8 caracteres")
    .required("Campo obligatorio"),
  checkPassword: yup.string()
    .trim().min(8, "Debe contener por lo menos 8 caracteres")
    .oneOf([yup.ref("password"), null], "La contraseña debe coincidir")
    .required("Campo obligatorio"),
  contact: yup.object({
    email: yup.string()
      .email("Correo electrónico no valido")
      .required("Campo obligatorio"),
    phone: yup.string()
      .trim()
      .matches(phoneRegExp, "Número no valido, ej. 5532990834")
      .required("Campo obligatorio"),
  }),
  company: yup.object({
    name: yup.string()
      .trim().min(4, "Debe contener por lo menos 4 caracteres")
      .required("Campo obligatorio"),
    approxshipmentsPerMonth: yup.string()
      .trim().min(2, "Debe seleccionar una opción")
      .required("Campo obligatorio"),
    activity: yup.string()
      .trim().min(8, "Debe seleccionar una opción")
      .required("Campo obligatorio"),
    howHelpYou: yup.string()
      .trim().min(8, "Debe seleccionar una opción")
      .required("Campo obligatorio"),
    typeOfServiceToRequire: yup.string()
      .trim().min(4, "Debe seleccionar una opción")
      .required("Campo obligatorio"),
  })
}).required();

const Register: React.FC<RegisterProps> = (props) => {
  const { actions, history } = props;
  const [formStepsNum, setFormStepsNum] = useState(1)
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<SignUpFormInput>({
    resolver: yupResolver(schema)
  });
  useEffect(() => {
    const errorsInFormStep1 = ["name", "checkPassword", "contact", "password"].filter(x => Object.keys(errors).includes(x));
    if (errorsInFormStep1.length > 0) setFormStepsNum(1);
  }, [errors])

  const nextform = () => {
    if (formStepsNum >= 2) {
      setFormStepsNum(formStepsNum)
    } else {
      setFormStepsNum(formStepsNum + 1)
    }
  }

  const prevForm = () => {
    if (formStepsNum <= 1) {
      setFormStepsNum(formStepsNum)
    } else {
      setFormStepsNum(formStepsNum - 1)
    }
  }

  const onRegister = (data: SignUpFormInput) => actions.register({ ...data, history });

  return (
    <>
      <div className="h-screen min-h-screen max-h-screen flex justify-center items-center p-4 w-full m-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:w-400 lg:w-400 shadow-2xl rounded-xl">
          <div className="grid bg-primary-inabit-purple md:rounded-l-xl">
            <div className="grid place-content-center">
              <img src={logoPng} alt="logo" className="m-auto" width={200} />
            </div>
            <div className="grid pb-8">
              <img src={signUpSvg} alt="logo" className="object-center m-auto" width={800} />
            </div>
          </div>
          <div className="grid shadow-2xl bg-white md:rounded-r-xl py-8 px-6">
            <div className="grid h-12">
              <p className="text-center tracking-tighter text-3xl font-bold">Bienvenido a Inabit! <br /> <span className="text-xl text-gray-400">Registra tu empresa</span></p>
            </div>
            <div className="grid">
              <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_API_KEY}>
                <form onSubmit={handleSubmit(onRegister)} className="px-4 w-full" id="forms">
                  <div className="progressbar">
                    <div className={`progress ${formStepsNum > 1 ? "w-full" : "w-0"}`} id="progress"></div>
                    <div className={`progress-step ${formStepsNum === 1 ? "progress-step-active" : "progress-step-active progress-step-check"}`} data-title="Cuenta"></div>
                    <div className={`progress-step ${formStepsNum === 2 ? "progress-step-active" : ""}`} data-title="Empresa"></div>
                  </div>
                  <div className={`step-forms ${formStepsNum === 1 ? "step-forms-active" : ""}`}>
                    <div className="mb-4">
                      <div className="grid">
                        <input
                          type="text"
                          {...register("name", { required: true })}
                          className={`w-full p-3 border-2 text-lg py-2 px-4 rounded-lg focus:outline-none ${errors.name ? "border-red-600" : ""}`}
                          placeholder="Nombre y apellido"
                        />
                        <span className="text-red-600 text-xs font-semibold h-5">{errors.name?.message}</span>
                      </div>
                      <div className="grid">
                        <input
                          type="email"
                          {...register("contact.email", { required: true })}
                          className={`w-full p-3 border-2 text-lg py-2 px-4 rounded-lg focus:outline-none ${errors.contact?.email ? "border-red-600" : ""}`}
                          placeholder="Correo electrónico"
                        />
                        <span className="text-red-600 text-xs font-semibold h-5">{errors.contact?.email?.message}</span>
                      </div>
                      <div className="grid">
                        <input
                          type="text"
                          {...register("contact.phone", { required: true })}
                          className={`w-full p-3 border-2 text-lg py-2 px-4 rounded-lg focus:outline-none ${errors.contact?.phone ? "border-red-600" : ""}`}
                          placeholder="Número de teléfono"
                        />
                        <span className="text-red-600 text-xs font-semibold h-5">{errors.contact?.phone?.message}</span>
                      </div>
                      <div className="grid">
                        <input
                          type="password"
                          {...register("password", { required: true })}
                          className={`w-full p-3 border-2 text-lg py-2 px-4 rounded-lg focus:outline-none ${errors.password ? "border-red-600" : ""}`}
                          placeholder="Contraseña (min 8 caracteres)"
                        />
                        <span className="text-red-600 text-xs font-semibold h-5">{errors.password?.message}</span>
                      </div>
                      <div className="grid">
                        <input
                          type="password"
                          {...register("checkPassword", { required: true })}
                          className={`w-full p-3 border-2 text-lg py-2 px-4 rounded-lg focus:outline-none ${errors.checkPassword ? "border-red-600" : ""}`}
                          placeholder="Confirmar contraseña (min 8 caracteres)"
                        />
                        <span className="text-red-600 text-xs font-semibold h-5">{errors.checkPassword?.message}</span>
                      </div>
                    </div>
                    <div className="grid col-end-1 place-items-end">
                      <input type="button" className="w-1/2 rounded-lg p-4 bg-primary-inabit-blue text-white text-lg hover:bg-primary-inabit-blue-hover" onClick={nextform} value="Siguiente" />
                    </div>
                  </div>
                  <div className={`step-forms ${formStepsNum === 2 ? "step-forms-active" : ""}`}>
                    <div className="mb-4">
                      <div className="grid">
                        <input type="text"
                          {...register("company.name", { required: true })}
                          className={`w-full p-3 border-2 text-lg py-2 px-4 rounded-lg focus:outline-none ${errors.company?.name ? "border-red-600" : ""}`}
                          placeholder="Nombre de la empresa"
                        />
                        <span className="text-red-600 text-xs font-semibold h-5">{errors.company?.name?.message}</span>
                      </div>
                      <div className="grid">
                        <select
                          className={`w-full border-2 text-lg p-2 px-4 rounded-lg ${errors.company?.approxshipmentsPerMonth ? "border-red-600" : ""}`}
                          {...register("company.approxshipmentsPerMonth")}
                        >
                          <option hidden value="">¿Cuántos pedidos realizas por mes?</option>
                          {
                            approxshipmentsPerMonth.map((option) => (
                              <option value={option.value} key={option.key}>{option.text}</option>
                            ))
                          }
                        </select>
                        <span className="text-red-600 text-xs font-semibold h-5">{errors.company?.approxshipmentsPerMonth?.message}</span>
                      </div>
                      <div className="grid">
                        <select
                          className={`w-full border-2 text-lg p-2 px-4 rounded-lg ${errors.company?.activity ? "border-red-600" : ""}`}
                          {...register("company.activity")}
                        >
                          <option hidden value="">¿Qué tipo de negocio tienes?</option>
                          {
                            options2.map((option) => (
                              <option value={option.value} key={option.key}>{option.text}</option>
                            ))
                          }
                        </select>
                        <span className="text-red-600 text-xs font-semibold h-5">{errors.company?.activity?.message}</span>
                      </div>
                      <div className="grid">
                        <select
                          className={`w-full border-2 text-lg p-2 px-4 rounded-lg ${errors.company?.howHelpYou ? "border-red-600" : ""}`}
                          {...register("company.howHelpYou")}
                        >
                          <option hidden value="">¿Cómo te podemos ayudar?</option>
                          {
                            howHelpYou.map((option) => (
                              <option value={option.value} key={option.key}>{option.text}</option>
                            ))
                          }
                        </select>
                        <span className="text-red-600 text-xs font-semibold h-5">{errors.company?.howHelpYou?.message}</span>
                      </div>
                      <div className="grid">
                        <select
                          className={`w-full border-2 text-lg p-2 px-4 rounded-lg ${errors.company?.typeOfServiceToRequire ? "border-red-600" : ""}`}
                          {...register("company.typeOfServiceToRequire")}
                        >
                          <option hidden value="">Tipo de servicios que estás buscando</option>
                          {
                            typeOfServiceToRequire.map((option) => (
                              <option value={option.value} key={option.key}>{option.text}</option>
                            ))
                          }
                        </select>
                        <span className="text-red-600 text-xs font-semibold h-5">{errors.company?.typeOfServiceToRequire?.message}</span>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 lg:space-x-2">
                      <div className="grid my-2">
                        <input type="button" className="w-full rounded-lg p-4 bg-gray-400 text-white text-lg hover:bg-gray-500" onClick={prevForm} value="Regresar" />
                      </div>
                      <div className="grid my-2">
                        <input className="w-full rounded-lg p-4 bg-primary-inabit-blue text-white text-lg hover:bg-primary-inabit-blue-hover" type="submit" value="Registrar" id="submit-form" />
                      </div>
                    </div>
                  </div>
                </form>
                <GoogleReCaptcha onVerify={token => setValue("ReCaptchaToken", token)} />
              </GoogleReCaptchaProvider>
            </div>
            <div className="text-xl text-center mt-4">
              <p>
                Ya tienes cuenta?{" "}
                <a className="text-blue-500" href="/login">
                  Inicia sesión!
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

const approxshipmentsPerMonth = [
  { key: "1-10", value: "1-10", text: "1-10" },
  { key: "11-50", value: "11-50", text: "11-50" },
  { key: "51-250", value: "51-250", text: "51-250" },
  { key: "Más de 250", value: "Más de 250", text: "Más de 250" },
]
const options2 = [
  { key: "Ecommerce", value: "Ecommerce", text: "Ecommerce" },
  { key: "Refacciones", value: "Refacciones", text: "Refacciones" },
  { key: "Refrigerados", value: "Refrigerados", text: "Refrigerados" },
  { key: "Documentos / Paquetería general", value: "Documentos / Paquetería general", text: "Documentos / Paquetería general" }
]
const howHelpYou = [
  { key: "Envíos en México", value: "Envíos en México", text: "Envíos en México" },
  { key: "Exportacion USA", value: "Exportacion USA", text: "Exportacion USA" },
  { key: "Importacion y Exportacion USA", value: "Importacion y Exportacion USA", text: "Importacion y Exportacion USA" },
  { key: "Envíos a todo el mundo", value: "Envíos a todo el mundo", text: "Envíos a todo el mundo" },
]
const typeOfServiceToRequire = [
  { key: "Económico", value: "Económico", text: "Económico" },
  { key: "Dia siguiente", value: "Dia siguiente", text: "Dia siguiente" },
  { key: "Internacional", value: "Internacional", text: "Internacional" },
]

const withConnect = connect(
  selectState("register.controls"),
  actionProps({ ...registerActions }),
);

export default withRouter(withConnect(Register));
