import React from 'react';
import {
  CardContent,
  Segment,
  CardGroup,
  Header,
  Button,
  Image,
  Grid
} from 'semantic-ui-react';

//Images
import MiniLogo from '../../../assets/miniLogo.svg';

interface FooterProps { widthScreen?: any; }

const Footer: React.FC<FooterProps> = (props) => {
  const { widthScreen } = props;

  const redirectUrl = (socialNetwork: string) => {
    if (socialNetwork === 'linkedin') {
      window.location.href = 'https://www.linkedin.com/company/in-a-bit-mexico/'
    }
    if (socialNetwork === 'facebook') {
      window.location.href = 'https://www.facebook.com/InaBitEnvios'
    }

  }
  return (
    <>
      {widthScreen > 503 && <Segment id='section7'>
        <Grid columns={widthScreen < 714 ? 2 : 3} divided>
          <Grid.Row>
            <Grid.Column>
              <Image id='miniLogoFooter' src={MiniLogo} />
            </Grid.Column>
            <Grid.Column>
            </Grid.Column>
            <Grid.Column>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <br />
              <br />
              <Header id='titleFooter' content='Síguenos' />
              <br />
              <CardGroup>
                <Button color='facebook' icon='facebook' onClick={() => { redirectUrl('facebook'); }} circular />
                <Button color='orange' icon='instagram' circular />
                <Button color='linkedin' icon='linkedin' onClick={() => { redirectUrl('linkedin'); }} circular />
                <Button color='green' icon='whatsapp' circular />
              </CardGroup>
              <br />
              <br />
              <br />
              <br />
              <p>© In a bit 2021</p>
            </Grid.Column>
            <Grid.Column>
              <br />
              <Header id='titleFooter' content='Contáctanos' />
              <p>(+52) 442 223 0688</p>
              <p>ventas@inabitmexico.com</p>
              <br />
              <br />
              <Header id='titleFooter' content='Ubícanos' />
              <p>MATRIZ QUERÉTARO</p>
              <p>Bernardo Quintana 161
                Col. Loma Dorada C.P. 76060
                Querétaro, Qro</p>
              <br />
              <p>Contamos con 17 sucursales</p>
            </Grid.Column>
            <Grid.Column>
              <br />
              <Header id='titleFooter' content='Servicio' />
              <br />
              <p>Servicio de Express (24 horas) y Económico (24-96 Hrs)</p>
              <p>Documentando en cualquiera de nuestras sucursales antes de las 12:00 del día.</p>
              <br />
              <br />
              <Header id='titleFooter' content='Soporte' />
              <p>ventas@inabitmexico.com</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Segment>}


      {/* Iphone Screen */}

      {widthScreen < 503 && <Segment id='section7'>
        <Grid columns={3} divided>
          <Grid.Row>
            <Image id='miniLogoFooter' src={MiniLogo} />
          </Grid.Row>
          <Grid.Row>
            <CardContent id='cardInfoFooter'>
              <Header id='titleFooter' content='Síguenos' />
              <Button.Group>
                <Button color='facebook' icon='facebook' circular />
                <Button color='orange' icon='instagram' circular />
                <Button color='linkedin' icon='linkedin' circular />
                <Button color='green' icon='whatsapp' circular />
              </Button.Group>
            </CardContent>
            <CardContent id='cardInfoFooter'>
              <br />
              <Header id='titleFooter' content='Contáctanos' />
              <p>(+52) 442 223 0688</p>
              <p>ventas@bitenvios.com</p>
              <Header id='titleFooter' content='Ubícanos' />
              <p>QRO Bernardo Quintana</p>
              <p>Bernardo Quintana 161, Loma Dorada</p>
              <p>Tenemos 15 sucursales</p>
            </CardContent>

            <CardContent id='cardInfoFooter'>
              <br />
              <Header id='titleFooter' content='Servicio' />
              <p>Servicio de 48-72 Hrs</p>
              <p>Documentando en cualquiera de nuestras sucursales antes de las 12:00 del día.</p>
            </CardContent>

            <CardContent id='cardInfoFooter'>
              <br />
              <Header id='titleFooter' content='Soporte' />
              <p>ventas@bitenvios.com</p>
              <p>© In a bit 2021</p>
            </CardContent>
          </Grid.Row>
        </Grid>
      </Segment>}
    </>

  );
}

export default Footer;