import { createStore, compose, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createContext } from 'react';

// project
import { rootSaga } from './configSaga';
import enhancers, { sagaMiddleware } from './middleware';

// reducers
import app from '../commons/reducer';
import login from '../views/Login/reducer';
import register from '../views/Register/reducer';
import calculator from '../views/calculator/reducer';
import addressBook from '../views/Address/AddressBook/reducer';
import createAddress from '../views/Address/CreateAddress/reducer';
import updateAddress from '../views/Address/UpdateAddress/reducer';
import generateShipment from '../views/GenerateShipment/reducer';
import rechargeCredits from '../views/RechargeCredits/reducer';
import stripeRechargeCredits from '../views/RechargeCredits/Stripe/reducer';
import paypalRecharge from '../views/RechargeCredits/Paypal/reducer';
import speiRecharge from '../views/RechargeCredits/Spei/reducer';
import completeSignUp from '../views/CompleteSignUp/reducer';
import shipmentList from '../views/Shipments/reducer';
import invoicesList from '../views/Invoices/reducer';
import transactionList from '../views/TransactionHistory/reducer';
import publicCalculator from '../views/Home/components/publicCalculator/reducer';
import invoice from '../commons/Components/Facturama/reducer';
import home from '../views/Home/reducer';
import shop from '../views/Shop/reducer';
import supportPayment from '../commons/Components/SupportPayment/reducer';
import wooCommerce from '../views/WooCommerce/reducer';

export const ReactReduxContext = createContext({});

export default function configureStore(initialState = {}) {
  // create reducers
  const reducers = combineReducers({
    app,
    home,
    register,
    login,
    calculator,
    addressBook,
    createAddress,
    updateAddress,
    generateShipment,
    rechargeCredits,
    stripeRechargeCredits,
    supportPayment,
    paypalRecharge,
    speiRecharge,
    completeSignUp,
    shipmentList,
    invoicesList,
    publicCalculator,
    transactionList,
    invoice,
    shop,
    wooCommerce
  });

  const store = createStore(
    reducers, initialState, composeWithDevTools(compose(enhancers)),
  );

  // run sagas
  sagaMiddleware.run(rootSaga);
  return store;
}
