import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../commons/reducer';
import { post } from '../../utils/api';
import { homeActions, HOME } from './reducer';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

function* auth() {
  yield takeLatest(HOME.CONTACT_US, function* fn(action: any): any {
    const body = {
      name: action.controls.name,
      phoneNumber: action.controls.phone,
      email: action.controls.email,
      subject: action.controls.subject,
      detail: action.controls.detail,
      aceptTermsAndConditions: action.controls.acceptTermAndConditions,
      ReCaptchaToken: action.controls.ReCaptchaToken
    };
    try {
      const response = yield post('/contact-us', body);
      if (response.error) {
        yield put(homeActions.contactUsFailed({ response: response.errorMsg }));
        const msgError = JSON.parse(response.errorMsg).error.message.split(':')[1]
        MySwal.fire({
          icon: 'error',
          title: `Ooops, tenemos problemas`,
          text: msgError,
        })
      } else {
        yield put(homeActions.contactUsSuccess(response));
        MySwal.fire({
          icon: 'success',
          title: `MENSAJE ENVIADO EXITOSAMENTE`,
          text: 'Gracias por escribirnos, uno de nuestros asesores se pondrá en contacto contigo tan pronto como sea posible.',
        })
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });
}
export default function* saga(): any {
  yield spawn(auth);
}