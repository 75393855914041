import React from 'react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { BellIcon } from '@heroicons/react/outline'
import paths from '../../config/paths'

interface NavbarProps {
  children?: any
  history?: any
}

const Navbar: React.FC<NavbarProps> = (props): JSX.Element => {
  const { history } = props

  const logOut = () => {
    localStorage.removeItem('token');
    history.push(paths.HOME)
  }

  return (
    <>
      <nav className="top-0 left-0 z-10 md:flex-row md:flex-wrap md:justify-start flex items-center pt-1 pb-8">
        <div className="hidden md:flex bg-primary-inabit-coffe w-full rounded-3xl mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4 py-6">
          <a
            className="text-white text-sm uppercase hidden lg:inline-block font-semibold"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            Dashboard
          </a>
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                <button type="button" className="bg-transparent p-1 rounded-full text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-8 w-8" aria-hidden="true" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                      <img className="h-8 w-8 rounded-full" src={require("../../assets/img/profile.jpg").default} alt="" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute z-40 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {/* {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))} */}
                      <Menu.Item >
                        <button
                          className={' w-full bg-gray-100 block px-4 py-2 text-sm text-gray-700'}
                          onClick={logOut}
                        >
                          Salir
                        </button>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </ul>
        </div>
      </nav >
    </>
  )
}

export default Navbar;
