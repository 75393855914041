import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../../../commons/reducer';
import { post } from '../../../../utils/api';
import { publicCalculatorActions, PUBLIC_CALCULATOR } from './reducer';

function* getQuote() {
  yield takeLatest(PUBLIC_CALCULATOR.GET_QUOTE, function* fn(action: any): any {
    const { calculatorControls } = action
    const body = {
      clientAddrOrig: {
        zipCode: calculatorControls.clientAddrOrig.zipCode,
        colonyName: calculatorControls.clientAddrOrig.colonyName,
      },
      clientAddrDest: {
        zipCode: calculatorControls.clientAddrDest.zipCode,
        colonyName: calculatorControls.clientAddrDest.colonyName,
      },
      shipmentDetails: [...calculatorControls.shipmentPktDetails]
    };
    try {
      const response = yield post('/shipping/quote', body);
      if (response.error) {
        yield put(publicCalculatorActions.publicCalculatorFailed({ response: response.errorMsg }));
      } else {
        yield put(publicCalculatorActions.publicCalculatorSuccess(response));
      }
    } catch (error) {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  }
  );
}

export default function* saga(): any {
  yield spawn(getQuote);
}