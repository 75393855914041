import { APP } from '../../../commons/reducer';
import { createAction, createReducer } from '../../../utils/reducer';
// Local constants
const PATH = 'updateAddress/';
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const UPDATE_ADDRESS = {
  UPDATE_ADDRESS: `${PATH}UPDATE_ADDRESS`,
  UPDATE_ADDRESS_SUCCESS: `${PATH}UPDATE_ADDRESS_SUCCESS`,
  UPDATE_ADDRESS_FAILED: `${PATH}UPDATE_ADDRESS_FAILED`,
};

// actions
export const updateAddressActions = {
  formChanged: createAction(CONTROLS_CHANGED, 'form'),
  updateAddress: createAction(UPDATE_ADDRESS.UPDATE_ADDRESS, 'form'),
  updateAddressSuccess: createAction(UPDATE_ADDRESS.UPDATE_ADDRESS_SUCCESS, 'updateResponse'),
  updateAddressFailed: createAction(UPDATE_ADDRESS.UPDATE_ADDRESS_FAILED, 'updateResponse'),
};

const initialState = {
  form: {
    addressType: null,
    nameOfIndividual: null,
    email: null,
    phone: null,
    companyName: null,
    address: {
      address: null,
      interiorNumber: null,
      zipCode: null,
      colonyName: null,
      city: null,
      state: null,
      country: 'México',
    },
    error: false,
    loading: false,
  },
  updateResponse: null
};

const updateAddress = createReducer(initialState, {
  [CONTROLS_CHANGED](state: any, action: any) {
    state.form = {
      ...state.form,
      ...action.form,
    };
  },
  [UPDATE_ADDRESS.UPDATE_ADDRESS](state: any) {
    state.form.loading = true;
  },
  [UPDATE_ADDRESS.UPDATE_ADDRESS_SUCCESS](state: any, action: any) {    
    state.form.addressType = null;
    state.form.nameOfIndividual = null;
    state.form.email = null;
    state.form.phone = null;
    state.form.companyName = null;
    state.form.address = {
      address: null,
      interiorNumber: null,
      zipCode: null,
      colonyName: null,
      city: null,
      state: null,
      country: 'México',
    };
    state.form.error = false;
    state.form.loading = false;
    state.updateResponse = action.updateResponse
  },
  [UPDATE_ADDRESS.UPDATE_ADDRESS_FAILED](state: any, action: any) {
    state.form.error = true;
    state.form.loading = false;
    state.updateResponse = action.updateResponse
  },
  [APP.SERVER_FAILED](state: any) {
    state.form.loading = false;
  },
});

export default updateAddress;