import * as React from 'react';

const originalWidth = 48.907;
const originalHeight = 57.933;
const aspectRatio = originalWidth / originalHeight;

const UPSSvg = ({ width = 48.907, height = 57.933, id = '' }) => (
  <div
    style={{ width, height, backgroundColor: '#FFFFFF00' }}
    id={id}
  >
    <div style={{ width: '100%', aspectRatio, backgroundColor: '' }}>
      <svg
        width='100%'
        height='100%'
        viewBox={`0 0 ${originalWidth} ${originalHeight}`}
        preserveAspectRatio='xMaxYMid meet'
        fill='none'
      >
        <g
          id='Grupo_5594'
          data-name='Grupo 5594'
          transform='translate(-146.898 -3440.862)'
        >
          <path
            id='Trazado_7575'
            data-name='Trazado 7575'
            d='M3384.608,620.5c0,4.778.013,9.556-.005,14.333a18.284,18.284,0,0,1-1.452,6.987,17.921,17.921,0,0,1-4.046,6.016,16.037,16.037,0,0,1-3.224,2.44c-2.6,1.5-5.224,2.936-7.911,4.263-2.47,1.22-4.984,2.346-7.554,3.341a.607.607,0,0,1-.478.006c-2.07-.823-4.12-1.694-6.138-2.639q-2.21-1.035-4.372-2.17c-2.054-1.084-4.076-2.223-6.035-3.47a14.284,14.284,0,0,1-2.835-2.438,18.234,18.234,0,0,1-4.5-9.019,18.951,18.951,0,0,1-.307-3.837c.039-4.139.021-8.277-.008-12.416-.018-2.518-.039-5.037-.017-7.555.013-1.573.017-3.148.051-4.721.028-1.286.074-2.571.107-3.857a.514.514,0,0,1,.3-.495,37.645,37.645,0,0,1,7.953-3.216,51.089,51.089,0,0,1,6.292-1.328q1.853-.279,3.72-.443a66.719,66.719,0,0,1,6.961-.278c1.352.02,2.7.066,4.052.167q2.158.162,4.3.475a51.559,51.559,0,0,1,6.958,1.507,43.459,43.459,0,0,1,6.575,2.5c.476.228.943.47,1.416.7a.322.322,0,0,1,.207.339q-.012,1.93-.012,3.86,0,5.472,0,10.944Zm-2.312.5h.033c0-1.491,0-2.981,0-4.472-.008-1.916-.025-3.832-.036-5.748-.009-1.5-.008-3-.054-4.5-.008-.3-.009-.3-.3-.325l-.083-.007c-.915-.055-1.829-.115-2.744-.162-1.572-.081-3.145-.13-4.718-.161-1.259-.025-2.519-.007-3.777.026q-1.528.04-3.051.142c-1.247.085-2.49.2-3.729.365-1.028.135-2.051.294-3.07.482-1.411.261-2.8.6-4.187.968a43.673,43.673,0,0,0-8.076,3,45.081,45.081,0,0,0-6.026,3.541,45.951,45.951,0,0,0-4.295,3.384.4.4,0,0,0-.154.331c0,.685-.025,1.369-.028,2.054q-.012,2.111-.011,4.222,0,4.93,0,9.861a20.21,20.21,0,0,0,.213,3.18,16.537,16.537,0,0,0,1.635,5.045,14.547,14.547,0,0,0,5.2,5.723,66.673,66.673,0,0,0,5.992,3.384c2.375,1.187,4.777,2.318,7.175,3.456a6.38,6.38,0,0,0,1.471.571,2.1,2.1,0,0,0,1.383-.134c1.012-.5,2.044-.957,3.069-1.429,2.27-1.044,4.541-2.083,6.748-3.255a50.9,50.9,0,0,0,4.554-2.713,15.056,15.056,0,0,0,2.609-2.148,15.536,15.536,0,0,0,3.575-6.289,17.628,17.628,0,0,0,.7-5.369c-.035-2.277-.012-4.554-.013-6.832Q3382.295,624.1,3382.3,621Z'
            transform='translate(-3188.819 2840.862)'
            fill='#242321'
          />
          <path
            id='Trazado_7576'
            data-name='Trazado 7576'
            d='M3460.416,735.657c0-2.574-.007-5.147,0-7.721.007-1.731.04-3.461.063-5.191,0-.3.025-.592.024-.887a.219.219,0,0,1,.156-.228,15.033,15.033,0,0,1,3.5-1.057,10.853,10.853,0,0,1,2.073-.163,7.959,7.959,0,0,1,3.9,1.04,6.678,6.678,0,0,1,2.167,2.115,10.046,10.046,0,0,1,1.447,3.7,17.467,17.467,0,0,1,.239,4.339,15.648,15.648,0,0,1-.524,3.364,9.6,9.6,0,0,1-1.842,3.636,5.945,5.945,0,0,1-3.942,2.208,12.652,12.652,0,0,1-2.549.056c-.437-.034-.442-.027-.444.419,0,1.555,0,3.109-.008,4.663-.006,1.166-.026,2.332-.042,3.5,0,.223-.007.222-.209.217-1.185-.029-2.37-.033-3.555.01-.382.014-.389.013-.392-.377-.012-1.2-.01-2.407-.03-3.61C3460.393,742.34,3460.407,739,3460.416,735.657Zm4.261-4.329c0,.731,0,1.462,0,2.193q.006,1.778.018,3.555c0,.247,0,.256.253.292a4.346,4.346,0,0,0,2.136-.168,2.932,2.932,0,0,0,1.926-2.03,15.936,15.936,0,0,0,.612-3.546,17.586,17.586,0,0,0-.59-5.716,2.514,2.514,0,0,0-1.51-1.733,4.51,4.51,0,0,0-2.508-.247c-.256.04-.256.047-.264.321-.022.8-.054,1.591-.063,2.387C3464.666,728.2,3464.623,729.764,3464.676,731.328Z'
            transform='translate(-3294.177 2739.123)'
            fill='#242321'
          />
          <path
            id='Trazado_7577'
            data-name='Trazado 7577'
            d='M3376.562,732.782c-.012,1.892-.017,4.188-.045,6.484-.009.684-.026,1.369-.034,2.054a.391.391,0,0,1-.2.36,10.187,10.187,0,0,1-3.545,1.46,10.573,10.573,0,0,1-3.091.214,6.88,6.88,0,0,1-3.671-1.282,5,5,0,0,1-1.889-2.862,8.281,8.281,0,0,1-.152-1.35c-.117-1.43-.175-2.862-.2-4.3-.013-.852-.028-1.7-.01-2.554.025-1.222.014-2.444.053-3.665.04-1.24.032-2.48.038-3.721,0-.074,0-.148,0-.222,0-.207,0-.212.221-.215,1.269-.014,2.538-.015,3.806.018.226.006.231.011.229.272-.007,1-.021,2-.023,3q-.008,2.472-.006,4.944c0,1.62-.013,3.241.005,4.861a5.729,5.729,0,0,0,.359,2.177c.046.111.1.218.149.329a1.368,1.368,0,0,0,.97.8,3.178,3.178,0,0,0,1.349.039,9.276,9.276,0,0,0,1.133-.266c.228-.066.228-.068.229-.3,0-.722.01-1.444.01-2.166q0-5.069,0-10.138c0-1.064-.024-2.128-.037-3.192,0-.028,0-.056,0-.083,0-.227,0-.224.221-.245.609-.058,1.221-.046,1.831-.051.667-.006,1.334.006,2,.013.209,0,.214.007.218.216.008.528.005,1.055.018,1.583C3376.56,727.454,3376.538,729.916,3376.562,732.782Z'
            transform='translate(-3212.48 2736.783)'
            fill='#242321'
          />
          <path
            id='Trazado_7578'
            data-name='Trazado 7578'
            d='M3554.168,736.259a.534.534,0,0,1,.317.1,19.512,19.512,0,0,0,1.968.9,5,5,0,0,0,1.982.341,3.015,3.015,0,0,0,1.249-.339,1.709,1.709,0,0,0,.786-.9,2.663,2.663,0,0,0-.257-2.495,3.032,3.032,0,0,0-1.231-1.128,13.062,13.062,0,0,1-3.4-2.47,6.285,6.285,0,0,1-1.477-2.325,4.845,4.845,0,0,1-.19-2.327,6.4,6.4,0,0,1,.481-1.788,5.159,5.159,0,0,1,2.34-2.4,7.222,7.222,0,0,1,3.868-.786,7.1,7.1,0,0,1,3.48,1.1.331.331,0,0,1,.2.3c.008,1.129.026,2.258.04,3.387,0,.035-.006.071-.009.107-.1.017-.139-.056-.2-.1a9.123,9.123,0,0,0-2.234-1.219,3.929,3.929,0,0,0-2.4-.12,1.83,1.83,0,0,0-1.341,1.174,2.222,2.222,0,0,0,.672,2.46c.62.472,1.277.887,1.937,1.3a19.68,19.68,0,0,1,2.012,1.385,5.509,5.509,0,0,1,2.079,3.345,6.315,6.315,0,0,1-1.623,5.773,4.97,4.97,0,0,1-2.58,1.387,8.528,8.528,0,0,1-5.979-.773.807.807,0,0,1-.413-.489,3.486,3.486,0,0,1-.187-1.145C3554.049,737.768,3554.179,737.026,3554.168,736.259Z'
            transform='translate(-3373.133 2738.939)'
            fill='#242321'
          />
        </g>
      </svg>
    </div>
  </div>
);
export default UPSSvg;
