import React, { useState, useEffect } from 'react';
import {
  CardContent,
  CardGroup,
  Header,
  Button,
  Image
} from 'semantic-ui-react';

//Banners
import Banner4 from '../../../assets/banner/banner4.svg';
import Banner7 from '../../../assets/banner/banner7.svg';
import Banner10 from '../../../assets/banner/banner10.svg';
import Banner12 from '../../../assets/banner/banner12.svg';
interface CarouselProps {
  widthScreen?: any;
}

const Carousel: React.FC<CarouselProps> = (props) => {
const {widthScreen} = props;
  

  const [moveCarousel, setmoveCarousel] = useState({
    initial: 0,
    final: 1
  });
  useEffect(() => {

  }, [moveCarousel])


//Carousel Function

const adavnceCarousel = () => {
  let carouselLeght = promo.length;
  if (moveCarousel.final !== carouselLeght) {
    setmoveCarousel({
      initial: moveCarousel.initial + 1,
      final: moveCarousel.final + 1
    })
  }
}

  const retroCarousel = () => {
    if (moveCarousel.initial !== 0) {
      setmoveCarousel({
        initial: moveCarousel.initial - 1,
        final: moveCarousel.final - 1
      })
    }
  }

  const promo = [
    { image: Banner4,
      title: 'Envía hasta 20kg',
      description:'Envío terrestre a USA por $1,510.00 pesos'
     },
    { image: Banner7,
      title: 'Envía hasta 3kgs',
      description:'Envía comida mexicana a tu familia en USA por $1,140.00 pesos'
     },
    { image: Banner10,
      title: 'Envía a Europa',
      description:'Envía un sobre por $810.00 pesos'
     },
    { image: Banner12,
      title: 'Compras en USA?',
      description:'Pide a nuestra dirección en USA y lo llevamos a tu casa en México.'
     }
  ]


  return (
   <>
   <CardContent id='section2'>
        <CardGroup id='headerBarCarousel'>
          {widthScreen <= 700 &&<CardContent id='containerBtn'>
            <Header content='Últimas Promociones' id='carouselTitle' />
            <Button floated='right' id='btnCarusel' icon='arrow right' disabled={moveCarousel.final === promo.length ? true : false} onClick={() => adavnceCarousel()} />
            <Button floated='right' id='btnCarusel' icon='arrow left' disabled={moveCarousel.initial === 0 ? true : false} onClick={() => retroCarousel()} />
          </CardContent >}
          {widthScreen > 700 &&<CardContent id='containerBtn'>
            <Header content='Últimas Promociones' id='carouselTitle' />
            <Button floated='right' id='btnCarusel' icon='arrow right' disabled onClick={() => adavnceCarousel()} />
            <Button floated='right' id='btnCarusel' icon='arrow left' disabled onClick={() => retroCarousel()} />
          </CardContent >}
        </CardGroup>
        {widthScreen > 700 &&
        <CardGroup id='carousel'>
          {promo.map((item, key) => (
            <CardContent key={key} id='cardBanner' >
              <Image src={item.image} />
              <CardContent id='containerTextBanner'>
              <p id='titleImgCarousel'>{item.title}</p>
              <p id='descriptionImgCarousel'>{item.description}</p>
              </CardContent>
            </CardContent>))}
        </CardGroup>}
        {widthScreen <= 700 &&
        <CardGroup id='carousel'>
          {promo.slice(moveCarousel.initial, moveCarousel.final).map((item, key) => (
            <CardContent key={key} id='cardBanner' >
              <Image src={item.image} />
              <CardContent id='containerTextBanner'>
              <p id='titleImgCarousel'>{item.title}</p>
              <p id='descriptionImgCarousel'>{item.description}</p>
              </CardContent>
            </CardContent>))}
        </CardGroup>}
      </CardContent>
      <br />
      <br />
      <br />
      <br />
   </>
  );
}

export default Carousel;