import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../commons/reducer';
import { post } from '../../utils/api';
import { completeSignUpActions, COMPLETE_SIGN_UP } from './reducer';
import paths from '../../config/paths';
import { cleaningNullValues } from '../../utils/functions'

function* completeSignUp() {
  yield takeLatest(COMPLETE_SIGN_UP.COMPLETE_SIGN_UP, function* fn(action: any): any {
    const body = cleaningNullValues({
      name: action.completeSignUpControls.name,
      curp: action.completeSignUpControls.identification,
      websiteUrl: action.completeSignUpControls.socialMediaUrl,
      interests: [
        "hola mundo ",
        "hola mundo 2 ",
        "hola mundo 10 ",
        "hola mundo 20 "
      ]
    });
    try {
      const completeSignUpRes = yield post('/complete-sign-up', body);
      if (completeSignUpRes.error) {
        yield put(completeSignUpActions.completeSignUpFailed({ response: completeSignUpRes.errorMsg }));
      } else {
        yield put(completeSignUpActions.completeSignUpSuccess(completeSignUpRes.data));
        action.completeSignUpControls.history.push(paths.WAITING_FOR_REVIEW)
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  }
  );
}

export default function* saga(): any {
  yield spawn(completeSignUp);
}