import { takeLatest, spawn, put } from 'redux-saga/effects';
import { get, post } from '../../utils/api';
import { SHOP, shopActions } from './reducer';
import Swal from 'sweetalert2';

function* wooCommerce() {
  yield takeLatest(SHOP.GET_WOOCOMMERCE_DATA, function* fn(): any {
    try {
      const response = yield get('/woo-commerce/orders');
      if (response) {
        if (response.error) {
          const data = JSON.parse(response.errorMsg);
          if (data.error.status === 401) {
            localStorage.removeItem('token');
          }
        }
        yield put(shopActions.getShopDataSuccess(response.data));
      } else {
        yield put(
          shopActions.getShopDataFailed({ response: response.errorMsg }),
        );
      }
    } catch {
      console.log('error en la request');
      // yield put(shopActions.('Oops, server failed'));
    }
  });
}

function* createShop() {
  yield takeLatest(
    SHOP.CREATE_WOOCOMMERCE_SHOP,
    function* fn(actions: any): any {
      try {
        console.log('%c⧭', 'color: #731d1d', 'a veeeeeer');
        const response = yield post('/woo-commerce', {
          wooCommerceKey: actions.createShop.input.key,
          wooCommerceSecret: actions.createShop.input.secret,
          wooCommerceUrl: actions.createShop.input.domain,
        });
        console.log('%c⧭ response', 'color: #ffa640', response);
        if (response) {
          if (response.error) {
            console.log('%c⧭response.error', 'color: #00b300', response.error);
            const data = JSON.parse(response.errorMsg);
            console.log('%c⧭c⧭response.error2', 'color: #1d5673', data);
            if (data.error.status === 401) {
              localStorage.removeItem('wooCommerceToken');
            }
            if (data.error.status === 422) {
              const errorList = data.error.details
                .map((e: any) => `<li>${e.message}</li>`)
                .join('');
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `<ul>${errorList}</ul>`,
              });
            }
            if (data.error.status === 424) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: data.error.message,
              });
            }
          } else {
            Swal.fire({
              icon: 'success',
              title: 'Tienda creada correctamente',
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              window.location.reload();
            });
            yield put(shopActions.createShopSuccess(response.data));
          }
        } else {
          yield put(
            shopActions.createShopFailed({ response: response.errorMsg }),
          );
        }
      } catch {
        console.log('error en la request');
        // yield put(shopActions.('Oops, server failed'));
      }
    },
  );
}

export default function* saga(): any {
  yield spawn(wooCommerce);
  yield spawn(createShop);
}
