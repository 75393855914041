import React from "react";

export const markers = [
  { lat: 20.63173, lng: -100.40129},
  { lat: 19.447667309761027, lng: -99.1889399334488},
  { lat: 21.1391922791908, lng: -101.69450937886619},
  { lat: 19.035753375112034, lng: -98.21417827167029},
  { lat: 20.681194693301673, lng: -87.04618895582279 }, //Quintana Roo
  { lat: 20.67512695401697, lng: -103.3493538013273} // Guadalajara
];

export const Map = ({ onClick, onIdle, children, style, ...options }) => {
  const ref = React.useRef(null);
  const [map, setMap] = React.useState();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  React.useEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export const Marker = (options) => {
  const [marker, setMarker] = React.useState();
  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      const infowindow = new window.google.maps.InfoWindow({
        content: `daver`
      });
      marker.setOptions(options);

      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          shouldFocus: false
        });
      });
    }
  }, [marker, options]);

  return null;
};


