import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../../commons/reducer';
import { post } from '../../../utils/api';
import { speiActions, SPEI } from './reducer';
import { rechargeCreditsActions } from '../reducer';

function* generateClabe() {
  yield takeLatest(SPEI.GENERATE_CLABE, function* fn(action: any): any {
    const body = {
      clientName: action.controls.profile.name,
      clientEmail: action.controls.profile.contact.email,
      clientPhone: action.controls.profile.contact.phone,
      amountToRecharge: action.controls.amount
    }
    try {
      const response = yield post('/payments/spei', body);
      if (response.error) {
        yield put(speiActions.generateClabeFailed({ response: response.errorMsg }));
      } else {
        yield put(speiActions.generateClabeSucess(response));
        yield put(rechargeCreditsActions.rechargeCreditsSuccess(response));
      }
    } catch (err) {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });
}
export default function* saga(): any {
  yield spawn(generateClabe);
}