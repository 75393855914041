import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../reducer';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { get, post } from '../../../utils/api';
import { invoiceActions, INVOICE } from './reducer';

const MySwal = withReactContent(Swal)

function* invoice() {
  yield takeLatest(INVOICE.GET_LIST_OF_CFDI, function* fn(action: any): any {
    try {
      const response = yield get('/invoice/catalog/cfdi');
      if (response.error) {
        yield put(invoiceActions.getListOfCfdiFailed({ response: response.errorMsg }));
      } else {
        yield put(invoiceActions.getListOfCfdiSuccess(response));
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  }
  );

  yield takeLatest(INVOICE.CREATE_INVOICE, function* fn(action: any): any {
    try {
      const { invoiceControls } = action
      delete invoiceControls['sendToAnotherEmail']
      const body = { ...invoiceControls, transactionIds: invoiceControls.transactionIds.split(','), emails: invoiceControls.emails?.split(",").map((e: string) => e.trim()) || [] }
      const response = yield post('/invoice', body);
      if (response.error) {
        yield put(invoiceActions.createInvoiceFailed({ response: response.errorMsg }));
        MySwal.fire({
          icon: 'error',
          title: `Ooops, tenemos problemas para generar tu factura`,
          text: JSON.parse(response.errorMsg).error.message,
        })
      } else {
        yield put(invoiceActions.createInvoiceSuccess(response));
        MySwal.fire({
          icon: 'success',
          title: `Tu factura se ha generado con éxito, verifica tu correo`,
          text: response.errorMsg,
        })
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  }
  );
  yield takeLatest(INVOICE.CREATE_NEW_INVOICE, function* fn(action: any): any {
    try {
      const { invoiceControls } = action;
      delete invoiceControls['sendToAnotherEmail'];
      const body = {
        ...invoiceControls,
        emails:
          invoiceControls.emails?.split(',').map((e: string) => e.trim()) || [],
      };
       const response = yield post('/invoice/new', body);
      if (response.error) {
        yield put(invoiceActions.createInvoiceFailed({ response: response.errorMsg }));
        MySwal.fire({
          icon: 'error',
          title: `Ooops, tenemos problemas para generar tu factura`,
          text: JSON.parse(response.errorMsg).error.message,
        })
      } else {
        yield put(invoiceActions.createInvoiceSuccess(response));
        MySwal.fire({
          icon: 'success',
          title: `Tu factura se ha generado con éxito, verifica tu correo`,
          text: response.errorMsg,
        })
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });

  yield takeLatest(INVOICE.GET_LIST_OF_REGIMEN_FISCAL, function* fn(action: any): any {
    try {
      const response = yield get('/invoice/catalog/fiscalRegimens');
      if (response.error) {
        yield put(invoiceActions.getListOfRegimeFiscalFailed({ response: response.errorMsg }));
      } else {
        yield put(invoiceActions.getListOfRegimeFiscalSuccess(response));
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  }
  );

  yield takeLatest(INVOICE.GET_PAYMENT_FORMS, function* fn(action: any): any {
    try {
      const response = yield get('/invoice/catalog/paymentForms');
      if (response.error) {
        yield put(invoiceActions.getPaymentFormsFailed({ response: response.errorMsg }));
      } else {
        yield put(invoiceActions.getPaymentFormsSuccess(response));
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  }
  );
}
export default function* saga(): any {
  yield spawn(invoice);
}
