import { takeLatest, spawn, put } from 'redux-saga/effects';
import { appActions } from '../../commons/reducer';

import { post } from '../../utils/api';
import { loginActions, LOGIN } from './reducer';

function* auth() {
  yield takeLatest(LOGIN.AUTH, function* fn(action: any): any {
    const body = {
      email: action.controls.email,
      password: action.controls.password,
      ReCaptchaToken: action.controls.ReCaptchaToken
    };
    try {
      const response = yield post('/signIn', body);
      if (response.error) {
        yield put(loginActions.authFailed({ response: response.errorMsg }));
      } else {
        localStorage.setItem('token', JSON.stringify(response.token));
        yield put(loginActions.authSuccess(response));
        action.controls.history.push('/dashboard');
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  });
}
export default function* saga(): any {
  yield spawn(auth);
}